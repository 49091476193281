/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent, Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../../notificationMessages';
import renderTextInput from '../../../../../../shared/components/form/TextInput';
import renderCheckBoxField from '../../../../../../shared/components/form/CheckBox';
import { translate } from 'react-i18next';
import renderDatePickerField from '../../../../../../shared/components/form/DatePicker';
import MinusIcon from 'mdi-react/MinusIcon';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import TaxDetailCalc from '../../../../../Report/BpjsReport/TaxDetailCalc';
import Iframe from 'react-iframe';
import Leavesubmission from '../../SP_File/leavesubmission';
import Overtimesubmission from '../../SP_File/overtimesubmission';
import Attendancesubmission from '../../SP_File/attendancesubmission';
import Claimsubmission from '../../SP_File/claimsubmission';
import Kpiplansubmission from '../../SP_File/kpiplansubmission';
import Kpitrackingsubmission from '../../SP_File/kpitrackingsubmission';
import Kpievaluationsubmission from '../../SP_File/kpievaluationsubmission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faFileAlt } from '@fortawesome/fontawesome-free-solid';
// import ReactDynamicImport from 'react-dynamic-import';
// import Iframe from './components/iframe.js';

class submissionForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    dt_workflow: PropTypes.string.isRequired,
    wfo_id: PropTypes.string.isRequired,
    wfl_id: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.ACTIVITY') },
      { 2: t('LBL.OPC_STATUS') },
    ];

    this.state = {
      // rows: this.createRows(3),
      // pageOfItems: [],
      token: '',
      apiws: '',
      emp_id: '',
      redirect: false,
      addcond: '',
      displayLoad: 'none',
      displayApprov: '',
      wfl_data: [],
      arrHistory: [],
      wfl_checklist: [],
      sp_file: '',
    };
    this.toggle = this.toggle.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getHistoryCase = this.getHistoryCase.bind(this);
    this.linkBack = this.linkBack.bind(this);
    this.btnClick = this.btnClick.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getEmployee();
      this.getHistoryCase();
    });
    setTimeout(() => {
      this.setState({ wfl_data: this.props.dt_workflow });
    }, 200);
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.ACTIVITY') },
      { 2: t('LBL.OPC_STATUS') },
    ];
    setTimeout(() => {
      this.setState({ wfl_data: newProps.dt_workflow });
    }, 200);
  }

  getHistoryCase() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .get(
        `${ls.get('ws_ip')}/workflow/gethistorycase/${this.props.wfo_id}`,
        config,
      )
      .then((resemp) => {
        const hist = resemp.data.data.history;
        const { checklist } = resemp.data.data;
        const request = resemp.data.data.request_detail;
        const arrayHist = [];
        const arrayChecklist = [];
        const arrayDetail = [];
        if (hist.length > 0) {
          hist.map((obj) =>
            arrayHist.push({
              activity: obj.activity,
              opc_status: obj.opc_status,
              note: obj.remarks,
              applicant: obj.emp_name,
            }),
          );
        }
        if (checklist.length > 0) {
          checklist.map((obj) => {
            arrayChecklist.push({
              wfc_id: obj.wfc_id,
              value: obj.checked,
              name: obj.name,
            });
            this.state.wfl_checklist.push({ name: obj.name });
          });
        }
        if (request.length > 0) {
          request.map((obj) => {
            this.setState({
              sp_file: obj.sp_file,
              upload_file: obj.upload_file,
            });
          });
        }
        this.setState({ arrHistory: hist });
        this.props.initialize({ wfl_check: arrayChecklist });
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getEmployee() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/employee/getAllItem/`, '', config)
      .then((resemp) => {
        const emp = resemp.data.data;
        const array = [];
        if (emp.length === 0) {
          this.setState({ arrEmp: array });
        } else {
          emp.map((obj) =>
            array.push({ value: obj.emp_id, label: obj.emp_name }),
          );
          this.setState({ arrEmp: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  btnClick(val) {
    this.setState({ action: val });
    // this.state ={data: 'sds'};
  }

  handleSubmit(values) {
    console.log(values);
    const { wfl_data } = this.state;
    this.setState({ displayLoad: '', displayApprov: 'none' });
    const { wfl_id } = this.props;
    const { wfa_id } = wfl_data;
    const emp_req = wfl_data.cases.emp_id_req;
    const wfo_id = wfl_data.cases.id;
    const { sp_file } = this.state;
    const { action } = this.state;
    const desc = typeof values.desc === 'undefined' ? '-' : values.desc;
    const { apiws, token } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const approver = ls.get('user_cred').emp_id;
    const url = `${apiws}/workflow/submitapproval`;
    const data = {
      wfl_id,
      wfa_id,
      sp_file,
      emp_req,
      wfo_id,
      action,
      desc,
      approver,
      cuser_id: userid,
    };

    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(url, formBody, config)
      .then((response) => {
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          this.setState({ redirect: true });
          setTimeout(() => {
            this.props.destroy();
          }, 200);
        } else if (response.data.status === 'no_user_found') {
          this.setState({ displayLoad: 'none', displayApprov: '' });
          showNotifications(
            'Fail',
            "Can't Find Next User Approval!",
            'warning',
          );
        } else {
          this.setState({ displayLoad: 'none', displayApprov: '' });
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  renderCategory = ({ fields, meta: { error }, t }) => (
    <div>
      {fields.map((item, index) => (
        <div>
          <Row>
            <Col md="12" lg="12" xl="12" sm="12">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name={`${item}.value`}
                    component={renderCheckBoxField}
                    label={this.state.wfl_checklist[index].name}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ))}
      {error && <li className="error">{error}</li>}
    </div>
  );

  render() {
    const { handleSubmit, t } = this.props;
    const { wfl_data, displayApprov, displayLoad } = this.state;
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <div
                  className="profile-identity"
                  style={{ marginTop: '0px', width: '100%' }}
                >
                  <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <div
                        className="separator-cv"
                        style={{ textTransform: 'uppercase' }}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                        {t('LBL.APPROVAL')}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col md={8}>
                      <h3 className="page-title">{t('LBL.APPROVAL')}</h3>
                    </Col> */}
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <table
                        id="myTable"
                        className="table data-grid-tbl responsive no-wrap"
                      >
                        <tbody>
                          <tr>
                            <td width="25%" style={{ fontWeight: 'bold' }}>
                              {t('LBL.WORKFLOW')}
                            </td>
                            <td>: {wfl_data.name}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold' }}>
                              {t('LBL.ACTIVITY')}
                            </td>
                            <td>: {wfl_data.activity}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold' }}>
                              {t('LBL.APPLICANT')}
                            </td>
                            <td>: {this.props.dt_workflow.cases.emp_req}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold' }}>
                              {t('LBL.REQUEST_DATE')}
                            </td>
                            <td>
                              :{' '}
                              {moment(
                                this.props.dt_workflow.cases.created_time,
                              ).format('DD/MM/YYYY')}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <div
                        className="separator-cv"
                        style={{ textTransform: 'uppercase' }}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                        {t('LBL.HISTORY_CASE')}
                      </div>
                    </Col>
                  </Row>
                  {/* <Col md={8}>
                    <h3 className="page-title">History Case</h3>
                  </Col> */}
                  <Row>
                    <Card
                      style={{ padding: '0px 10px 10px 10px', margin: '0px' }}
                    >
                      <CardBody style={{ padding: '8px', margin: '8px' }}>
                        <Col
                          xs={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{ padding: '0px', margin: '0px' }}
                        >
                          <table
                            id="myTable"
                            className="table data-grid-tbl responsive no-wrap"
                          >
                            <thead>
                              <th>{t('LBL.NUMBER')}</th>
                              <th>{t('LBL.ACTIVITY')}</th>
                              <th>{t('LBL.APPLICANT')}</th>
                              <th>{t('LBL.APPROVER')}</th>
                              <th>{t('LBL.STATUS')}</th>
                              <th>{t('LBL.NOTE_APPROVER')}</th>
                            </thead>
                            <tbody>
                              {this.state.arrHistory.map((data, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{data.activity}</td>
                                  <td>{data.requester}</td>
                                  <td>{data.approver}</td>
                                  <td>{data.opc_status}</td>
                                  <td>{data.remarks}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Col>
                      </CardBody>
                    </Card>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <div
                        className="separator-cv"
                        style={{ textTransform: 'uppercase' }}
                      >
                        <FontAwesomeIcon icon={faFileAlt} />
                        {t('FRM.REQUEST_DETAIL')}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                    {/* <Card>
                      <CardBody> */}
                    {/* <Col md={8}>
                      <h3 className="page-title">Request Detail</h3>
                    </Col> */}
                    {this.state.sp_file === '/leavesubmission' && (
                      <Leavesubmission
                        wfo_id={this.props.wfo_id}
                        disabledField="true"
                      />
                    )}
                    {this.state.sp_file === '/overtimesubmission' && (
                      <Overtimesubmission
                        wfo_id={this.props.wfo_id}
                        emp_id={wfl_data.cases.emp_id_req}
                        disabledField="true"
                      />
                    )}
                    {this.state.sp_file === '/attendancesubmission' && (
                      <Attendancesubmission
                        wfo_id={this.props.wfo_id}
                        disabledField="true"
                      />
                    )}
                    {this.state.sp_file === '/claimsubmission' && (
                      <Claimsubmission
                        wfo_id={this.props.wfo_id}
                        emp_id={wfl_data.cases.emp_id_req}
                        disabledField="true"
                      />
                    )}
                    {this.state.sp_file === '/kpiplansubmission' && (
                      <Kpiplansubmission
                        wfo_id={this.props.wfo_id}
                        emp_id={wfl_data.cases.emp_id_req}
                        disabledField="true"
                      />
                    )}
                    {this.state.sp_file === '/kpitrackingsubmission' && (
                      <Kpitrackingsubmission
                        wfo_id={this.props.wfo_id}
                        emp_id={wfl_data.cases.emp_id_req}
                        disabledField="true"
                      />
                    )}
                    {this.state.sp_file === '/kpievaluationsubmission' && (
                      <Kpievaluationsubmission
                        wfo_id={this.props.wfo_id}
                        emp_id={wfl_data.cases.emp_id_req}
                        disabledField="true"
                      />
                    )}
                    {/* </CardBody>
                    </Card> */}
                    {/* <Iframe
                      url="http://www.youtube.com/embed/xDMP3i36naA"
                      width="450px"
                      height="450px"
                      id="myId"
                      className="myClassname"
                      display="initial"
                      position="relative"
                    /> */}
                    {/* <Col xs={12} md={8} lg={8} xl={12}>
                      <h3 className="page-title">Checklist</h3>
                    </Col>
                    {this.state.wfl_checklist.length > 0 && (

                      <Card>
                        <CardBody>
                          <Row>
                            <Col xs={12} md={12} lg={12} xl={12}>
                              <FieldArray
                                name="wfl_check"
                                component={this.renderCategory}
                                props={{
                                  t,
                                }}
                              />
                            </Col>
                          </Row>

                        </CardBody>
                      </Card>

                    )}
                    <Card>
                      <CardBody>
                        <Col md={8}>
                          <span className="form__form-group-label">File</span>

                        </Col>
                      </CardBody>
                    </Card> */}
                  </Row>
                  <Row>
                    <Col md={12} xs={12} lg={12} xl={12}>
                      <form
                        className="form form--horizontal"
                        name="submitapproval"
                        id="submitapproval"
                        onSubmit={handleSubmit(this.handleSubmit)}
                      >
                        <div
                          className="form__form-group"
                          style={{ paddingLeft: '20px' }}
                        >
                          <span className="form__form-group-label">
                            {t('LBL.NOTE_APPROVER')}
                          </span>
                          <div className="form__form-group-field">
                            <input type="hidden" name="elv_id" />
                            <Col md={6} xs={12}>
                              <Field
                                name="desc"
                                component={renderTextInput}
                                type="textarea"
                                placeholder="Write description here"
                              />
                            </Col>
                          </div>
                        </div>
                        <hr
                          style={{
                            width: '100%',
                            padding: '0px',
                            margin: '0px',
                          }}
                        />
                        <div
                          style={{
                            textAlign: 'center',
                            width: '100%',
                            paddingTop: '10px',
                          }}
                        >
                          <Button
                            color="danger"
                            type="submit"
                            form="submitapproval"
                            onClick={() => this.btnClick(0)}
                            style={{ display: displayApprov }}
                          >
                            {t('LBL.REJECT')}
                          </Button>
                          <Button
                            color="primary"
                            type="submit"
                            form="submitapproval"
                            onClick={() => this.btnClick(1)}
                            style={{ display: displayApprov }}
                          >
                            {' '}
                            {t('LBL.ACCEPT')}
                          </Button>
                          <Button
                            color="primary"
                            style={{ display: displayLoad }}
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              style={{ marginRight: '6px' }}
                            />
                            {t('FRM.LOADING')}
                          </Button>
                        </div>
                      </form>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'approval', // a unique identifier for this form
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(translate('global')(submissionForm));
