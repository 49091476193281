/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len, object-shorthand */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-did-mount-set-state */
import React, { Component } from 'react';
import { Button, Card, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import WizardFormOne from './WizardFormOne';
import WizardFormTwo from './WizardFormTwo';
import WizardFormThree from './WizardFormThree';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import ls from 'local-storage';
import moment from 'moment';
// font-awesome;
import { Redirect } from 'react-router-dom';
import { translate } from 'react-i18next';
import Authenticator from '../../../Authenticator';
import utils from '../../../../utils';

class WizardForm extends Component {
  static propTypes = {
    parmid: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      token: '',
      apiws: '',
      slcunit: [],
      taxstatus: [],
      rtbbank: [],
      payproratesetting: [],
      rtbemployeementstatus: [],
      rtbreligion: [],
      rtbidentitytype: [],
      rtbmaritalstatus: [],
      paytaxmethod: [],
      rtbfamilyrelation: [],
      rtbbloodtype: [],
      rtbcompany: [],
      rtbnationality: '',
      paycomponentgroup: [],
      paycomponentgrouprel: [],
      orgoffice: [],
      orgjobgrade: [],
      orgjobbandgrade: [],
      orgjoblevel: [],
      orgjobband: [],
      orgjobname: [],
      orgposition: [],
      orgorganization: [],
      attshift: [],
      paytaxclass: [],
      saveonthefly: {
        identity_ktp: 1,
        identity_sim: 2,
        identity_paspor: 3,
        idty_ktp: 'KTP',
        idty_sim: 'SIM',
        idty_paspor: 'PASPORT',
      },
      emphierarchy: [],
      fingerbyemp: [],
      allfingerid: [],
      redirect_emp: false,
      disabledTax: false,
      disabledTaxMethod: false,
      orgId: '',
      superordinat: '',
      valuecomp: [],
      oldvaluecomp: [],
      displaySpinner: 'none',
      displaySave: '',
      paycomponentrecur: [],
      validateToken: false,
      isLoaded: 0,
      allempcode: [],
      empStatus: '',
      touched: false,
      empApproval: [],
    };
    this.loadSelectBox = this.loadSelectBox.bind(this);
    this.loadEmployee = this.loadEmployee.bind(this);
    this.getJobName = this.getJobName.bind(this);
    this.getJobTitle = this.getJobTitle.bind(this);
    this.getJobGrade = this.getJobGrade.bind(this);
    this.getSuperordinat = this.getSuperordinat.bind(this);
    this.getFingerId = this.getFingerId.bind(this);
    this.checkAuth = this.checkAuth.bind(this);
    this.employeeStatus = this.employeeStatus.bind(this);
  }

  componentDidMount = () => {
    this.setState(
      {
        apiws: ls.get('ws_ip'),
        token: ls.get('token'),
      },
      async () => {
        await this.loadSelectBox();
        if (this.props.parmid.state.usr_id !== undefined) {
          await this.loadEmployee(this.props.parmid.state.usr_id);
          await this.getFingerId(this.props.parmid.state.usr_id);
        } else {
          await this.getallfinger();
        }
        this.checkAuth();
        this.setState((state) => ({ isLoaded: 2 }));
      },
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    /* eslint-disable react/prop-types */
    if (
      (this.props.values !== nextProps.values || this.state !== nextState) &&
      nextState.isLoaded < 3
    ) {
      return true;
    }

    return false;
  }

  // eslint-disable-next-line react/sort-comp
  sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  submit = async (values) => {
    // console.log(values);
    this.setState({
      displaySave: 'none',
      displaySpinner: '',
    });
    await this.sleep(1000);
    const idnarr = [];
    const comp_payroll = [];
    let ctr_id;
    let adjustment_start_date;
    let adjustment_end_date;
    let backpay_date;
    let npwp_date;
    let end_date;
    const pyg_id = null;

    if (values.identity_no_ktp !== '-') {
      idnarr.push({
        identity: values.identity_ktp,
        identity_no: values.identity_no_ktp,
        expired_date:
          typeof values.expired_date_ktp === 'undefined' ||
          values.expired_date_ktp === null
            ? ''
            : values.expired_date_ktp.format('YYYY-MM-DD'),
        file_ktp: values.file_ktp,
      });
    }
    if (
      typeof values.identity_no_sim !== 'undefined' &&
      values.identity_no_sim !== ''
    ) {
      idnarr.push({
        identity: values.identity_sim,
        identity_no: values.identity_no_sim,
        expired_date:
          typeof values.expired_date_sim === 'undefined' ||
          values.expired_date_sim === null
            ? null
            : values.expired_date_sim.format('YYYY-MM-DD'),
        file_sim: values.file_sim,
      });
    }
    if (
      typeof values.identity_no_paspor !== 'undefined' &&
      values.identity_no_paspor !== ''
    ) {
      idnarr.push({
        identity: values.identity_paspor,
        identity_no: values.identity_no_paspor,
        expired_date:
          typeof values.expired_date_paspor === 'undefined' ||
          values.expired_date_paspor === null
            ? null
            : values.expired_date_paspor.format('YYYY-MM-DD'),
        file_passport: values.file_passport,
      });
    }
    // values.dyn_identity.forEach((element) => {
    //   const identity = (typeof element.identity === 'object') ? element.identity.value : element.identity;
    //   idnarr.push({
    //     identity,
    //     identity_no: element.identity_no,
    //     expired_date: typeof element.expired_date === 'undefined' || element.expired_date === null ? null : element.expired_date.format('YYYY-MM-DD'),
    //   });
    // });
    // if (this.state.valuecomp.length > 0) {
    values.component_payroll.forEach((element, index) => {
      comp_payroll.push({
        pyc_id: element.pyc_id === null || null ? '' : element.pyc_id,
        label: element.label === null || null ? '' : element.label,
        is_backpay: element.is_backpay === null || '' ? '' : element.is_backpay,
        is_effective:
          element.is_effective === null || '' ? '' : element.is_effective,
        old_value:
          typeof this.state.oldvaluecomp[index] === 'undefined'
            ? 0
            : this.state.oldvaluecomp[index],
        pec_value:
          typeof this.state.valuecomp[index] === 'undefined'
            ? 0
            : this.state.valuecomp[index],
      });
    });
    // pyg_id = (typeof values.pyg_id === 'object') ? values.pyg_id.value : values.pyg_id;
    // }
    const dynIdentity = JSON.stringify(idnarr);
    const componentPayroll = JSON.stringify(comp_payroll);
    let rtf_id;
    // const pysId = (typeof values.pys_id === 'object') ? values.pys_id.value : values.pys_id;
    const last_education =
      typeof values.last_education === 'object'
        ? values.last_education.value
        : values.last_education;
    const rtb_id =
      typeof values.rtb_id === 'object' ? values.rtb_id.value : values.rtb_id;
    const rtg_id =
      typeof values.rtg_id === 'object' ? values.rtg_id.value : values.rtg_id;
    const rtk_id =
      typeof values.rtk_id === 'object' ? values.rtk_id.value : values.rtk_id;
    const rtm_id =
      typeof values.rtm_id === 'object' ? values.rtm_id.value : values.rtm_id;
    const rtx_id =
      typeof values.rtx_id === 'object' ? values.rtx_id.value : values.rtx_id;
    const ats_id =
      typeof values.ats_id === 'object' ? values.ats_id.value : values.ats_id;
    const cmp_id =
      typeof values.cmp_id === 'object' ? values.cmp_id.value : values.cmp_id;
    const off_id =
      typeof values.off_id === 'object' ? values.off_id.value : values.off_id;
    const org_id =
      typeof values.org_id === 'object' ? values.org_id.value : values.org_id;
    const jbn_id =
      typeof values.jbn_id === 'object' ? values.jbn_id.value : values.jbn_id;
    // const jbg_id = (typeof values.jbg_id === 'object') ? values.jbg_id.value : values.jbg_id;
    const pos_id =
      typeof values.pos_id === 'object' ? values.pos_id.value : values.pos_id;
    const ems_id =
      typeof values.ems_id === 'object' ? values.ems_id.value : values.ems_id;
    const jbd_id =
      typeof values.jbd_id === 'object' ? values.jbd_id.value : values.jbd_id;
    const jgd_id =
      typeof values.jgd_id === 'object' ? values.jgd_id.value : values.jgd_id;
    const ptm_id =
      typeof values.ptm_id === 'object' ? values.ptm_id.value : values.ptm_id;
    const tcs_id =
      typeof values.tcs_id === 'object' ? values.tcs_id.value : values.tcs_id;
    // if (typeof values.ogf_id !== 'undefined') {
    //   if (values.ogf_id !== null) {
    //     ogf_id = (typeof values.ogf_id === 'object') ? values.ogf_id.value : values.ogf_id;
    //   } else {
    //     ogf_id = '';
    //   }
    // } else {
    //   ogf_id = '';
    // }
    let ogf_id = [];
    if (values.ogf_id.length) {
      values?.ogf_id?.map((item) => {
        ogf_id.push(item.value);
      });
    }
    if (typeof values.rtf_id !== 'undefined') {
      if (values.rtf_id !== null) {
        rtf_id =
          typeof values.rtf_id === 'object'
            ? values.rtf_id.value
            : values.rtf_id;
      } else {
        rtf_id = '';
      }
    } else {
      rtf_id = '';
    }
    const citizen =
      typeof values.citizen === 'object'
        ? values.citizen.value
        : values.citizen;
    const generate = values.gnr_usr === true ? '1' : '0';
    // const depend = parseInt(values.dependant, 10);
    if (citizen === 2) {
      ctr_id =
        typeof values.ctr_id === 'object' ? values.ctr_id.value : values.ctr_id;
    } else {
      ctr_id = '';
    }
    // const job_id = (typeof values.job_id === 'object') ? values.job_id.value : values.job_id;
    let emp_parent;
    let path_photo;
    let file_ktp;
    let file_sim;
    let file_passport;
    let file_npwp;
    let emp_approval;
    if (!values.emp_parent) {
      emp_parent = '';
    } else {
      emp_parent =
        typeof values.emp_parent === 'object'
          ? values.emp_parent.value
          : values.emp_parent;
    }
    if (!values.emp_approval) {
      emp_approval = '';
    } else {
      emp_approval =
        typeof values.emp_approval === 'object'
          ? values.emp_approval.value
          : values.emp_approval;
    }
    const sex = typeof values.sex === 'object' ? values.sex.value : values.sex;
    const bpjs_ks_registered =
      typeof values.bpjs_ks_registered === 'object'
        ? values.bpjs_ks_registered.value
        : values.bpjs_ks_registered;
    const bpjs_tk_registered =
      typeof values.bpjs_tk_registered === 'object'
        ? values.bpjs_tk_registered.value
        : values.bpjs_tk_registered;
    const overtime =
      typeof values.overtime === 'object' ? values.overtime.value : '';
    const bpjs_ks_paid_by_company =
      typeof values.bpjs_ks_by_company === 'undefined' ? '2' : '1';
    const bpjs_tk_paid_by_company =
      typeof values.bpjs_tk_by_company === 'undefined' ? '2' : '1';
    if (values.path_photo !== null) {
      path_photo =
        typeof values.path_photo === 'object'
          ? values.path_photo.file
          : values.path_photo;
    } else {
      path_photo = '';
    }
    if (values.file_ktp !== null) {
      file_ktp =
        typeof values.file_ktp === 'object'
          ? values.file_ktp.file
          : values.file_ktp;
    } else {
      file_ktp = '';
    }
    if (values.file_sim !== null) {
      file_sim =
        typeof values.file_sim === 'object'
          ? values.file_sim.file
          : values.file_sim;
    } else {
      file_sim = '';
    }
    if (values.file_passport !== null) {
      file_passport =
        typeof values.file_passport === 'object'
          ? values.file_passport.file
          : values.file_passport;
    } else {
      file_passport = '';
    }
    if (values.file_npwp !== null) {
      file_npwp =
        typeof values.file_npwp === 'object'
          ? values.file_npwp.file
          : values.file_npwp;
    } else {
      file_npwp = '';
    }
    if (typeof values.adjustment_start_date === 'undefined') {
      adjustment_start_date = '';
    } else if (
      values.adjustment_start_date === null ||
      values.adjustment_start_date === ''
    ) {
      adjustment_start_date = '';
    } else {
      adjustment_start_date = values.adjustment_start_date.format('YYYY-MM-DD');
    }

    if (typeof values.adjustment_end_date === 'undefined') {
      adjustment_end_date = '';
    } else if (
      values.adjustment_end_date === null ||
      values.adjustment_end_date === ''
    ) {
      adjustment_end_date = '';
    } else {
      adjustment_end_date = values.adjustment_end_date.format('YYYY-MM-DD');
    }

    if (typeof values.end_date === 'undefined') {
      end_date = '';
    } else if (values.end_date === null || values.end_date === '') {
      end_date = '';
    } else {
      end_date = values.end_date.format('YYYY-MM-DD');
    }

    if (
      typeof values.backpay_date === 'undefined' ||
      values.backpay_date === null ||
      values.backpay_date === ''
    ) {
      backpay_date = '';
    } else {
      backpay_date = values.backpay_date.format('YYYY-MM-DD');
    }

    if (
      typeof values.npwp_date === 'undefined' ||
      values.npwp_date === null ||
      values.npwp_date === ''
    ) {
      npwp_date = '';
    } else {
      npwp_date = values.npwp_date.format('YYYY-MM-DD');
    }
    const padId = values.pad_id;
    const fingerId = values.finger_id;
    const convertdata = {
      dyn_identity: dynIdentity,
      component_payroll: componentPayroll,
      // pys_id: pysId,
      last_education,
      rtb_id,
      rtf_id,
      rtg_id,
      rtk_id,
      rtm_id,
      rtx_id,
      pyg_id,
      ats_id,
      cmp_id,
      off_id,
      pos_id,
      org_id,
      jbn_id,
      // job_id,
      jgd_id,
      jbd_id,
      // jbg_id,
      ems_id,
      emp_parent,
      emp_approval,
      pad_id: padId,
      finger_id: fingerId,
      bpjs_ks_registered,
      bpjs_tk_registered,
      overtime,
      sex,
      adjustment_start_date,
      adjustment_end_date,
      backpay_date,
      bpjs_ks_paid_by_company,
      bpjs_tk_paid_by_company,
      path_photo,
      file_ktp,
      file_sim,
      file_passport,
      file_npwp,
      dob: values.dob.format('YYYY-MM-DD'),
      join_date: values.join_date.format('YYYY-MM-DD'),
      end_date,
      npwp_date,
      citizen,
      ctr_id,
      // depend,
      ptm_id,
      generate,
      tcs_id,
      ogf_id,
    };
    // console.log(JSON.stringify(convertdata));
    const alldata = { ...values, ...convertdata };
    const config = {
      headers: {
        // eslint-disable-next-line quote-props
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    let url;
    let data;
    const apiws = ls.get('ws_ip');
    const userid = ls.get('user_cred').usr_id;

    const id = values.emp_id;
    if (id === undefined || id === '') {
      url = `${apiws}/employee/saveItem`;
      data = { ...alldata, cuser_id: userid };
    } else {
      data = { ...alldata, muser_id: userid };
      url = `${apiws}/employee/updateItem/${id}`;
    }
    // alert(JSON.stringify(data));
    // eslint-disable-next-line guard-for-in
    const getFormData = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
    }, new FormData());

    axios
      .post(url, getFormData, config)
      .then((response) => {
        // alert(JSON.stringify(response));
        // console.log(response);
        if (response.data.status === 'ok') {
          showNotifications('Success', 'Save Success', 'success');
          setTimeout(() => {
            this.setState({
              redirect_emp: true,
              displaySpinner: 'none',
              displaySave: '',
            });
          }, 2000);
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
          this.setState({ displaySpinner: 'none', displaySave: '' });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async getFingerId(id) {
    return new Promise((resolve, reject) => {
      const { token, apiws } = this.state;
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      axios
        .post(`${apiws}/employee/getallitemfingerid/${id}`, '', config)
        .then((response) => {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) => Arr.push(obj.finger_id));
          setTimeout(() => {
            this.setState({ fingerbyemp: Arr });
          }, 200);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  getJobName(id) {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgjobname/getAllHierarchy/${id.value}`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const jbn = response.data.data;
          const arr = [];
          jbn.map((obj) =>
            arr.push({ value: obj.jbn_id, label: obj.job_name }),
          );
          setTimeout(() => {
            this.setState({
              orgjobname: arr,
              orgId: id.value,
            });
          }, 500);
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getJobTitle(id) {
    const { orgId } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgposition/getAllHierarchy/${orgId}/${id.value}`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const pos = response.data.data;
          const arr = [];
          pos.map((obj) =>
            arr.push({ value: obj.pos_id, label: obj.position_nm }),
          );
          setTimeout(() => {
            this.setState({
              orgposition: arr,
            });
          }, 500);
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getJobGrade(id) {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgjobbandgrade/getAllHierarchy/${id.value}`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const grade = response.data.data;
          const arr = [];
          grade.map((obj) =>
            arr.push({ value: obj.jgd_id, label: obj.job_grade }),
          );
          setTimeout(() => {
            this.setState({
              orgjobgrade: arr,
            });
          }, 500);
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  async getallfinger() {
    return new Promise((resolve) => {
      const { token, apiws } = this.state;
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      axios.post(`${apiws}/employee/getAll/`, '', config).then((response) => {
        const res = response.data.data;
        const finger = [];
        // eslint-disable-next-line array-callback-return
        res.map((data) => {
          finger.push(data.finger_id);
        });
        this.setState({ allfingerid: finger });
        resolve();
      });
    });
  }

  getSuperordinat(pos_id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const datatree = [];

    const usrd = ls.get('user_cred').usr_id;
    axios
      .post(
        `${apiws}/employee/getAllItemByPosition/${pos_id.value}`,
        '',
        config,
      )
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.emp_id, label: obj.emp_name }),
          );
          setTimeout(() => {
            this.setState({ emphierarchy: Arr });
          }, 500);
        } else {
          this.setState({ button_disabled: false });
          // showNotifications('Fail', 'Failed Check', 'danger');
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ button_disabled: false });
      });
  }

  async loadSelectBox() {
    /* eslint-disable semi */
    /* eslint-disable padded-blocks */
    const me = this;
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const datatree = [];

    async function getApprovalEmp() {
      return new Promise((resolve2, reject) => {
        axios
          .post(`${apiws}/employee/getAll`, '', config)
          .then((response) => {
            const dataResponse = response.data.data;
            const Arr = [];
            dataResponse.map((obj) =>
              Arr.push({ value: obj.emp_id, label: obj.emp_name }),
            );
            //  me.setState({ slcunit: Arr });
            me.setState({ empApproval: Arr });
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getCompany() {
      return new Promise((resolve2, reject2) => {
        const usrd = ls.get('user_cred').usr_id;
        axios
          .post(`${apiws}/Orgcompany/getAllItemByUser/${usrd}`, '', config)
          .then((response) => {
            if (response.data.status === 'ok') {
              const dataResponse = response.data.data;
              if (dataResponse.length !== 0) {
                const Arr = [];
                dataResponse.map((obj) =>
                  Arr.push({ value: obj.cmp_id, label: obj.company }),
                );
                me.setState({ rtbcompany: Arr });
              }
            } else {
              me.setState({ button_disabled: false });
            }

            resolve2();
          })
          .catch((error) => {
            console.log(error);
            me.setState({ button_disabled: false });
          });
      });
    }

    async function getPayComponentGroupRel() {
      return new Promise((resolve2, reject2) => {
        axios
          .post(
            // `${apiws}/paycomponentgrouprel/getAllItemByPygId/${id}`,
            `${apiws}/paycomponentgrouprel/getAllItem/`,
            '',
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              const getResponseData = response.data.data;
              const dataComponent = [];
              getResponseData.forEach((element) => {
                if (dataComponent[element.pyg_id] === undefined) {
                  dataComponent[element.pyg_id] = [];
                }
                dataComponent[element.pyg_id].push({
                  pyc_id: element.pyc_id,
                  label: element.component,
                  pec_value: 0,
                });
              });
              me.setState({ paycomponentgrouprel: dataComponent });
              resolve2();
            } else {
              console.log('Error fetch component payroll');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getPayTaxClass() {
      return new Promise((resolve2, reject2) => {
        axios
          .post(
            // `${apiws}/paycomponentgrouprel/getAllItemByPygId/${id}`,
            `${apiws}/paytaxclass/getAll/`,
            '',
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              const getResponseData = response.data.data;
              const array = [];
              getResponseData.forEach((element) => {
                array.push({
                  value: element.tcs_id,
                  label: element.class_name,
                });
              });
              me.setState({ paytaxclass: array });
            } else {
              console.log('Error fetch component payroll');
            }
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getAllItemRecurring() {
      return new Promise((resolve2, reject2) => {
        axios
          .post(
            // `${apiws}/paycomponentgrouprel/getAllItemByPygId/${id}`,
            `${apiws}/paycomponent/getAllItemrecurring/0`,
            '',
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              const getResponseData = response.data.data;
              const dataComponent = [];
              getResponseData.forEach((element) => {
                dataComponent.push({
                  pyc_id: element.pyc_id,
                  label: element.component,
                  pec_value: 0,
                });
              });
              me.setState({ paycomponentrecur: dataComponent });
            } else {
              console.log('Error fetch component payroll');
            }
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getPayComponentGroup() {
      return new Promise((resolve2, reject2) => {
        axios
          .post(`${apiws}/paycomponentgroup/getAll/`, '', config)
          .then((response) => {
            if (response.data.status === 'ok') {
              const dataResponse = response.data.data;
              if (dataResponse.length !== 0) {
                const Arr = [];
                dataResponse.map((obj) =>
                  Arr.push({ value: obj.pyg_id, label: obj.component_group }),
                );
                me.setState({ paycomponentgroup: Arr });
              }
            } else {
              me.setState({ button_disabled: false });
              // showNotifications('Fail', 'Failed Check', 'danger');
            }
            resolve2();
          })
          .catch((error) => {
            console.log(error);
            me.setState({ button_disabled: false });
          });
      });
    }

    // async function getJobBand() {
    //   return new Promise((resolve2, reject2) => {
    //     axios.post(
    //       `${apiws}/orgjobband/getAll/`,
    //       '',
    //       config,
    //     ).then((res) => {
    //       const dataResponse = res.data.data;
    //       if (res.data.status === 'ok') {
    //         const Arr = [];
    //         dataResponse.map(obj => (
    //           Arr.push({ value: obj.jbd_id, label: obj.label })
    //         ));
    //         me.setState({ orgjobband: Arr });
    //       }
    //       resolve2();
    //     }).catch((error) => {
    //       console.log(error);
    //     });
    //   })
    // }

    async function getOffice() {
      return new Promise((resolve2, reject2) => {
        axios
          .post(`${apiws}/orgoffice/getAll`, '', config)
          .then((res) => {
            const dataResponse = res.data.data;
            if (res.data.status === 'ok') {
              const Arr = [];
              dataResponse.map((obj) =>
                Arr.push({ value: obj.off_id, label: obj.office }),
              );
              me.setState({ orgoffice: Arr });
            }
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getGeofence() {
      return new Promise((resolve2, reject2) => {
        axios
          .post(`${apiws}/geofence/getAll`, '', config)
          .then((res) => {
            const dataResponse = res.data.data;
            if (res.data.status === 'ok') {
              const Arr = [];
              dataResponse.map((obj) =>
                Arr.push({ value: obj.ogf_id, label: obj.title }),
              );
              me.setState({ orggeofence: Arr });
            }
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getJobLevel() {
      return new Promise((resolve2, reject2) => {
        axios
          .post(`${apiws}/orgjoblevel/getAll/`, '', config)
          .then((response) => {
            if (response.data.status === 'ok') {
              const dataResponse = response.data.data;
              const Arr = [];
              dataResponse.map((obj) =>
                Arr.push({ value: obj.job_id, label: obj.job_level }),
              );
              me.setState({ orgjoblevel: Arr });
            }
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    function getJobBand() {
      return new Promise((resolve2, reject2) => {
        axios
          .post(`${apiws}/orgjobband/getAll/`, '', config)
          .then((response) => {
            if (response.data.status === 'ok') {
              const dataResponse = response.data.data;
              const Arr = [];
              dataResponse.map((obj) =>
                Arr.push({ value: obj.jbd_id, label: obj.job_band }),
              );
              me.setState({ orgjobband: Arr });
            } else {
              // me.setState({ button_disabled: false });
              // showNotifications('Fail', 'Save Failed', 'danger');
            }
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getOrganization() {
      return new Promise((resolve2, reject) => {
        axios
          .post(`${apiws}/orgorganization/getAll/`, '', config)
          .then((response) => {
            if (response.data.status === 'ok') {
              const dataResponse = response.data.data;
              const Arr = [];
              dataResponse.map((obj) =>
                Arr.push({ value: obj.org_id, label: obj.unit_organization }),
              );
              me.setState({ orgorganization: Arr });
            }
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getEmployeementStatus() {
      return new Promise((resolve2, reject) => {
        let url_ems;
        if (me.props.parmid.state.statusEdit) {
          url_ems = `${apiws}/rtbemployeementstatus/getAll`;
        } else {
          url_ems = `${apiws}/rtbemployeementstatus/getAllItemByIsActive`;
        }

        axios
          .post(url_ems, '', config)
          .then((response) => {
            if (response.data.status === 'ok') {
              const dataResponse = response.data.data;
              const Arr = [];
              dataResponse.map((obj) =>
                Arr.push({
                  value: obj.ems_id,
                  label: obj.ems_employeement_status,
                }),
              );
              me.setState({ rtbemployeementstatus: Arr });
            }
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getAttSetting() {
      return new Promise((resolve2, reject) => {
        axios
          .post(`${apiws}/attsetting/getAll/`, '', config)
          .then((response) => {
            if (response.data.status === 'ok') {
              const dataResponse = response.data.data;
              const Arr = [];
              dataResponse.map((obj) =>
                Arr.push({ value: obj.ats_id, label: obj.ats_name }),
              );
              me.setState({ attshift: Arr });
            }
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getRtbTaxStatus() {
      return new Promise((resolve2, reject2) => {
        axios
          .post(`${apiws}/rtbtaxstatus/getAll/`, '', config)
          .then((response) => {
            const dataResponse = response.data.data;
            const Arr = [];
            dataResponse.map((obj) =>
              Arr.push({ value: obj.rtx_id, label: obj.tax_status }),
            );

            me.setState({ taxstatus: Arr });
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getPayTaxMethod() {
      return new Promise((resolve2, reject) => {
        axios
          .post(`${apiws}/paytaxmethod/getAll/`, '', config)
          .then((response) => {
            const dataResponse = response.data.data;
            const Arr = [];
            dataResponse.map((obj) =>
              Arr.push({ value: obj.ptm_id, label: obj.tax_method }),
            );

            me.setState({ paytaxmethod: Arr });
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getRtbBank() {
      return new Promise((resolve2, reject) => {
        axios
          .post(`${apiws}/rtbbank/getAll/`, '', config)
          .then((response) => {
            const dataResponse = response.data.data;
            const Arr = [];
            dataResponse.map((obj) =>
              Arr.push({ value: obj.rtk_id, label: obj.bank }),
            );
            me.setState({ rtbbank: Arr });
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getPayProrate() {
      return new Promise((resolve2, reject2) => {
        axios
          .post(`${apiws}/payproratesetting/getAll/`, '', config)
          .then((response) => {
            const dataResponse = response.data.data;
            const Arr = [];
            dataResponse.map((obj) =>
              Arr.push({ value: obj.pys_id, label: obj.prorate_setting }),
            );
            //  me.setState({ slcunit: Arr });
            me.setState({ payproratesetting: Arr });
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getRtbReligion() {
      return new Promise((resolve2, reject) => {
        axios
          .post(`${apiws}/rtbreligion/getAll/`, '', config)
          .then((response) => {
            const dataResponse = response.data.data;
            const Arr = [];
            dataResponse.map((obj) =>
              Arr.push({ value: obj.rtg_id, label: obj.religion }),
            );
            //  me.setState({ slcunit: Arr });
            me.setState({ rtbreligion: Arr });
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getRtbIdentityType() {
      return new Promise((resolve2, reject) => {
        axios
          .post(`${apiws}/rtbidentitytype/getAll/`, '', config)
          .then((response) => {
            const dataResponse = response.data.data;
            const Arr = [];
            dataResponse.map((obj) =>
              Arr.push({ value: obj.rti_id, label: obj.identity_type }),
            );
            //  me.setState({ slcunit: Arr });
            me.setState({ rtbidentitytype: Arr });
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getRtbMartialStatus() {
      return new Promise((resolve2, reject) => {
        axios
          .post(`${apiws}/rtbmaritalstatus/getAll/`, '', config)
          .then((response) => {
            const dataResponse = response.data.data;
            const Arr = [];
            dataResponse.map((obj) =>
              Arr.push({ value: obj.rtm_id, label: obj.marital_status }),
            );
            //  me.setState({ slcunit: Arr });
            me.setState({ rtbmaritalstatus: Arr });
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getCountry() {
      return new Promise((resolve2, reject) => {
        axios
          .post(`${apiws}/appcountry/getAll/`, '', config)
          .then((response) => {
            const dataResponse = response.data.data;
            const Arr = [];
            dataResponse.map((obj) =>
              Arr.push({ value: obj.ctr_id, label: obj.country }),
            );
            //  me.setState({ slcunit: Arr });
            me.setState({ rtbnationality: Arr });
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getRtbFamilyRelation() {
      return new Promise((resolve2, reject) => {
        axios
          .post(`${apiws}/rtbfamilyrelation/getAll/`, '', config)
          .then((response) => {
            const dataResponse = response.data.data;
            const Arr = [];
            dataResponse.map((obj) =>
              Arr.push({ value: obj.rtf_id, label: obj.family_relation }),
            );
            //  me.setState({ slcunit: Arr });
            me.setState({ rtbfamilyrelation: Arr });
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    async function getRtbBloodType() {
      return new Promise((resolve2, reject) => {
        axios
          .post(`${apiws}/rtbbloodtype/getall/`, '', config)
          .then((response) => {
            const dataResponse = response.data.data;
            const Arr = [];
            dataResponse.map((obj) =>
              Arr.push({ value: obj.rtb_id, label: obj.blood_type }),
            );
            me.setState({ rtbbloodtype: Arr });
            resolve2();
          })
          .catch((error) => {
            console.log(error);
          });
        // console.log(datatree);
      });
    }

    async function getEmployeeCode() {
      return new Promise((resolve2, reject) => {
        axios
          .post(`${apiws}/employee/getallcode`, 'emp=', config)
          .then((response) => {
            const dataResponse = response.data.data;
            const Arr = [];
            dataResponse.map((obj) => Arr.push(obj.emp_code));
            me.setState({ allempcode: Arr });
            resolve2();
          })
          .catch(() => {
            // console.log(error);
          });
      });
    }

    await getCompany();
    await getPayComponentGroupRel();
    await getPayTaxClass();
    await getAllItemRecurring();
    await getPayComponentGroup();
    await getJobBand();
    await getOffice();
    await getGeofence();
    await getJobLevel();
    await getOrganization();
    await getEmployeementStatus();
    await getAttSetting();
    await getRtbTaxStatus();
    await getPayTaxMethod();
    await getRtbBank();
    await getPayProrate();
    await getRtbReligion();
    await getRtbIdentityType();
    await getRtbMartialStatus();
    await getCountry();
    await getRtbFamilyRelation();
    await getRtbBloodType();
    await getEmployeeCode();
    await getApprovalEmp();

    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  async loadEmployee(id) {
    return new Promise((resolve, reject) => {
      const { token, apiws } = this.state;
      const config = {
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      axios
        .post(`${apiws}/employee/getAllItemById/${id}`, '', config)
        .then((response) => {
          // prompt('', JSON.stringify(response));
          const dataResponse = response.data.data;
          // console.log(JSON.stringify(dataResponse));
          const identityEmployee = [];
          if (dataResponse.identity.length !== 0) {
            dataResponse.identity.forEach((element) => {
              identityEmployee.push({
                identity: element.rti_id,
                identity_no: element.identity_number,
                expired_date:
                  element.expired_date === null
                    ? null
                    : moment(element.expired_date),
                file_path: element.file_path,
              });
            });
          } else {
            identityEmployee.push({
              identity: '',
              identity_no: '',
              expired_date: null,
              file_path: null,
              // identity_file:'',
            });
          }
          const adjustment = [];
          if (dataResponse.adjustment.length !== 0) {
            adjustment.push({
              pad_id: dataResponse.adjustment[0].pad_id,
              adjustment_start_date:
                dataResponse.adjustment[0].adjustment_start_date,
              adjustment_end_date:
                dataResponse.adjustment[0].adjustment_end_date,
              backpay_date: dataResponse.adjustment[0].backpay_date,
            });
          } else {
            adjustment.push({
              pad_id: '',
              adjustment_start_date: null,
              adjustment_end_date: null,
              backpay_date: null,
            });
          }

          dataResponse.pay_component.map((obj) =>
            this.state.valuecomp.push(obj.pec_value),
          );

          dataResponse.pay_component.map((obj) =>
            this.state.oldvaluecomp.push(obj.old_value),
          );

          if (dataResponse.superordinat.length !== 0) {
            this.setState({
              superordinat: dataResponse.superordinat[0].emp_id,
            });
          } else {
            this.setState({ superordinat: '' });
          }
          this.setState({
            saveonthefly: {
              ...dataResponse.user[0],
              emp_parent: this.state.superordinat,
              dob: moment(dataResponse.user[0].dob),
              join_date: moment(dataResponse.user[0].join_date),
              npwp_date:
                dataResponse.user[0].npwp_date === null
                  ? dataResponse.user[0].npwp_date
                  : moment(dataResponse.user[0].npwp_date),
              dyn_identity: identityEmployee,
              component_payroll: dataResponse.pay_component,
              // off_id: dataResponse.his_office[0].off_id,
              // jbd_id: dataResponse.his_job_band[0].jbd_id,
              // pos_id: dataResponse.his_position[0].pos_id,
              pad_id: adjustment[0].pad_id,
              adjustment_start_date:
                adjustment[0].adjustment_start_date === null
                  ? adjustment[0].adjustment_start_date
                  : moment(adjustment[0].adjustment_start_date),
              adjustment_end_date:
                adjustment[0].adjustment_end_date === null
                  ? adjustment[0].adjustment_end_date
                  : moment(adjustment[0].adjustment_end_date),
              backpay_date:
                adjustment[0].backpay_date === null
                  ? adjustment[0].backpay_date
                  : moment(adjustment[0].backpay_date),
              cmp_id: dataResponse.company[0].cmp_id,
            },
          });
          this.getJobName({ value: `${dataResponse.user[0].org_id}` });
          this.setState({ orgId: dataResponse.user[0].org_id });
          this.getJobTitle({ value: `${dataResponse.user[0].jbn_id}` });
          this.getJobGrade({ value: `${dataResponse.user[0].jbd_id}` });
          this.getSuperordinat({ value: `${dataResponse.user[0].pos_id}` });
        })
        .catch((error) => {
          console.log(error);
        });

      // Update dynamic update tax 10/05/2021
      // if (this.props.parmid.state.statusEdit) {
      //   // console.log(new Date().getMonth() + 1);
      //   if (new Date().getMonth() + 1 !== 1) {
      //     this.setState({ disabledTax: true });
      //     this.setState({ disabledTaxMethod: true });
      //   }
      // }
    });
  }

  nextPage = (value) => {
    this.setState({
      page: this.state.page + 1,
    });
  };

  updateValues = (value) => {
    let citizen;
    let displaycountry;
    if (value.citizen !== null) {
      citizen =
        typeof value.citizen === 'object' ? value.citizen.value : value.citizen;
    }
    if (citizen === 2) {
      displaycountry = '2';
    } else {
      displaycountry = '1';
    }
    // console.log(value.dyn_identity);
    this.setState({
      saveonthefly: {
        ...this.state.saveonthefly,
        ...value,
        displaycountry,
      },
    });
    // console.log(this.state.saveonthefly);
    if (typeof value.valuecomp !== 'undefined') {
      this.setState({ valuecomp: value.valuecomp });
    }
    if (typeof value.oldvaluecomp !== 'undefined') {
      this.setState({ oldvaluecomp: value.oldvaluecomp });
    }
    // this.updateTouched(value);
  };

  previousPage = () => {
    this.setState({ page: this.state.page - 1 });
  };

  setPage = (num) => {
    this.setState({ page: num });
  };

  validate = (values) => {
    console.log(values?.ogf_id, 'validate nih');

    let validation = {
      job_level: {
        fieldLabel: 'Job Level',
        required: true,
      },
      citizen: {
        fieldLabel: 'Citizenship',
        required: true,
      },
      job_level_abbreviaton: {
        fieldLabel: 'Job Level Abbreviaton',
        required: true,
      },
      emp_code: {
        fieldLabel: 'Employee code',
        required: true,
      },
      first_name: {
        fieldLabel: 'Employee name',
        required: true,
      },
      last_name: {
        fieldLabel: 'Last name',
        required: true,
      },
      email: {
        fieldLabel: 'Email',
        required: true,
        isEmail: true,
      },
      mobile: {
        fieldLabel: 'Mobile Phone',
        required: true,
      },
      postal_code: {
        fieldLabel: 'Postal Code',
        required: true,
      },
      tcs_id: {
        fieldLabel: 'Tax Recipient',
        required: true,
      },
      emp_address: {
        fieldLabel: 'Address',
        required: true,
      },
      dob: {
        fieldLabel: 'Date of birth',
        required: true,
      },
      pob: {
        fieldLabel: 'Place of birth',
        required: true,
      },
      sex: {
        fieldLabel: 'Sex',
        required: true,
      },
      last_education: {
        fieldLabel: 'Last Education',
        required: true,
      },
      rtb_id: {
        fieldLabel: 'Blood type',
        required: true,
      },
      rtm_id: {
        fieldLabel: 'Marital status',
        required: true,
      },
      rtg_id: {
        fieldLabel: 'Religion',
        required: true,
      },
      finger_id: {
        fieldLabel: 'Finger ID',
        required: true,
      },
      rtx_id: {
        fieldLabel: 'Tax status',
        required: true,
      },
      pys_id: {
        fieldLabel: 'Prorate setting',
        required: true,
      },
      overtime: {
        fieldLabel: 'Overtime',
        required: true,
      },
      bpjs_tk_registered: {
        fieldLabel: 'BPJS TK',
        required: true,
      },
      bpjs_ks_registered: {
        fieldLabel: 'BPJS KS',
        required: true,
      },
      rtk_id: {
        fieldLabel: 'Bank',
        required: true,
      },
      bank_account: {
        fieldLabel: 'Bank account',
        required: true,
      },
      bank_account_holder: {
        fieldLabel: 'Bank account holder',
        required: true,
      },
      cmp_id: {
        fieldLabel: 'Company',
        required: true,
      },
      off_id: {
        fieldLabel: 'Office',
        required: true,
      },
      jbd_id: {
        fieldLabel: 'Job field',
        required: true,
      },
      ptm_id: {
        fieldLabel: 'Default tax method',
        required: true,
      },
      jbn_id: {
        fieldLabel: 'Job name',
        required: true,
      },
      jgd_id: {
        fieldLabel: 'Job Grade',
        required: true,
      },
      pos_id: {
        fieldLabel: 'Position',
        required: true,
      },
      org_id: {
        fieldLabel: 'Organization',
        required: true,
      },
      ems_id: {
        fieldLabel: 'Employee status',
        required: true,
      },
      join_date: {
        fieldLabel: 'Join date',
        required: true,
      },
      ats_id: {
        fieldLabel: 'Attendance',
        required: true,
      },
      ogf_id: {
        fieldLabel: 'Geofence locations',
        required: true,
      },
      adjustment_start_date: {
        fieldLabel: 'Payroll Start Date',
        required: true,
      },
      component_payroll: {
        dynamicField: true,
        children: [{ name: 'pec_value', label: 'Value', required: true }],
      },
    };
    if (values?.identity_no_sim) {
      validation = {
        ...validation,
        expired_date_sim: {
          fieldLabel: 'Expired Date',
          required: true,
        },
      };
    }
    if (values?.identity_no_paspor) {
      validation = {
        ...validation,
        expired_date_paspor: {
          fieldLabel: 'Date',
          required: true,
        },
      };
    }
    if (
      !values?.identity_no_ktp &&
      !values?.identity_no_sim &&
      !values?.identity_no_paspor
    ) {
      validation = {
        ...validation,
        identity_no_ktp: {
          fieldLabel: 'Identity Number',
          required: true,
        },
        identity_no_sim: {
          fieldLabel: 'Identity Number',
          required: true,
        },
        identity_no_paspor: {
          fieldLabel: 'Identity Number',
          required: true,
        },
      };
    }
    const errors = utils.validate(validation, values);

    if (typeof values.citizen === 'object') {
      if (values.citizen.value === 2) {
        if (!values.ctr_id) {
          errors.ctr_id = 'Country shouldn`t be empty';
        }
      }
    }
    if (!values?.ogf_id?.length || !values?.ogf_id) {
      errors.ogf_id = 'Geofence locations shouldn`t be empty';
    } else if (values?.ogf_id?.length > 5) {
      errors.ogf_id = 'Maximum 5 geofence locations';
    }

    if (this.state.fingerbyemp.length === 0) {
      if (this.state.allfingerid.indexOf(values.finger_id) !== -1) {
        errors.finger_id = 'Finger ID already exist';
      }
    } else if (this.state.fingerbyemp.indexOf(values.finger_id) !== -1) {
      errors.finger_id = 'Finger id is available';
    }
    if (this.state.allempcode.length > 0) {
      if (this.state.allempcode.includes(values.emp_code)) {
        errors.emp_code = 'Employee code already exist';
      }
    }

    return errors;
  };

  employeeStatus(val) {
    this.setState({ empStatus: val.value });
  }

  checkAuth() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/token/isOtp`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          if (response.data.otp) {
            this.setState({ validateToken: true });
          }
        }
      })
      .catch((error) => {
        // showNotifications('Fail', 'Delete Failed', 'danger');
      });
  }

  render() {
    const { t } = this.props;
    const {
      page,
      taxstatus,
      rtbbank,
      payproratesetting,
      rtbemployeementstatus,
      rtbreligion,
      rtbidentitytype,
      rtbmaritalstatus,
      paytaxmethod,
      rtbbloodtype,
      rtbfamilyrelation,
      rtbcompany,
      paycomponentgroup,
      paycomponentgrouprel,
      paycomponentrecur,
      orgjobbandgrade,
      orgjobname,
      orgoffice,
      orgjoblevel,
      orgjobband,
      orgposition,
      orgorganization,
      orgjobgrade,
      attshift,
      saveonthefly,
      emphierarchy,
      rtbnationality,
      disabledTax,
      disabledTaxMethod,
      valuecomp,
      oldvaluecomp,
      displaySave,
      displaySpinner,
      paytaxclass,
      validateToken,
      orggeofence,
      isLoaded,
      empApproval,
    } = this.state;

    if (this.state.redirect_emp) {
      return (
        <Redirect
          to={{
            pathname: '/administration/employee',
            state: {
              men_id: this.props.parmid.state.men_employee,
              fga_id: this.props.parmid.state.fga_id,
            },
          }}
        />
      );
    }

    const backBtn = (
      <Button
        color="primary"
        className="btn btn-success"
        onClick={this.previousPage}
      >
        {t('FRM.BACK')}
      </Button>
    );
    return (
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <div className="tabbable">
              <nav>
                <ul className="nav nav-tabs nav-fill wizard2" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`${page === 1 ? 'active' : ''}`}
                      data-toggle="tab"
                      aria-expanded="false"
                    >
                      <span className="nmbr">1</span>
                      {t('FRM.EMPLOYEE_DATA')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`${page === 2 ? 'active' : ''}`}
                      data-toggle="tab"
                      aria-expanded="false"
                    >
                      <span className="nmbr">2</span>
                      {t('LBL.PAYROLL')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`${page === 3 ? 'active' : ''}`}
                      data-toggle="tab"
                      aria-expanded="false"
                    >
                      <span className="nmbr">3</span>
                      {t('LBL.COMPANY')}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="wizard">
              {/* <div className="wizard__steps" >
            <div
              className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}
            >
              <div className="tab_title_wizard">
                <Badge className="badge_wizard" color="light" pill>1</Badge>
                <span className="badge_title_wizard">Employee Data </span>
              </div>
            </div>
            <div
              className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}
            >
              <div className="tab_title_wizard">
                <Badge className="badge_wizard" color="light" pill>2</Badge>
                <span className="badge_title_wizard">Payroll </span>
              </div>
            </div>
            <div
              className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}
            >
              <div className="tab_title_wizard">
                <Badge className="badge_wizard" color="light" pill>3</Badge>
                <span className="badge_title_wizard">Company </span>
              </div>
            </div>
          </div> */}
              <div className="wizard__form-wrapper">
                {page === 1 && (
                  <WizardFormOne
                    onSubmit={this.nextPage}
                    onChange={this.updateValues}
                    touched={this.state.touched}
                    rtbbloodtype={rtbbloodtype}
                    rtbreligion={rtbreligion}
                    rtbidentitytype={rtbidentitytype}
                    rtbmaritalstatus={rtbmaritalstatus}
                    initialValues={{ dyn_identity: [{ disabled: false }] }}
                    tempform={saveonthefly}
                    rtbfamilyrelation={rtbfamilyrelation}
                    rtbnationality={rtbnationality}
                    validate={this.validate}
                    men_id={this.props.parmid.state.men_employee}
                    fga_id={this.props.parmid.state.fga_id}
                  />
                )}
                {page === 2 && validateToken === true && (
                  <WizardFormTwo
                    rtbtaxstatus={taxstatus}
                    paytaxmethod={paytaxmethod}
                    rtbpayproratesetting={payproratesetting}
                    paycomponentgroup={paycomponentgroup}
                    paycomponentgrouprel={paycomponentgrouprel}
                    paycomponentrecur={paycomponentrecur}
                    previousPage={this.previousPage}
                    rtbbank={rtbbank}
                    tempform={saveonthefly}
                    onSubmit={this.nextPage}
                    onChange={this.updateValues}
                    validate={this.validate}
                    load={this.loadEmployee}
                    valuecomp={valuecomp}
                    oldvaluecomp={oldvaluecomp}
                    paytaxclass={paytaxclass}
                    disabledTaxStatus={disabledTax}
                    disabledTaxMethod={disabledTaxMethod}
                  />
                )}
                {page === 2 && validateToken === false && (
                  <Authenticator checkAuth={this.checkAuth} backBtn={backBtn} />
                )}
                {page === 3 && (
                  <WizardFormThree
                    parmid={this.props.parmid}
                    rtbcompany={rtbcompany}
                    rtboffice={orgoffice}
                    rtbgeofence={orggeofence}
                    rtborgjobband={orgjobband}
                    rtborgjoblevel={orgjoblevel}
                    rtborgjobname={orgjobname}
                    rtborgposition={orgposition}
                    rtborgjobgrade={orgjobgrade}
                    rtborgjobbandgrade={orgjobbandgrade}
                    rtbattshift={attshift}
                    rtborgorganization={orgorganization}
                    emphierarchy={emphierarchy}
                    tempform={saveonthefly}
                    rtbemployeementstatus={rtbemployeementstatus}
                    previousPage={this.previousPage}
                    onSubmit={this.submit}
                    onChange={this.updateValues}
                    validate={this.validate}
                    getjobname={this.getJobName}
                    getjobtitle={this.getJobTitle}
                    getjobgrade={this.getJobGrade}
                    getSuperordinat={this.getSuperordinat}
                    displaySave={displaySave}
                    displaySpinner={displaySpinner}
                    empStatus={this.state.empStatus}
                    employeeStatus={this.employeeStatus}
                    empApproval={empApproval}
                  />
                )}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default translate('global')(WizardForm);
