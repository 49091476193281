/* eslint-disable no-unneeded-ternary */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';
import axios from 'axios';
import ls from 'local-storage';
import showNotifications from '../../../Preferences/notificationMessages';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import ReactExport from 'react-export-excel';
import { jsonToCSV } from 'react-papaparse';
import { CSVLink } from 'react-csv';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import Spinner from 'react-bootstrap/Spinner';
import FileSaver from 'file-saver';
import { security } from '../../../../security';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ReportAttendanceForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    // onProcess: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    displayForm: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { url, t } = props;
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      arrPeriod: [],
      urlData: '',
      orgorganization: [],
      company: [],
      empcurrent: [],
      search: '',
      org: '',
      dataexcel: '',
      type: '',
      csvData: '',
      arrYear: [],
      download: false,
      displayTable: 'none',
      displayLoad: 'none',
      displaySave: '',
      condYear: '',
      condPypId: '',
      condEmpId: '',
      condTaxValue: '',
      condCmp: '',
      npwpcompany: [],
      type: '',
      formatType: '',
      period_month: '',
    };
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.TAX_PERIOD'),
      t('LBL.EMPLOYEE'),
      t('LBL.TAX_YEAR'),
      t('LBL.RECTIFICATION'),
      t('LBL.NPWP'),
      t('LBL.NAME'),
      t('LBL.TAX_CODE'),
      t('LBL.BRUTO_TOTAL'),
      t('LBL.PPH_TOTAL'),
      t('LBL.COUNTRY_CODE'),
    ];
    this.loadSelectBox = this.loadSelectBox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getPeriodProcess = this.getPeriodProcess.bind(this);
    this.getYear = this.getYear.bind(this);
    this.getReport = this.getReport.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.loadSelectBox();
      this.getPeriodProcess();
      this.getYear();
      this.getTableData();
    });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.TAX_PERIOD'),
      t('LBL.EMPLOYEE'),
      t('LBL.TAX_YEAR'),
      t('LBL.RECTIFICATION'),
      t('LBL.NPWP'),
      t('LBL.NAME'),
      t('LBL.TAX_CODE'),
      t('LBL.BRUTO_TOTAL'),
      t('LBL.PPH_TOTAL'),
      t('LBL.COUNTRY_CODE'),
    ];
  }

  getTableData() {
    this.child.reload();
    this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
  }

  getYear() {
    const arr = [];
    for (let i = 0; i < 5; i += 1) {
      const currentYear = new Date().getFullYear() - i;
      arr.push({ value: currentYear, label: currentYear });
    }
    this.setState({ arrYear: arr });
  }

  getNpwpCompany(id) {
    console.log(id);
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .get(`${apiws}/orgcompany/getAllItemById/${id}`, config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const arr = [];
          dataResponse.map((obj) =>
            arr.push({ value: obj.cmp_id, label: obj.company_npwp }),
          );
          this.setState({ npwpcompany: arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getCompany(id) {
    console.log(id);
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${apiws}/employee/getAllItemById/${id}`, '', config)
      .then((res) => {
        const dataResponse = res.data.data.user;
        if (res.data.status === 'ok') {
          const arr = [];
          dataResponse.map((obj) =>
            arr.push({ value: obj.cmp_id, label: obj.company }),
          );
          this.setState({ npwpcompany: arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getReport(url) {
    this.setState({
      displayTable: 'none',
      displayLoad: 'none',
      displaySave: '',
    });
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const conds = {
      pyp_id: this.state.condPypId,
      year: this.state.condYear,
      emp_id: this.state.condEmpId,
      tax_value: this.state.condTaxValue,
      cmp_id: this.state.condCmp,
      type: this.state.type,
      period_month: this.state.period_month,
      formatType: this.state.formatType,
    };
    const array = [];
    const bodyParameters = {
      cond: conds,
    };

    axios.post(url, bodyParameters, config).then((response) => {
      if (response.data.status === 'ok') {
        if (response.data.data.length === 0) {
          setTimeout(() => {
            showNotifications('Success', 'Empty Data', 'success');
          }, 1000);
        } else {
          showNotifications('Success', 'Available Data', 'success');
          axios({
            method: 'get',
            url: security.decrypt('EXCEL_LINK', response.data.url),
            responseType: 'blob',
          }).then((res) => {
            FileSaver.saveAs(res.data, response.data?.name);
          });
        }
      }
    });
  }

  getPeriodProcess() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/payperiod/getAllItemTax`, '', config)
      .then((response) => {
        const pcs = response.data.data;
        const array = [];
        if (pcs.length === 0) {
          this.setState({ arrPcs: array });
        } else {
          pcs.map((obj) =>
            array.push({
              value: obj.pyp_id,
              label: obj.period_code,
              start: obj.period_start,
              end: obj.period_end,
              status: obj.is_process_tax,
            }),
          );
          this.setState({ arrPcs: array });
        }
      });
  }
  loadSelectBox() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${apiws}/orgcompany/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          const arrnpwp = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.cmp_id, label: obj.company }),
          );
          this.setState({ company: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${apiws}/employee/getAllItemOrg/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({
              value: obj.emp_id,
              label: obj.employee,
              label2: obj.emp_code,
            }),
          );
          this.setState({ empcurrent: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${apiws}/orgcompany/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.cmp_id, label: obj.company }),
          );
          this.setState({ company: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(`${apiws}/orgorganization/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.org_id, label: obj.unit_organization }),
          );
          this.setState({ orgorganization: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleClick = (e) => {
    e.preventDefault();
  };

  handleSubmit(values) {
    this.setState({ displayLoad: '', displaySave: 'none' });
    console.log(values);
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const url = `${ls.get('ws_ip')}/reportpayroll/getReporttaxa1`;
    const period =
      typeof values.period === 'undefined' || values.period === null
        ? ''
        : values.period.value;
    const period_month =
      typeof values.period_month === 'undefined' || values.period_month === null
        ? ''
        : values.period_month.value;
    const formatType =
      typeof values.formatType === 'undefined' || values.formatType === null
        ? ''
        : values.formatType;
    const year =
      typeof values.period_year === 'undefined' || values.period_year === null
        ? ''
        : values.period_year.value;
    const empId =
      typeof values.employee === 'undefined' || values.employee === null
        ? ''
        : values.employee.value;
    const cmp_id =
      typeof values.company === 'undefined' || values.company === null
        ? ''
        : values.company.value;
    const type = values.type_report.value;
    const taxval =
      typeof values.tax_value === 'undefined' || values.tax_value === null
        ? ''
        : values.tax_value.value;
    const valperiod = year === '' && period === '' ? false : true;
    if (valperiod) {
      const pypId = period;
      setTimeout(() => {
        this.setState({ condYear: year });
        this.setState({ condPypId: pypId });
        this.setState({ condEmpId: empId });
        this.setState({ condTaxValue: taxval });
        this.setState({ condCmp: cmp_id });
        this.setState({ type: type });
        this.setState({ period_month: period_month });
        this.setState({ formatType: formatType });
        this.setState({ urlData: url });
        setTimeout(() => {
          if (values.type_report.value === '1') {
            this.setState({ displayTable: '' });
            const conds = {
              pyp_id: period,
              year: year,
              emp_id: empId,
              tax_value: taxval,
              cmp_id: cmp_id,
              period_month: period_month,
              formatType: formatType,
              type: '1',
            };
            const array = [];
            const bodyParameters = {
              cond: conds,
            };
            axios.post(url, bodyParameters, config).then((res) => {
              if (res.data.status === 'ok') {
                if (res.data.data.length === 0) {
                  setTimeout(() => {
                    showNotifications('Success', 'Empty Data', 'success');
                  }, 1000);
                  this.getTableData();
                } else {
                  setTimeout(() => {
                    this.getTableData();
                    showNotifications('Success', 'Available Data', 'success');
                  }, 1000);
                }
              }
            });
          } else if (values.type_report.value === '2') {
            this.setState({ displayTable: 'none' });
            this.getReport(url);
          } else if (values.type_report.value === '3') {
            // window.open(`${ls.get('ws_ip')}/report/certificate-tax`, 'blank');
            this.props.displayForm(year, empId, cmp_id);
          }
        }, 1000);
      }, 100);
    }
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      csvData,
      arrYear,
      company,
      arrPcs,
      empcurrent,
      urlData,
      condYear,
      condPypId,
      displayTable,
      displayLoad,
      displaySave,
      condEmpId,
      condTaxValue,
      npwpcompany,
      condCmp,
    } = this.state;
    return (
      <Container>
        <Row style={{ paddingLeft: '20px' }}>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody className="p0">
                <Row className="m5">
                  <Col md={12}>
                    <h3 className="page-title">
                      {t('LBL.TAX')} - {t('MEN.REPORT')}
                    </h3>
                    <h3 className="page-subhead subhead">
                      {t('LBL.EXPORT_TO_CSV')}
                    </h3>
                  </Col>
                </Row>
                <Row className="m5">
                  <Col md={12} xs={12} lg={12} xl={12}>
                    <form
                      className="form form--horizontal mt15"
                      name="bpa1report"
                      id="bpa1report"
                      onSubmit={handleSubmit(this.handleSubmit)}
                    >
                      <Container>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                Format
                              </span>
                              <div className="form__form-group-field">
                                <Container className="pl-pr-0">
                                  <Row>
                                    <Col sm="12" md="12" lg="12" xl="12">
                                      <Container className="pl-pr-0">
                                        <Row>
                                          <Col sm="12" md="6" lg="6" xl="6">
                                            <div className="form__form-group">
                                              <div className="form__form-group-field form_custom_w100">
                                                <Field
                                                  name="formatType"
                                                  component={
                                                    renderRadioButtonField
                                                  }
                                                  label="Default"
                                                  defaultChecked
                                                  radioValue="1"
                                                  onChange={(value) => {
                                                    this.setState({
                                                      formatType: value[0],
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                          <Col sm="12" md="6" lg="6" xl="6">
                                            <div className="form__form-group">
                                              <div className="form__form-group-field form_custom_w100">
                                                <Field
                                                  name="formatType"
                                                  component={
                                                    renderRadioButtonField
                                                  }
                                                  label="New"
                                                  radioValue="0"
                                                  onChange={(value) => {
                                                    this.setState({
                                                      formatType: value[0],
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Container>
                                    </Col>
                                  </Row>
                                </Container>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">
                                {t('LBL.YEAR')}
                              </span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="period_year"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrYear}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.FORMAT_REPORT')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="format_report"
                                  component={renderSelectField}
                                  type="text"
                                  options={[
                                    { value: '1', label: 'Monthly Tax Report' },
                                    { value: '2', label: 'Bukti Potong A1' },
                                  ]}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row> */}
                        {this.state.formatType == 0 && (
                          <Row>
                            <Col md={6} xs={12} lg={6} xl={6}>
                              <div className="form__form-group form__form-group--address">
                                <span className="form__form-group-label">
                                  {t('LBL.PERIOD')}
                                </span>
                                <div className="form__form-group-field form-flex">
                                  <Field
                                    name="period_month"
                                    component={renderSelectField}
                                    type="text"
                                    options={[
                                      { value: '1', label: '1' },
                                      { value: '2', label: '2' },
                                      { value: '3', label: '3' },
                                      { value: '4', label: '4' },
                                      { value: '5', label: '5' },
                                      { value: '6', label: '6' },
                                      { value: '7', label: '7' },
                                      { value: '8', label: '8' },
                                      { value: '9', label: '9' },
                                      { value: '10', label: '10' },
                                      { value: '11', label: '11' },
                                      { value: '12', label: '12' },
                                    ]}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )}

                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.EMPLOYEE_NAME')}
                              </span>
                              <div className="form__form-group-field products-list__search">
                                <Field
                                  name="employee"
                                  component={renderSelectField}
                                  type="text"
                                  options={empcurrent}
                                  onChange={(val) => this.getCompany(val.value)}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col md={12} xs={6} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.COMPANY')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="company"
                                  component={renderSelectField}
                                  type="text"
                                  options={company}
                                  onChange={val => (this.getNpwpCompany(val.value))}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row> */}
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            {/* <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.NPWP')} {t('LBL.COMPANY')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="npwp"
                                  component={renderSelectField}
                                  type="text"
                                  options={npwpcompany}
                                />
                              </div>
                            </div> */}
                            <Row>
                              <Col sm="12" md="12" xl="12" lg="12">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    {t('LBL.RECTIFICATION')}
                                  </span>
                                  <div className="form__form-group-field">
                                    <Container className="pl-pr-0">
                                      <Row>
                                        <Col sm="12" md="12" lg="12" xl="12">
                                          <Container className="pl-pr-0">
                                            <Row>
                                              <Col sm="12" md="6" lg="6" xl="6">
                                                <div className="form__form-group">
                                                  <div className="form__form-group-field form_custom_w100">
                                                    <Field
                                                      name="radioLeaveAllowance"
                                                      component={
                                                        renderRadioButtonField
                                                      }
                                                      label={t('LBL.YES')}
                                                      radioValue="1"
                                                    />
                                                  </div>
                                                </div>
                                              </Col>
                                              <Col sm="12" md="6" lg="6" xl="6">
                                                <div className="form__form-group">
                                                  <div className="form__form-group-field form_custom_w100">
                                                    <Field
                                                      name="radioLeaveAllowance"
                                                      component={
                                                        renderRadioButtonField
                                                      }
                                                      label={t('LBL.NO')}
                                                      radioValue="0"
                                                    />
                                                  </div>
                                                </div>
                                              </Col>
                                            </Row>
                                          </Container>
                                        </Col>
                                      </Row>
                                    </Container>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} xs={12} lg={12} xl={12}>
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    {t('LBL.TYPE_REPORT')}
                                  </span>
                                  <div className="form__form-group-field">
                                    <Field
                                      name="type_report"
                                      component={renderSelectField}
                                      type="text"
                                      options={[
                                        { value: '2', label: 'Excel' },
                                        { value: '3', label: 'PDF' },
                                      ]}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                          <Button
                            color="primary"
                            type="submit"
                            style={{ display: displaySave }}
                          >
                            {t('FRM.SUBMIT')}{' '}
                          </Button>
                          <Button
                            color="primary"
                            style={{ display: displayLoad }}
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{' '}
                            {t('FRM.LOADING')}
                          </Button>
                        </ButtonToolbar>
                      </Container>
                    </form>
                    {this.state.download === true && (
                      <CSVLink
                        filename="Bukti Potong A1.csv"
                        target="_blank"
                        data={csvData}
                      >
                        <input
                          type="hidden"
                          ref={(input) => (this.inputElement = input)}
                        />
                      </CSVLink>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{ display: displayTable }}>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ paddingLeft: '20px' }}>
                <Row className="m5">
                  <div className="card__title" style={{ paddingLeft: '15px' }}>
                    <h5 className="bold-text">{t('LBL.YEARLY_REPORT')}</h5>
                  </div>
                  <ViewListTable
                    url={urlData}
                    heads={this.heads}
                    // primaryKey="pyp_id"
                    // widthTable={this.state.dataWidth}
                    buttonAction="noButton"
                    conds={{
                      pyp_id: condPypId,
                      year: condYear,
                      emp_id: condEmpId,
                      tax_value: condTaxValue,
                      cmp_id: condCmp,
                      type: '1',
                    }}
                    onRef={(ref) => (this.child = ref)}
                    displayStyleHead="none"
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  console.log(values);
  if (values.formatType == '0' && !values.period_month) {
    errors.period_month = "Period field shouldn't be empty";
  }
  if (!values.type_report) {
    errors.type_report = 'Type report field shouldn’t be empty';
  }
  if (!values.period_year) {
    errors.period_year = 'Period year field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'bpa1report', // a unique identifier for this form
  validate,
})(translate('global')(ReportAttendanceForm));
