/* eslint-disable no-underscore-dangle */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars, no-unneeded-ternary */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar, Row, Col, Container } from 'reactstrap';
import { Field, reduxForm, FieldArray, touch } from 'redux-form';
// import { actions } from 'react-redux-form';
import MaskedInput from 'react-text-mask';
import { Link } from 'react-router-dom';
// import EyeIcon from 'mdi-react/EyeIcon';
import PropTypes from 'prop-types';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/fontawesome-free-solid';
import renderFileInputField from '../../../../shared/components/form/FileInput';
// import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import renderSelectField from '../../../../shared/components/form/Select';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import renderMaskInput from '../../../../shared/components/form/InputMask';
import renderPhoneInput from '../../../../shared/components/form/InputPhone';
import { translate } from 'react-i18next';

const renderField = ({ input, placeholder, type, mask }) => (
  <MaskedInput {...input} placeholder={placeholder} type={type} mask={mask} />
);

renderField.propTypes = {
  input: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  mask: PropTypes.func.isRequired,
};

renderField.defaultProps = {
  placeholder: '',
  type: 'text',
};

const user2 = `${process.env.PUBLIC_URL}/img/user_photo/user-2.png`;
class WizardFormOne extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    // initialize: PropTypes.func.isRequired,
    rtbreligion: PropTypes.array.isRequired,
    rtbidentitytype: PropTypes.array.isRequired,
    rtbmaritalstatus: PropTypes.array.isRequired,
    rtbfamilyrelation: PropTypes.array.isRequired,
    rtbbloodtype: PropTypes.array.isRequired,
    rtbnationality: PropTypes.array.isRequired,
    tempform: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    men_id: PropTypes.string.isRequired,
    fga_id: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      // dynForm: [],
      // eslint-disable-next-line react/no-unused-state
      tempForm: [],
      // citizen: '',
      // file: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.tempform.length === undefined) {
      props.initialize(props.tempform);
      return {
        tempForm: props.tempform,
      };
    }

    return null;
  }

  // eslint-disable-next-line class-methods-use-this

  renderIdentity = ({ fields, meta: { error }, rtbidentitytype, t }) => (
    <div>
      {fields.map((identity, index) => (
        <Row className="h_line">
          <div className="identity_form_group">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.IDENTITY')}{' '}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name={`${identity}.identity`}
                    component={renderSelectField}
                    type="textemployee"
                    props={{ disableTouched: true }}
                    options={rtbidentitytype}
                    placeholder="Identity"
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.IDENTITY')} {t('LBL.NUMBER')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name={`${identity}.identity_no`}
                    component={renderTextInput}
                    type="number"
                    placeholder="Your Identity No"
                    props={{ disableTouched: true }}
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <Row>
                <Col md={8} sm={8} xs={8}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.EXPIRED_DATE')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name={`${identity}.expired_date`}
                        component={renderDatePickerField}
                        props={{ disableTouched: true }}
                        disabled={
                          this.props.tempform.dyn_identity[index].disabled
                        }
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                </Col>
                {index === 0 ? (
                  <Col md={4} sm={4}>
                    <Button
                      className="add_btn_identity"
                      onClick={() => fields.push({})}
                      color="primary"
                      size="sm"
                      disabled={fields.length > 2 ? true : false}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </Col>
                ) : (
                  <Col md={4} sm={4}>
                    <Button
                      className="add_btn_identity"
                      onClick={() => fields.remove(index)}
                      color="danger"
                      size="sm"
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </div>
        </Row>
      ))}
      {error && <li className="error">{error}</li>}
    </div>
  );

  // onAddForm = (e) => {
  //   e.preventDefault();
  //   this.setState(prevState => ({ dynForm: [...prevState.dynForm, ''] }));
  // };

  // eslint-disable-next-line class-methods-use-this
  // removeForm(i) {
  //   const dynForm = [...this.state.dynForm];
  //   dynForm.splice(i, 1);
  //   this.setState({ dynForm });
  // }

  render() {
    const { handleSubmit, dispatch } = this.props;
    const {
      rtbreligion,
      rtbidentitytype,
      rtbmaritalstatus,
      rtbfamilyrelation,
      rtbbloodtype,
      previousPage,
      rtbnationality,
      t,
    } = this.props;
    const { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} alt="tes" />;
    }
    return (
      <form className="form wizard__form" onSubmit={handleSubmit}>
        <Container>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.EMPLOYEE_CODE')}
                </span>
                <div className="form__form-group-field">
                  <input type="hidden" name="emp_id" />
                  <Field
                    name="emp_code"
                    component={renderTextInput}
                    type="textemployee"
                    placeholder={t('LBL.EMPLOYEE_CODE')}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.FIRST_NAME')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="first_name"
                    component={renderTextInput}
                    type="textemployee"
                    placeholder="First Name"
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.LAST_NAME')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="last_name"
                    component={renderTextInput}
                    type="textemployee"
                    placeholder="Last Name"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('LBL.EMAIL')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="email"
                    component={renderTextInput}
                    type="textemployee"
                    placeholder="username@domain"
                    maxLength="64"
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.MOBILE')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="mobile"
                    component={renderPhoneInput}
                    placeholder="+__ ____ ____ ___"
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('LBL.PHONE')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="phone"
                    component={renderMaskInput}
                    type="textemployee"
                    mask={[
                      '+',
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    placeholder="+___ ____ ____"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.ADDRESS')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="emp_address"
                    component={renderTextInput}
                    type="textareaemp"
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.POSTAL_CODE')}
                </span>
                <div className="form__form-group-field number-form">
                  <Field
                    name="postal_code"
                    component={renderTextInput}
                    type="number"
                    placeholder={t('LBL.POSTAL_CODE')}
                    maxLength="6"
                    disableTouched
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.DATE_OF_BIRTH')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="dob"
                    component={renderDatePickerField}
                    props={{ disableTouched: true }}
                  />
                  <div className="form__form-group-icon">
                    <CalendarBlankIcon />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.PLACE_OF_BIRTH')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="pob"
                    component={renderTextInput}
                    type="textemployee"
                    placeholder="Your Place of Birth"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('LBL.SEX')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="sex"
                    component={renderSelectField}
                    props={{ disableTouched: true }}
                    type="textemployee"
                    options={[
                      { value: '1', label: 'Male' },
                      { value: '2', label: 'Female' },
                    ]}
                    placeholder="Select Gender"
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.BLOOD_TYPE')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="rtb_id"
                    component={renderSelectField}
                    props={{ disableTouched: true }}
                    type="textemployee"
                    options={rtbbloodtype}
                    placeholder={t('LBL.SELECT_BLOOD_TYPE')}
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.LAST_EDUCATION')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="last_education"
                    component={renderSelectField}
                    type="textemployee"
                    props={{ disableTouched: true }}
                    options={[
                      { value: 1, label: 'SD' },
                      { value: 2, label: 'SMP' },
                      { value: 3, label: 'SMA / SMK' },
                      { value: 4, label: 'D1' },
                      { value: 5, label: 'D2' },
                      { value: 6, label: 'D3' },
                      { value: 7, label: 'D4' },
                      { value: 8, label: 'S1' },
                      { value: 9, label: 'S2' },
                      { value: 10, label: 'S3' },
                    ]}
                    placeholder="Last Education"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.MARITAL_STATUS')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="rtm_id"
                    component={renderSelectField}
                    type="textemployee"
                    props={{ disableTouched: true }}
                    options={rtbmaritalstatus}
                    placeholder="Select Marital Status"
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.RELIGION')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="rtg_id"
                    component={renderSelectField}
                    type="textemployee"
                    options={rtbreligion}
                    props={{ disableTouched: true }}
                    placeholder="Religion"
                  />
                </div>
              </div>
            </Col>
            <Col md={2} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.CITIZENSHIP')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="citizen"
                    component={renderSelectField}
                    type="textemployee"
                    props={{ disableTouched: true }}
                    options={[
                      { value: 1, label: 'WNI' },
                      { value: 2, label: 'WNA' },
                    ]}
                    placeholder="Citizenship"
                    // onChange={(val) => {
                    //   // console.log(this.state.tempForm);
                    //   // if (val.value === 2) {
                    //     // this.setState({ citizen: '2' });
                    //   // }
                    // }}
                  />
                </div>
              </div>
            </Col>
            {this.props.tempform.displaycountry === '2' && (
              <Col md={2} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t('LBL.COUNTRY')}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="ctr_id"
                      component={renderSelectField}
                      props={{ disableTouched: true }}
                      type="textemployee"
                      options={rtbnationality}
                      placeholder="Nationality"
                    />
                  </div>
                </div>
              </Col>
            )}
          </Row>

          {/* identity Form */}
          <Row className="h_line">
            <Col md={12}>
              <div className="adj-panel-profile">
                <div className="title-adj">{t('LBL.IDENTITY')}</div>
                <div className="body-adj">
                  <div className="identity_form_group">
                    <Row>
                      <Col md={4} sm={12}>
                        <input type="hidden" name="identity_ktp" value="1" />
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.IDENTITY')}{' '}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              maxLength={16}
                              name="idty_ktp"
                              component={renderTextInput}
                              type="textemployee"
                              placeholder="Identity"
                              disabled="true"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={3} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.IDENTITY_NUMBER')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="identity_no_ktp"
                              component={renderTextInput}
                              props={{ disableTouched: true }}
                              type="textemployee"
                              maxLength={16}
                              placeholder="Your Identity No"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={2} sm={12}>
                        <Row>
                          <Col md={8} sm={8} xs={8}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.EXPIRED_DATE')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="expired_date"
                                  component={renderDatePickerField}
                                  disabled="true"
                                />
                                <div className="form__form-group-icon">
                                  <CalendarBlankIcon />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={3} sm={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.UPLOAD_PHOTO')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="file_ktp"
                              allowedType={['png', 'jpg', 'jpeg', 'pdf']}
                              maxSize={1000}
                              component={renderFileInputField}
                              type="textemployee"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="identity_form_group">
                    <Row>
                      <Col md={4} sm={12}>
                        <input type="hidden" name="identity_sim" value="2" />
                        <div
                          className="form__form-group"
                          style={{ paddingTop: '25px' }}
                        >
                          <div className="form__form-group-field">
                            <Field
                              name="idty_sim"
                              component={renderTextInput}
                              type="textemployee"
                              placeholder="Identity"
                              disabled="true"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={3} sm={12}>
                        <div
                          className="form__form-group"
                          style={{ paddingTop: '25px' }}
                        >
                          <div className="form__form-group-field">
                            <Field
                              name="identity_no_sim"
                              component={renderTextInput}
                              type="textemployee"
                              placeholder="Your Identity No"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={2} sm={12}>
                        <Row>
                          <Col md={8} sm={8} xs={8}>
                            <div
                              className="form__form-group"
                              style={{ paddingTop: '25px' }}
                            >
                              <div className="form__form-group-field">
                                <Field
                                  name="expired_date_sim"
                                  component={renderDatePickerField}
                                  disableTouched
                                />
                                <div className="form__form-group-icon">
                                  <CalendarBlankIcon />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={3} sm={12}>
                        <div
                          className="form__form-group"
                          style={{ paddingTop: '25px' }}
                        >
                          {/* <span className="form__form-group-label">
                            {t('LBL.UPLOAD_PHOTO')}
                          </span> */}
                          <div className="form__form-group-field">
                            <Field
                              name="file_sim"
                              allowedType={['png', 'jpg', 'jpeg', 'pdf']}
                              maxSize={1000}
                              component={renderFileInputField}
                              type="textemployee"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="identity_form_group">
                    <Row>
                      <Col md={4} sm={12}>
                        <input type="hidden" name="identity_paspor" value="3" />
                        <div
                          className="form__form-group"
                          style={{ paddingTop: '25px' }}
                        >
                          <div className="form__form-group-field">
                            <Field
                              name="idty_paspor"
                              component={renderTextInput}
                              type="textemployee"
                              placeholder="Identity"
                              disabled="true"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={3} sm={12}>
                        <div
                          className="form__form-group"
                          style={{ paddingTop: '25px' }}
                        >
                          <div className="form__form-group-field">
                            <Field
                              name="identity_no_paspor"
                              component={renderTextInput}
                              type="textemployee"
                              placeholder="Your Identity No"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={2} sm={12}>
                        <Row>
                          <Col md={8} sm={8} xs={8}>
                            <div
                              className="form__form-group"
                              style={{ paddingTop: '25px' }}
                            >
                              <div className="form__form-group-field">
                                <Field
                                  name="expired_date_paspor"
                                  component={renderDatePickerField}
                                  disableTouched
                                />
                                <div className="form__form-group-icon">
                                  <CalendarBlankIcon />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={3} sm={12}>
                        <div
                          className="form__form-group"
                          style={{ paddingTop: '25px' }}
                        >
                          {/* <span className="form__form-group-label">
                            {t('LBL.UPLOAD_PHOTO')}
                          </span> */}
                          <div className="form__form-group-field">
                            <Field
                              name="file_passport"
                              allowedType={['png', 'jpg', 'jpeg', 'pdf']}
                              maxSize={1000}
                              component={renderFileInputField}
                              type="textemployee"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h_line">
            <Col md={12}>
              <div className="adj-panel-profile">
                <div className="title-adj">{t('LBL.EMERGENCY_CONTACT')}</div>
                <div className="body-adj">
                  <Row>
                    <Col md={4} sm={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.NAME')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="relation_name"
                            component={renderTextInput}
                            props={{ disableTouched: true }}
                            type="textemployee"
                            placeholder="Relation Name"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={4} sm={12}>
                      <div className="form__form-group form__form-group--address">
                        <span className="form__form-group-label">
                          {t('LBL.RELATION')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="rtf_id"
                            component={renderSelectField}
                            type="textemployee"
                            options={rtbfamilyrelation}
                            placeholder="Select Relation"
                          />
                          {/* <Field
                            name="relation_name"
                            component={renderTextInput}
                            type="textemployee"
                            placeholder="Name"
                          /> */}
                        </div>
                      </div>
                    </Col>
                    <Col md={4} sm={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.PHONE')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="emergency_contact"
                            component={renderPhoneInput}
                            placeholder="+__ ____ ____ ___"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.ATTENDANCE_TYPE_CODE')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="finger_id"
                    component={renderTextInput}
                    type="textemployee"
                    placeholder="Fingerprint ID or Attendance Type Code"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h_line">
            <Col md={2} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.UPLOAD_PHOTO')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="path_photo"
                    allowedType={['png', 'jpg', 'gif']}
                    maxSize={1000}
                    component={renderFileInputField}
                    type="textemployee"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <ButtonToolbar className="form__button-toolbar wizard__toolbar">
              <Link
                className="btn  btn-outline-primary"
                to={{
                  pathname: '/administration/employee',
                  state: {
                    men_id: this.props.men_id,
                    fga_id: this.props.fga_id,
                  },
                }}
              >
                {' '}
                {t('FRM.BACK')}
              </Link>
              <Button color="primary" type="submit" className="next">
                {t('FRM.NEXT')}
              </Button>
            </ButtonToolbar>
          </Row>
        </Container>
      </form>
    );
  }
}

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  keepDirtyOnReinitialize: true,
})(translate('global')(WizardFormOne));
