/* eslint-disable array-callback-return */
/*
 * Page Grade
 * Notes: Master Data Promotion & Mutation
 * @author Gerdi
 * @date 02/08/2019
 * Modification (please note below)
 */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint no-lonely-if: "error" */
/* eslint-disable-next-line no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable object-shorthand */
/* eslint-disable react/forbid-prop-types */

import React, { PureComponent } from 'react';
import {
  Container,
  Col,
  Modal,
  Button,
  Row,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import ls from 'local-storage';
import axios from 'axios';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import Swal from 'sweetalert2';
import '../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/fontawesome-free-solid';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import showNotifications from '../../../Preferences/notificationMessages';
import moment from 'moment';
import utils from '../../../../utils';

class PromotionMutation extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    parmid: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.NAME') },
      { '2': t('LBL.TYPE') },
      { '3': `${t('LBL.OLD')} ${t('LBL.JOB_TITLE')}` },
      { '4': `${t('LBL.NEW')} ${t('LBL.JOB_TITLE')}` },
      { '5': `${t('LBL.START')} ${t('LBL.DATE')}` },
      { '6': t('LBL.STATUS') },
      { '7': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      apiws: '',
      token: '',
      dataWidth: ['5%', '15%', '10%', '15%', '15%', '10%', '10%', '15%'],
      urlData: [],
      statusEdit: false,
      arrShift: [],
      arrJobName: [],
      arrJobTitle: [],
      arrJobBand: [],
      arrJobGrade: [],
      arrOffice: [],
      arrUnitOrg: [],
      arrEmpStatus: [],
      arrTypeStatus: [],
      redirect: false,
      modal: false,
      joinDate: '',
      company: '',
      off_id_old: '',
      office_old: '',
      org_id_old: '',
      unit_organization_old: '',
      jbn_id_old: '',
      job_name_old: '',
      pos_id_old: '',
      position_old: '',
      jbd_id_old: '',
      job_band_old: '',
      jgd_id_old: '',
      job_grade_old: '',
      ems_id_old: '',
      employeement_status_old: '',
      org_id: '',
      jbn_id: '',
      pos_id: '',
      join_date: '',
      displayLoad: false,
      disabledForm: true,
      type_name: '',
      employeement_status: '',
      lastday: false,
      addcond: '',
      editcond: '',
      deletecond: '',
      statusEmp: 0,
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteFunc = this.deleteFunc.bind(this);
    this.editFunc = this.editFunc.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getUserCurrent = this.getUserCurrent.bind(this);
    this.getJobName = this.getJobName.bind(this);
    this.getJobTitle = this.getJobTitle.bind(this);
    this.getJobGrade = this.getJobGrade.bind(this);
    this.linkBack = this.linkBack.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getUserCurrent();
      this.getOffice();
      this.getUnitOrg();
      this.getJobBand();
      this.getEmpStatus();
      this.getTypeStatus();
      this.getButton();
    });
    this.setState({
      urlData: `${ls.get('ws_ip')}/emppromotionmutation/getAllitem`,
    });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.NAME') },
      { '2': t('LBL.TYPE') },
      { '3': t('LBL.OLD_JOB_TITLE') },
      { '4': t('LBL.NEW_JOB_TITLE') },
      { '5': t('LBL.START_DATE') },
      { '6': t('LBL.STATUS') },
      { '7': t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  getButton() {
    const men = this.props.parmid.state.men_id;
    const fga = this.props.parmid.state.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });

          // console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getUserCurrent() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const id = this.props.parmid.state.usrid;
    const url = `${ws_ip}/employee/getAllItemById/${id}`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          this.setState({
            joinDate: moment(dataResponse.user[0].join_date).format(
              'DD/MM/YYYY',
            ),
            join_date: dataResponse.user[0].join_date,
            cmpId: dataResponse.company[0].cmp_id,
            company: dataResponse.company[0].company,
            off_id_old: dataResponse.user[0].off_id,
            office_old: dataResponse.user[0].office,
            org_id_old: dataResponse.user[0].org_id,
            unit_organization_old: dataResponse.user[0].unit_organization,
            jbn_id_old: dataResponse.user[0].jbn_id,
            job_name_old: dataResponse.user[0].job_name,
            pos_id_old: dataResponse.user[0].pos_id,
            position_old: dataResponse.user[0].position_name,
            jbd_id_old: dataResponse.user[0].jbd_id,
            job_band_old: dataResponse.user[0].job_band,
            jgd_id_old: dataResponse.user[0].jgd_id,
            job_grade_old: dataResponse.user[0].job_grade,
            ems_id_old: dataResponse.user[0].ems_id,
            employeement_status_old: dataResponse.user[0].employeement_status,
          });
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getTypeStatus() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/rtbemployeementtype/getAll/`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const off = response.data.data;
          const arr = [];
          off.map((obj) =>
            arr.push({ value: obj.ret_id, label: obj.type_name }),
          );
          this.setState({
            arrTypeStatus: arr,
          });
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getOffice() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgoffice/getAll`;
    axios
      .post(url, '', config)
      .then((response) => {
        // alert(JSON.stringify(response));
        if (response.data.status === 'ok') {
          const off = response.data.data;
          const arr = [];
          off.map((obj) => arr.push({ value: obj.off_id, label: obj.office }));
          this.setState({
            arrOffice: arr,
          });
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getUnitOrg() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgorganization/getAll/`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const org = response.data.data;
          const arr = [];
          org.map((obj) =>
            arr.push({ value: obj.org_id, label: obj.unit_organization }),
          );
          this.setState({
            arrUnitOrg: arr,
          });
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getJobName(id) {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgjobname/getAllHierarchy/${id.value}`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const jbn = response.data.data;
          const arr = [];
          jbn.map((obj) =>
            arr.push({ value: obj.jbn_id, label: obj.job_name }),
          );
          this.setState({
            arrJobName: arr,
            org_id: id.value,
          });
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getJobTitle(id) {
    const { org_id } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgposition/getAllHierarchy/${org_id}/${id.value}`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const pos = response.data.data;
          const arr = [];
          pos.map((obj) =>
            arr.push({ value: obj.pos_id, label: obj.position_nm }),
          );
          this.setState({
            arrJobTitle: arr,
          });
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getJobBand() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgjobband/getAll/`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const band = response.data.data;
          const arr = [];
          band.map((obj) =>
            arr.push({ value: obj.jbd_id, label: obj.job_band }),
          );
          this.setState({
            arrJobBand: arr,
          });
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getJobGrade(id) {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgjobbandgrade/getAllHierarchy/${id.value}`;
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const grade = response.data.data;
          const arr = [];
          grade.map((obj) =>
            arr.push({ value: obj.jgd_id, label: obj.job_grade }),
          );
          this.setState({
            arrJobGrade: arr,
          });
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getEmpStatus(terminate) {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    let url;
    if (terminate) {
      url = `${ws_ip}/rtbemployeementstatus/getAll/1`;
    } else {
      url = `${ws_ip}/rtbemployeementstatus/getAll/0`;
    }
    axios
      .post(url, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.ems_id, label: obj.ems_employeement_status }),
          );
          this.setState({ arrEmpStatus: Arr });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  toggle(item) {
    if (item === 'add') {
      this.setState({
        lastday: false,
        disabledForm: true,
        statusEmp: 0,
      });
      this.getUserCurrent();
    }
    this.props.destroy();
    this.setState({
      displayLoad: false,
      statusEdit: false,
      modal: !this.state.modal,
    });
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  mapFormData(b, a) {
    let val_b;
    if (b !== '') {
      if (typeof b === 'object') {
        if (b !== null) {
          if (a.includes('periode', 'date')) {
            val_b = moment(b).format('Y-M-D');
          } else {
            val_b = b.value;
          }
        }
      } else {
        val_b = b;
      }
    } else {
      val_b = '';
    }
    return val_b;
  }

  handleSubmit(values) {
    // console.log(typeof values.periode_end);
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws, joinDate } = this.state;
    const id = values.epm_id;
    let error = false;
    if (!values.ret_id || !typeof values.ret_id === 'object') {
      error = true;
    }
    if (!values.org_id || !typeof values.org_id === 'object') {
      error = true;
    }
    if (!values.off_id || !typeof values.off_id === 'object') {
      error = true;
    }
    if (!values.jbn_id || !typeof values.jbn_id === 'object') {
      error = true;
    }
    if (!values.jbd_id || !typeof values.jbd_id === 'object') {
      error = true;
    }
    if (!values.jgd_id || !typeof values.jgd_id === 'object') {
      error = true;
    }
    if (!values.ems_id || !typeof values.ems_id === 'object') {
      error = true;
    }
    if (!values.pos_id || !typeof values.pos_id === 'object') {
      error = true;
    }
    let url;
    let dataBody;

    // const join = moment(this.state.joinDate).format('Y-m-d');
    // const join = joinDate.split('/');
    // const joindates = join[2];
    // alert(joindates);
    const join = `${joinDate.substring(6, 10)}-${joinDate.substring(
      3,
      5,
    )}-${joinDate.substring(0, 2)}`;
    const end =
      typeof values.periode_end === 'undefined' ||
      values.periode_end === '' ||
      values.periode_end === null ||
      values.periode_end === 'null'
        ? ''
        : moment(values.periode_end).format('YYYY-MM-DD');
    const data = {
      emp_id: this.props.parmid.state.usrid,
      emp_code: this.props.parmid.state.code,
      off_id_old: this.state.off_id_old,
      org_id_old: this.state.org_id_old,
      jbn_id_old: this.state.jbn_id_old,
      jbd_id_old: this.state.jbd_id_old,
      jgd_id_old: this.state.jgd_id_old,
      pos_id_old: this.state.pos_id_old,
      ems_id_old: this.state.ems_id_old,
      office_old: this.state.office_old,
      unit_organization_old: this.state.unit_organization_old,
      job_name_old: this.state.job_name_old,
      job_band_old: this.state.job_band_old,
      job_grade_old: this.state.job_grade_old,
      position_old: this.state.position_old,
      employeement_status_old: this.state.employeement_status_old,
      join_date: join,
      periode_end: end,
    };
    // console.log(data);
    const emsId =
      typeof values.ems_id === 'object' ? values.ems_id.value : values.ems_id;
    if (id === undefined || id === '') {
      url = `${apiws}/emppromotionmutation/saveItem`;
      dataBody = {
        ...data,
        ...values,
        cuser_id: userid,
        ems_id: emsId,
      };
    } else {
      url = `${apiws}/emppromotionmutation/updateItem/${id}`;
      dataBody = {
        ...data,
        ...values,
        muser_id: userid,
        ems_id: emsId,
      };
    }
    const formBody = Object.keys(dataBody)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            this.mapFormData(dataBody[key], key),
          )}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // const isDateValid = (typeof values.periode_start === 'object' && values.periode_start.isAfter(values.periode_end));
    if (!error) {
      this.setState({ displayLoad: true });
      axios
        .post(url, formBody, config)
        .then((response) => {
          // alert(JSON.stringify(response));
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            this.setState({ modal: false });
            this.child.reload('addedit');
            this.props.destroy();
          } else if (response.data.status == 'exist_submission') {
            showNotifications(
              'Save Failed',
              'Pending workflow request exists!',
              'warning',
            );
            this.setState({ displaySpinner: 'none', displaySave: '' });
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
          this.setState({ displayLoad: false });
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  editFunc(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/emppromotionmutation/getAllItemById/${id}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          this.setState({ statusEdit: true });
          const getResponseData = response.data.data;
          console.log(getResponseData);
          const offOld =
            getResponseData[0].office_old === null
              ? ''
              : getResponseData[0].office_old;
          const orgOld =
            getResponseData[0].unit_organization_old === null
              ? ''
              : getResponseData[0].unit_organization_old;
          const jbnOld =
            getResponseData[0].job_name_old === null
              ? ''
              : getResponseData[0].job_name_old;
          const jgdOld =
            getResponseData[0].job_grade_old === null
              ? ''
              : getResponseData[0].job_grade_old;
          const bandOld =
            getResponseData[0].job_band_old === null
              ? ''
              : getResponseData[0].job_band_old;
          const posOld =
            getResponseData[0].position_old === null
              ? ''
              : getResponseData[0].position_old;
          const emsOld =
            getResponseData[0].employeement_status_old === null
              ? ''
              : getResponseData[0].employeement_status_old;
          const offIdOld =
            getResponseData[0].off_id_old === null
              ? ''
              : getResponseData[0].off_id_old;
          const orgIdOld =
            getResponseData[0].org_id_old === null
              ? ''
              : getResponseData[0].org_id_old;
          const jbnIdOld =
            getResponseData[0].jbn_id_old === null
              ? ''
              : getResponseData[0].jbn_id_old;
          const jbdIdOld =
            getResponseData[0].jbd_id_old === null
              ? ''
              : getResponseData[0].jbd_id_old;
          const jgdIdOld =
            getResponseData[0].jgd_id_old === null
              ? ''
              : getResponseData[0].jgd_id_old;
          const posIdOld =
            getResponseData[0].pos_id_old === null
              ? ''
              : getResponseData[0].pos_id_old;
          const emsIdOld =
            getResponseData[0].ems_id_old === null
              ? ''
              : getResponseData[0].ems_id_old;
          const datamaping1 = {
            office_old: offOld,
            org_id: getResponseData[0].org_id,
            unit_organization_old: orgOld,
            job_name_old: jbnOld,
            job_grade_old: jgdOld,
            job_band_old: bandOld,
            position_old: posOld,
            employeement_status_old: emsOld,
            off_id_old: offIdOld,
            org_id_old: orgIdOld,
            jbn_id_old: jbnIdOld,
            jbd_id_old: jbdIdOld,
            jgd_id_old: jgdIdOld,
            pos_id_old: posIdOld,
            ems_id_old: emsIdOld,
            join_date: getResponseData[0].join_date,
            joinDate: moment(getResponseData[0].join_date).format('DD/MM/YYYY'),
          };
          const periodeEnd =
            getResponseData[0].periode_end === null
              ? ''
              : moment(getResponseData[0].periode_end);
          const description =
            getResponseData[0].epm_description === null
              ? ''
              : getResponseData[0].epm_description;
          const datamaping2 = {
            epm_id: getResponseData[0].epm_id,
            ret_id: getResponseData[0].ret_id,
            off_id: getResponseData[0].off_id,
            org_id: getResponseData[0].org_id,
            jbn_id: getResponseData[0].jbn_id,
            jgd_id: getResponseData[0].jgd_id,
            jbd_id: getResponseData[0].jbd_id,
            pos_id: getResponseData[0].pos_id,
            ems_id: getResponseData[0].ems_id,
            periode_start: moment(getResponseData[0].periode_start),
            periode_end: periodeEnd,
            epm_description: description,
          };
          if (getResponseData[0].ret_id === 5) {
            this.setState({ lastday: true });
            this.getEmpStatus(true);
          } else {
            this.setState({ lastday: false });
            this.getEmpStatus(false);
          }
          this.setState({ statusEmp: getResponseData[0].ems_id });
          this.setState(datamaping1);
          this.setState({ disabledForm: false, modal: true }, () => {
            this.props.destroy();
            this.getJobName({ value: getResponseData[0].org_id });
            this.getJobTitle({ value: getResponseData[0].jbn_id });
            this.getJobGrade({ value: getResponseData[0].jbd_id });
            this.getTypeStatus({ value: getResponseData[0].ret_id });
            this.props.initialize(datamaping2);
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  deleteFunc(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/emppromotionmutation/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            // alert(JSON.stringify(response));
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 1000);
            } else if (response.data.status == 'exist_submission') {
              showNotifications(
                'Save Failed',
                'Pending workflow request exists!',
                'warning',
              );
              this.setState({ displaySpinner: 'none', displaySave: '' });
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  changeType(x) {
    const { employeement_status } = this.state;
    if (x.value === 5) {
      this.setState({ lastday: true });
      this.getEmpStatus(true);
    } else {
      this.setState({ lastday: false });
      this.getEmpStatus(false);
    }
    this.setState({
      type_name: x.value,
      disabledForm: false,
    });
  }

  // changeStatus(x) {
  //   const { type_name } = this.state;
  //   if (x.value === 9 && type_name === 5) {
  //     this.setState({ lastday: true });
  //   } else {
  //     this.setState({ lastday: false });
  //   }
  //   this.setState({ employeement_status: x.value });
  // }

  render() {
    const {
      statusEdit,
      urlData,
      arrOffice,
      arrUnitOrg,
      arrJobName,
      arrJobTitle,
      arrJobBand,
      arrJobGrade,
      arrEmpStatus,
      arrTypeStatus,
      redirect,
      joinDate,
      company,
      office_old,
      unit_organization_old,
      job_name_old,
      position_old,
      job_band_old,
      job_grade_old,
      employeement_status_old,
      lastday,
    } = this.state;
    console.log(this.state.statusEmp);
    const { handleSubmit, t, pristine } = this.props;
    const addBtn = (
      <div className="add-btn-comp">
        <Button
          color="primary"
          size="sm"
          className="btn_table_navbar"
          onClick={() => this.toggle('add')}
          style={{
            float: 'right',
            'margin-bottom': '20px',
            display: this.state.addcond === '1' ? '' : 'none',
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> {t('FRM.ADD')}
        </Button>
      </div>
    );
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn-sm btn_table_navbar"
        onClick={this.linkBack}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          'margin-right': '10px',
        }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    const button_load = (
      <Button color="primary">
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          style={{ marginRight: '6px' }}
        />
        Loading...
      </Button>
    );
    const button_action = (
      <Button
        color="primary"
        form="addpromotionmutation"
        type="submit"
        disabled={pristine}
      >
        {t('FRM.SAVE')}
      </Button>
    );
    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: '/administration/employee',
            state: {
              men_id: this.props.parmid.state.men_employee,
              fga_id: this.props.parmid.state.fga_id,
            },
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  backBtn={backBtn}
                  primaryKey="epm_id"
                  widthTable={this.state.dataWidth}
                  deleteFunc={this.deleteFunc}
                  updateFunc={this.editFunc}
                  onRef={(ref) => (this.child = ref)}
                  textHead={`${this.props.parmid.state.name} (${this.props.parmid.state.code}) `}
                  conds={this.props.parmid.state.usrid}
                  displayStyleHead=""
                  pageTitle={t('LBL.PROMOTION_MUTATION_LIST')}
                  pageSubTitle={t('LBL.VIEW_MANAGE_PMK')}
                  editCond={this.state.editcond}
                  addCond={this.state.addcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>
                {' '}
                {t('FRM.ADD')} {t('LBL.PROMOTION_MUTATION')}{' '}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal form-promotion-mutation"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="addpromotionmutation"
                  id="addpromotionmutation"
                >
                  <input type="hidden" name="emp_id" />
                  <Container>
                    <Row>
                      <Col sm="12" md="6" lg="6" xl="6">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('MEN.EMPLOYEE')} {t('LBL.CODE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="emp_code"
                              component="input"
                              type="text"
                              placeholder={`${this.props.parmid.state.code}`}
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="6" lg="6" xl="6" sm="12">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.JOIN_DATE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="joinDateInput"
                              component="input"
                              type="text"
                              placeholder={joinDate}
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md="6" lg="6" xl="6">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.NAME')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="emp_name"
                              component="input"
                              type="text"
                              placeholder={`${this.props.parmid.state.name}`}
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                      <Col sm="12" md="6" lg="6" xl="6">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.TYPE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="ret_id"
                              component={renderSelectField}
                              options={arrTypeStatus}
                              onChange={(x) => {
                                this.changeType(x);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <hr className="devider-line" />
                    <Row>
                      <Col
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        className="col-separator"
                      >
                        <div className="text-center">
                          <h5 className="bold-text mb-2">{t('FRM.FROM')}</h5>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.COMPANY')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="disableInput"
                              component="input"
                              type="text"
                              placeholder={company}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.OFFICE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="disableInput"
                              component="input"
                              type="text"
                              placeholder={office_old}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.ORGANIZATION')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="disableInput"
                              component="input"
                              type="text"
                              placeholder={unit_organization_old}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.JOB_NAME')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="disableInput"
                              component="input"
                              type="text"
                              placeholder={job_name_old}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.JOB_TITLE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="disableInput"
                              component="input"
                              type="text"
                              placeholder={position_old}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.JOB_BAND')} / {t('LBL.JOB_GRADE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="disableInput"
                              component="input"
                              type="text"
                              placeholder={job_band_old}
                              disabled
                            />
                            <Field
                              name="disableInput"
                              component="input"
                              type="text"
                              placeholder={job_grade_old}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.STATUS')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="disableInput"
                              component="input"
                              type="text"
                              placeholder={employeement_status_old}
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                      <Col sm="12" md="6" lg="6" xl="6">
                        <div className="text-center">
                          <h5 className="bold-text mb-2">{t('FRM.TO')}</h5>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.COMPANY')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="disableInput"
                              component="input"
                              type="text"
                              placeholder={company}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.OFFICE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="off_id"
                              component={renderSelectField}
                              options={arrOffice}
                              className="mb-0"
                              disabled={this.state.disabledForm}
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.ORGANIZATION')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="org_id"
                              component={renderSelectField}
                              options={arrUnitOrg}
                              className="mb-0"
                              onChange={this.getJobName}
                              disabled={this.state.disabledForm}
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.JOB_NAME')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="jbn_id"
                              component={renderSelectField}
                              options={arrJobName}
                              className="mb-0"
                              onChange={this.getJobTitle}
                              disabled={this.state.disabledForm}
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.JOB_TITLE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="pos_id"
                              component={renderSelectField}
                              options={arrJobTitle}
                              className="mb-0"
                              disabled={this.state.disabledForm}
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.JOB_BAND')} / {t('LBL.JOB_GRADE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="jbd_id"
                              component={renderSelectField}
                              options={arrJobBand}
                              className="mb-0"
                              onChange={this.getJobGrade}
                              disabled={this.state.disabledForm}
                            />
                            <Field
                              name="jgd_id"
                              component={renderSelectField}
                              options={arrJobGrade}
                              className="mb-0"
                              disabled={this.state.disabledForm}
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.STATUS')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="ems_id"
                              component={renderSelectField}
                              options={arrEmpStatus}
                              className="mb-0"
                              disabled={this.state.disabledForm}
                              onChange={(value) => {
                                this.setState({ statusEmp: value.value });
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <hr className="devider-line" />
                    <Row>
                      <Col sm="12" md="6" lg="6" xl="6">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {this.state.lastday
                              ? t('LBL.LAST_DAY')
                              : t('LBL.START_DATE')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="periode_start"
                              component={
                                this.state.disabledForm
                                  ? renderTextInput
                                  : renderDatePickerField
                              }
                              disabled={this.state.disabledForm}
                              placeholder="dd-mm-yyyy"
                            />
                          </div>
                        </div>
                      </Col>
                      {this.state.lastday ? (
                        ''
                      ) : this.state.statusEmp == 1 ? (
                        ''
                      ) : (
                        <Col sm="12" md="6" lg="6" xl="6">
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              {t('LBL.END_DATE')}
                            </span>
                            <div className="form__form-group-field">
                              <Field
                                name="periode_end"
                                component={
                                  this.state.disabledForm
                                    ? renderTextInput
                                    : renderDatePickerField
                                }
                                disabled={this.state.disabledForm}
                                placeholder="dd-mm-yyyy"
                              />
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col sm="12" md="12" lg="12" xl="12">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.DESCRIPTION')}
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="epm_description"
                              component={renderTextInput}
                              type="textarea"
                              disabled={this.state.disabledForm}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggle}>
                  {t('FRM.CANCEL')}
                </Button>
                {this.state.displayLoad ? button_load : button_action}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const validation = {
    ret_id: {
      fieldLabel: 'Type',
      required: true,
    },
    org_id: {
      fieldLabel: 'Organization',
      required: true,
    },
    off_id: {
      fieldLabel: 'Office',
      required: true,
    },
    jbn_id: {
      fieldLabel: 'Job name',
      required: true,
    },
    jgd_id: {
      fieldLabel: 'Job grade',
      required: true,
    },
    jbd_id: {
      fieldLabel: 'Job band',
      required: true,
    },
    pos_id: {
      fieldLabel: 'Job title',
      required: true,
    },
    ems_id: {
      fieldLabel: 'Employeement status',
      required: true,
    },
    periode_start: {
      fieldLabel: 'Start date',
      required: true,
    },
    // periode_end: {
    //   fieldLabel: 'End date',
    //   required: false,
    //   lockDateAfter: 'periode_start',
    //   fieldLabelBefore: 'Start date',
    // },
  };

  const errors = utils.validate(validation, values);
  if (
    values.periode_start !== null &&
    values.periode_end !== null &&
    typeof values.periode_end !== 'undefined'
  ) {
    if (
      typeof values.periode_start === 'object' &&
      values.periode_start.isAfter(values.periode_end)
    ) {
      errors.periode_end = 'End date field shouldn’t be before start date';
    }
  }

  // console.log(errors);
  return errors;
};

export default reduxForm({
  form: 'pmkforms',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(PromotionMutation));
