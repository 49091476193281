/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable class-methods-use-this */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import renderFileInputField from '../../../../shared/components/form/FileInput';
import renderSelectField from '../../../../shared/components/form/Select';
// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import { CSVReader } from 'react-papaparse';
import ls from 'local-storage';
import axios from 'axios';
import showNotifications from '../../../../containers/Preferences/notificationMessages';
import moment from 'moment';
// import csv from 'csv-validator';
import ReactExport from 'react-export-excel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/fontawesome-free-solid';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
// import csv from 'csv-validator';

// eslint-disable-next-line react/prefer-stateless-function
const buttonRef = React.createRef();
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class HorizontalForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      message: [],
      token: '',
      apiws: '',
      arrdatacsv: [],
      file: '',
      emp_code: '',
      head: [],
      displayLoad: 'none',
      displayButton: '',
      headerexcel: [],
      templatevalue: [],
      headercountry: [],
      headerjobband: [],
      headerjobname: [],
      headeroffice: [],
      headerorganization: [],
      headerposition: [],
      headerbank: [],
      countryvalue: [],
      jobbandvalue: [],
      jobnamevalue: [],
      officevalue: [],
      organizationvalue: [],
      positionvalue: [],
      banknamevalue: [],
      maritalname: [],
      eduname: [],
      bpjstkname: [],
      headergrade: [],
      gradevalue: [],
      headertaxstat: [],
      taxstatusval: [],
      headermethod: [],
      methodvalue: [],
      finger_id: [],
      headerattsetting: [],
      attsettingvalue: [],
      headerems: [],
      emsvalue: [],
      ats_id: [],
      headertcs: [],
      tcsvalue: [],
      headerogf: [],
      ogfvalue: [],
      familyrelation: [],
      religion: [],
      sexname: [],
      bpjsksname: [],
      genusername: [],
      idenname: [],
      citizenname: [],
      bloodname: [],
      headerfamily: [],
      headerreligion: [],
      headersex: [],
      headermarital: [],
      headerblood: [],
      headereducationlevel: [],
      headercitizen: [],
      headerbpjstk: [],
      headerbpjsks: [],
      headeriden: [],
      headergenuser: [],
      ogf_id: [],
      isEdit: 'false',
      country: [],
      taxStatus: [],
      taxMethod: [],
      bank: [],
      employeeStatus: [],
      arrFamilyRelation: [],
      arrReligion: [],
      payTaxClass: [],
      lastEducation: [],
      maritalStatus: [],
      identity: [],
      generateUser: [1, 2],
      BPJSTKRegis: [1, 2],
      BPJSKSRegis: [1, 2],
      loadCount: 0,
    };
    this.handleOnError = this.handleOnError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.downloadTemplate = this.downloadTemplate(this);
    this.loadExcelSheet = this.loadExcelSheet.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.getEmployee();
    this.loadExcelSheet();
  };

  getEmployee() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/employee/getAll/`;
    axios
      .post(url, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const finger = [];
        res.map((data) => {
          arr.push(data.emp_code);
          finger.push(data.finger_id);
        });
        this.setState({ emp_code: arr });
        this.setState({ finger_id: finger });
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  loadExcelSheet() {
    let loadCount = 0;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    axios
      .post(`${ws_ip}/appcountry/getAll/`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const arrCtr = [];
        const head = ['ctr_id', 'country'];
        res.map((data) => {
          arr.push({ ctr_id: data.ctr_id, country: data.country });
          arrCtr.push(data.ctr_id);
        });
        setTimeout(() => {
          this.setState({
            headercountry: head,
            countryvalue: arr,
            country: arrCtr,
            loadCount: this.state.loadCount + 1,
          });
        }, 200);
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/paytaxmethod/getAll/`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const arrTaxMeth = [];
        const head = ['jgd_id', 'tax method'];
        res.map((data) => {
          arr.push({ jgd_id: data.ptm_id, 'tax method': data.tax_method });
          arrTaxMeth.push(data.ptm_id);
        });
        setTimeout(() => {
          this.setState({
            headermethod: head,
            methodvalue: arr,
            taxMethod: arrTaxMeth,
            loadCount: this.state.loadCount + 1,
          });
        }, 200);
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/rtbtaxstatus/getAll/`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const arrTaxStatus = [];
        const head = ['rtx_id', 'tax_status'];
        res.map((data) => {
          arr.push({ rtx_id: data.rtx_id, tax_status: data.tax_status });
          arrTaxStatus.push(data.rtx_id);
        });
        setTimeout(() => {
          this.setState({
            headertaxstat: head,
            taxstatusval: arr,
            taxStatus: arrTaxStatus,
            loadCount: this.state.loadCount + 1,
          });
        }, 200);
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/rtbbank/getAll/`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const arrBank = [];
        const head = ['rtk_id', 'bank'];
        res.map((data) => {
          arr.push({ rtk_id: data.rtk_id, bank: data.bank });
          arrBank.push(data.rtk_id);
        });
        setTimeout(() => {
          this.setState({
            headerbank: head,
            banknamevalue: arr,
            bank: arrBank,
            loadCount: this.state.loadCount + 1,
          });
        }, 200);
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/orgoffice/getAll`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const head = ['off_id', 'office'];
        const arroff = [];
        res.map((data) => {
          arr.push({ off_id: data.off_id, office: data.office });
          arroff.push(data.off_id);
        });
        setTimeout(() => {
          this.setState({
            headeroffice: head,
            officevalue: arr,
            off_id: arroff,
            loadCount: this.state.loadCount + 1,
          });
        }, 200);
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/paytaxclass/getAllItem`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const arrPayTax = [];
        const head = ['tcs_id', 'pay tax class'];
        res.map((data) => {
          arr.push({ tcs_id: data.tcs_id, 'pay tax class': data.class_name });
          arrPayTax.push(data.tcs_id);
        });
        setTimeout(() => {
          this.setState({
            headertcs: head,
            tcsvalue: arr,
            payTaxClass: arrPayTax,
            loadCount: this.state.loadCount + 1,
          });
        }, 200);
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/geofence/getAllItem`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const arrOfgId = [];
        const head = ['ogf_id', 'Geofence_Area'];
        res.map((data) => {
          arr.push({ ogf_id: data.ogf_id, Geofence_Area: data.title });
          arrOfgId.push(data.ogf_id);
        });
        setTimeout(() => {
          this.setState({
            headerogf: head,
            ogfvalue: arr,
            ogf_id: arrOfgId,
            loadCount: this.state.loadCount + 1,
          });
        }, 200);
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/orgorganization/getAll/`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const arrorg = [];
        const head = ['org_id', 'unit organization'];
        res.map((data) => {
          arr.push({
            org_id: data.org_id,
            'unit organization': data.unit_organization,
          });
          arrorg.push(data.org_id);
        });
        setTimeout(() => {
          this.setState({
            headerorganization: head,
            organizationvalue: arr,
            org_id: arrorg,
            loadCount: this.state.loadCount + 1,
          });
        }, 200);
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/orgjobname/getAll/`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const arrjbn = [];
        const head = ['jbn_id', 'job name', 'job level'];
        res.map((data) => {
          arr.push({
            jbn_id: data.jbn_id,
            'job name': data.job_name,
            'job level': data.job_level,
          });
          arrjbn.push(data.jbn_id);
        });
        setTimeout(() => {
          this.setState({
            headerjobname: head,
            jobnamevalue: arr,
            jbn_id: arrjbn,
            loadCount: this.state.loadCount + 1,
          });
        }, 200);
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/orgposition/getAll/`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const arrpos = [];
        const head = ['pos_id', 'position name'];
        res.map((data) => {
          arr.push({ pos_id: data.pos_id, 'position name': data.position_nm });
          arrpos.push(data.pos_id);
        });
        setTimeout(() => {
          this.setState({
            headerposition: head,
            positionvalue: arr,
            pos_id: arrpos,
            loadCount: this.state.loadCount + 1,
          });
        }, 200);
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/orgjobband/getAll/`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const arrjbd = [];
        const head = ['jbd_id', 'Job band'];
        res.map((data) => {
          arr.push({ jbd_id: data.jbd_id, 'Job band': data.job_band });
          arrjbd.push(data.jbd_id);
        });
        setTimeout(() => {
          this.setState({
            headerjobband: head,
            jobbandvalue: arr,
            jbd_id: arrjbd,
            loadCount: this.state.loadCount + 1,
          });
        }, 200);
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/orgjobgrade/getAll/`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const arrjgd = [];
        const head = ['jgd_id', 'job grade'];
        res.map((data) => {
          arr.push({ jgd_id: data.jgd_id, 'job grade': data.job_grade });
          arrjgd.push(data.jgd_id);
        });
        setTimeout(() => {
          this.setState({
            headergrade: head,
            gradevalue: arr,
            jgd_id: arrjgd,
            loadCount: this.state.loadCount + 1,
          });
        }, 200);
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/attsetting/getAll/`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const arratg = [];
        const head = ['ats_id', 'attendance setting'];
        res.map((data) => {
          arr.push({
            ats_id: data.ats_id,
            'attendance setting': data.ats_name,
          });
          arratg.push(data.ats_id);
        });
        setTimeout(() => {
          this.setState({
            headerattsetting: head,
            attsettingvalue: arr,
            loadCount: this.state.loadCount + 1,
          });
          this.setState({ ats_id: arratg });
        }, 200);
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/rtbemployeementstatus/getAllItemByIsActive`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const head = ['ems_id', 'employeement status'];
          const Arr = [];
          const arrEmpStat = [];
          dataResponse.map((obj) => {
            Arr.push({
              ems_id: obj.ems_id,
              'employeement status': obj.ems_employeement_status,
            }),
              arrEmpStat.push(obj.ems_id);
          });
          this.setState({
            headerems: head,
            emsvalue: Arr,
            employeeStatus: arrEmpStat,
            loadCount: this.state.loadCount + 1,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    axios
      .post(`${ws_ip}/rtbfamilyrelation/getAll`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const head = ['rtf_id', 'Family relation'];
          const Arr = [];
          const arrFamily = [];
          dataResponse.map((obj) => {
            Arr.push({
              rtf_id: obj.rtf_id,
              'Family relation': obj.family_relation,
            });
            arrFamily.push(obj.rtf_id);
          });
          this.setState({
            headerfamily: head,
            familyrelation: Arr,
            arrFamilyRelation: arrFamily,
            loadCount: this.state.loadCount + 1,
          });
        }
      });
    axios
      .post(`${ws_ip}/rtbreligion/getAll`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const head = ['rtg_id', 'Religion'];
          const Arr = [];
          const arrRlg = [];
          dataResponse.map((obj) => {
            Arr.push({ rtg_id: obj.rtg_id, Religion: obj.religion });
            arrRlg.push(obj.rtg_id);
          });

          this.setState({
            headerreligion: head,
            religion: Arr,
            arrReligion: arrRlg,
            loadCount: this.state.loadCount + 1,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    axios
      .post(`${ws_ip}/employee/getSex/`, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const head = ['sex', 'sex name'];
        res.map((data) => {
          arr.push({ sex: data.sex, 'sex name': data.status });
        });
        setTimeout(() => {
          this.setState({
            headersex: head,
            sexname: arr,
            loadCount: this.state.loadCount + 1,
          });
        }, 200);
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
    axios
      .post(`${ws_ip}/Rtbbloodtype/getAll`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const head = ['rtb_id', 'blood name'];
          const Arr = [];
          dataResponse.map((obj) => {
            Arr.push({ rtb_id: obj.rtb_id, 'blood name': obj.blood_type });
          });
          this.setState({
            headerblood: head,
            bloodname: Arr,
            loadCount: this.state.loadCount + 1,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    axios
      .post(`${ws_ip}/Rtbeducationlevel/getAll`, '', config)
      .then((response) => {
        // console.log('ini response', response);
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const head = ['edu_id', 'edu name'];
          const Arr = [];
          const arrEdu = [];
          dataResponse.map((obj) => {
            Arr.push({ edu_id: obj.edu_id, 'edu name': obj.edu_name }),
              arrEdu.push(obj.edu_id);
          });
          this.setState({
            headereducationlevel: head,
            eduname: Arr,
            lastEducation: arrEdu,
            loadCount: this.state.loadCount + 1,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    axios
      .post(`${ws_ip}/Rtbmaritalstatus/getAll`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const head = ['rtm_id', 'marital name'];
          const Arr = [];
          const arrMaritalStatus = [];
          dataResponse.map((obj) => {
            Arr.push({
              rtm_id: obj.rtm_id,
              'marital name': obj.marital_status,
            }),
              arrMaritalStatus.push(obj.rtm_id);
          });
          this.setState({
            headermarital: head,
            maritalname: Arr,
            maritalStatus: arrMaritalStatus,
            loadCount: this.state.loadCount + 1,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    axios
      .post(`${ws_ip}/employee/getCitizen`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const head = ['citizen', 'citizen name'];
          const Arr = [];
          dataResponse.map((obj) => {
            Arr.push({ citizen: obj.citizen, 'citizen name': obj.status });
          });
          this.setState({
            headercitizen: head,
            citizenname: Arr,
            loadCount: this.state.loadCount + 1,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    // bpjstk reg
    const headbpjstk = ['Bpjstk_reg_id', 'Keterangan'];
    const valuebpjstk = [
      { Bpjstk_reg_id: '1', Keterangan: 'Yes' },
      { Bpjstk_reg_id: '2', Keterangan: 'No' },
    ];
    setTimeout(() => {
      this.setState({
        headerbpjstk: headbpjstk,
        bpjstkname: valuebpjstk,
        loadCount: this.state.loadCount + 1,
      });
    }, 200);
    // bpjsks reg
    const headbpjsks = ['Bpjsks_reg_id', 'Keterangan'];
    const valuebpjsks = [
      { Bpjsks_reg_id: '1', Keterangan: 'Yes' },
      { Bpjsks_reg_id: '2', Keterangan: 'No' },
    ];
    setTimeout(() => {
      this.setState({
        headerbpjsks: headbpjsks,
        bpjsksname: valuebpjsks,
        loadCount: this.state.loadCount + 1,
      });
    }, 200);
    // Generate User
    const headgenuser = ['generate_user', 'Keterangan'];
    const valuegenuser = [
      { generate_user: '1', Keterangan: 'Yes' },
      { generate_user: '2', Keterangan: 'No' },
    ];
    setTimeout(() => {
      this.setState({
        headergenuser: headgenuser,
        genusername: valuegenuser,
        loadCount: this.state.loadCount + 1,
      });
    }, 200);
    // identity
    axios
      .post(`${ws_ip}/Rtbidentitytype/getAll`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const head = ['rti_id', 'identity name'];
          const Arr = [];
          const arrIdentity = [];
          dataResponse.map((obj) => {
            Arr.push({
              rti_id: obj.rti_id,
              'identity name': obj.identity_type,
            }),
              arrIdentity.push(obj.rti_id);
          });
          this.setState({
            headeriden: head,
            idenname: Arr,
            identity: arrIdentity,
            loadCount: this.state.loadCount + 1,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  handleOnFileLoad = (data, e) => {
    const array = [],
      headers = [],
      errheader = [],
      header = data[0].data;
    header.map((item) => {
      // change geofence_area to ogf_id
      item = item.toLowerCase().replace(/\s/g, '_');
      if (item === 'geofence_area') {
        item = 'ogf_id';
      }
      headers.push(item);
    });
    const headrequire = [
      'no',
      'employee_code',
      'first_name',
      'last_name',
      'email',
      'mobile',
      'phone_number',
      'address',
      'postal_code',
      'date_of_birth',
      'place_of_birth',
      'sex',
      'blood_type',
      'last_education',
      'marital_status',
      'religion',
      'citizenship',
      'country',
      'identity',
      'identity_number',
      'expired_date',
      'emergency_contact',
      'relation',
      'relation_name',
      'attendance_type_code',
      'tax_status',
      'overtime',
      'bpjstk_registered',
      'bpjstk_number',
      'bpjsks_registered',
      'bpjsks_number',
      'npwp',
      'npwp_date',
      'bank_name',
      'bank_account',
      'account_holder',
      'office',
      'organizational_unit',
      'job_name',
      'position',
      'job_band',
      'grade',
      'superordinat',
      'approval_line',
      'employeement_status',
      'join_date',
      'attendance_setting',
      'default_tax_method',
      'pay_tax_class',
      'generate_user',
      'ogf_id',
    ];
    headrequire.some((v) => {
      if (headers.indexOf(v) === -1) {
        errheader.push(`${v} not found `);
      }
    });

    // eslint-disable-next-line object-curly-newline
    const {
      emp_code,
      finger_id,
      attsettingvalue,
      ats_id,
      jbd_id,
      jbn_id,
      off_id,
      org_id,
      pos_id,
      jgd_id,
      ogf_id,
      country,
      taxStatus,
      taxMethod,
      bank,
      employeeStatus,
      arrFamilyRelation,
      arrReligion,
      payTaxClass,
      lastEducation,
      maritalStatus,
      identity,
      generateUser,
      BPJSTKRegis,
      BPJSKSRegis,
    } = this.state;
    if (data.length > 0) {
      array.push({ header: data[0].data, value: data.slice(1) });
    }
    const datacsv = data.slice(1);
    const validdata = [];
    const errordata = [];
    const errormsg = [];
    const dateReg = /^\d{2}([/])\d{2}([/])\d{4}$/;
    const timeReg = /^\d{2}([:])\d{2}$/;

    const fingerId = datacsv.map(
      (e) => e.data[headers.indexOf('attendance_type_code')],
    );

    if (errheader.length > 0) {
      errormsg.push({ row: 'header', log: errheader });
    } else {
      datacsv.map((item, index) => {
        const errorlog = [];
        let stsva;
        if (item.data.length > 1) {
          item.data.map((hea, key) => {
            if (typeof headers[key] !== 'undefined') {
              if (headers[key].toLowerCase().includes('no')) {
                if (Number.isNaN(hea) === null) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
              }
              if (headers[key].toLowerCase().includes('employee_code')) {
                if (hea === '') {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
              }
              if (headers[key].toLowerCase().includes('first_name')) {
                if (hea === '') {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
              }
              if (headers[key].toLowerCase().includes('date')) {
                if (hea !== '') {
                  const input = hea;
                  const [day, month, year] = input.split('/');
                  const actualdate = new Date(`${year}/${month}/${day}`);
                  const stringdate = moment(actualdate).format('DD/MM/YYYY');
                  if (stringdate !== hea) {
                    stsva = false;
                    errorlog.push(`${header[key]} out of date  `);
                  }
                  if (hea.match(dateReg) === null) {
                    stsva = false;
                    errorlog.push(`${header[key]} Invalid, `);
                  }
                }
              }
              if (headers[key].toLowerCase().includes('place_of_birth')) {
                if (hea === '') {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
              }
              if (headers[key].toLowerCase().includes('pay_tax_class')) {
                if (hea === '') {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (payTaxClass.indexOf(parseInt(+hea, 10)) === -1) {
                  stsva = false;
                  errorlog.push(`Pay Tax Class ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('country')) {
                if (hea != '') {
                  if (country.indexOf(parseInt(+hea, 10)) === -1) {
                    stsva = false;
                    errorlog.push(`Country ${hea} not found`);
                  }
                }
              }
              if (headers[key].toLowerCase().includes('identity_number')) {
                if (hea !== '') {
                  if (hea.includes('E+')) {
                    stsva = false;
                    errorlog.push(`${header[key]} Invalid, `);
                  }
                }
              }
              if (headers[key].toLowerCase().includes('attendance_type_code')) {
                if (hea === '') {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (finger_id.includes(hea) && this.state.isEdit == 'false') {
                  stsva = false;
                  errorlog.push(`attendance type code ${hea} has been used`);
                }
              }
              if (headers[key].toLowerCase().includes('attendance_setting')) {
                if (hea === '') {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (ats_id.indexOf(parseInt(hea, 10)) === -1) {
                  stsva = false;
                  errorlog.push(`attendance setting ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('ogf_id')) {
                if (hea.split(',').length > 5) {
                  stsva = false;
                  errorlog.push(`${header[key]} Maximum ogf_id is 5 Location,`);
                }
                const pairedArray = [];
                hea?.split(',')?.map((itemhea, index) => {
                  const duplicateIndices = hea
                    ?.split(',')
                    .slice(index + 1)
                    .filter((element) => element === itemhea)
                    .map((_, i) => i + index + 1);

                  if (duplicateIndices.length > 0) {
                    pairedArray.push([
                      itemhea,
                      ...duplicateIndices.map((i) => hea?.split(',')[i]),
                    ]);
                  }
                  if (ogf_id.indexOf(parseInt(+itemhea, 10)) === -1) {
                    stsva = false;
                    errorlog.push(`${header[key]} ${itemhea} not found`);
                  }
                });
                if (pairedArray?.length) {
                  pairedArray.map((item) => {
                    stsva = false;
                    errorlog.push(`${header[key]} ${item[0]} already used`);
                  });
                }
              }
              if (headers[key].toLowerCase().includes('superordinat')) {
                if (hea !== '') {
                  if (emp_code.indexOf(hea) === -1) {
                    stsva = false;
                    errorlog.push(
                      `Superordinat employee code ${hea} not found`,
                    );
                  }
                }
              }
              if (headers[key].toLowerCase().includes('approval')) {
                if (hea !== '') {
                  if (emp_code.indexOf(hea) === -1) {
                    stsva = false;
                    errorlog.push(
                      `Approval Line employee code ${hea} not found`,
                    );
                  }
                }
              }
              if (headers[key].toLowerCase().includes('office')) {
                if (hea === '' || isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (off_id.indexOf(parseInt(hea, 10)) === -1) {
                  stsva = false;
                  errorlog.push(`office ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('organizational_unit')) {
                if (hea === '' || isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (org_id.indexOf(parseInt(hea, 10)) === -1) {
                  stsva = false;
                  errorlog.push(`Organizational unit ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('job_name')) {
                if (hea === '' || isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (jbn_id.indexOf(parseInt(hea, 10)) === -1) {
                  stsva = false;
                  errorlog.push(`Job name ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('position')) {
                if (hea === '' || isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (pos_id.indexOf(parseInt(hea, 10)) === -1) {
                  stsva = false;
                  errorlog.push(`Position ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('job_band')) {
                if (hea === '' || isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (jbd_id.indexOf(parseInt(hea, 10)) === -1) {
                  stsva = false;
                  errorlog.push(`Job band ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('grade')) {
                if (hea === '' || isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (jgd_id.indexOf(parseInt(hea, 10)) === -1) {
                  stsva = false;
                  errorlog.push(`Grade ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('generate_user')) {
                if (hea === '' || isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (generateUser.indexOf(parseInt(+hea, 10)) === -1) {
                  stsva = false;
                  errorlog.push(`Generate user ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('sex')) {
                if (isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (!['1', '2'].includes(hea)) {
                  stsva = false;
                  errorlog.push(`Sex ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('blood_type')) {
                if (isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (!['1', '2', '3', '4'].includes(hea) && hea !== '') {
                  stsva = false;
                  errorlog.push(`Blood ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('marital_status')) {
                if (isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (
                  maritalStatus.indexOf(parseInt(+hea, 10)) === -1 &&
                  hea !== ''
                ) {
                  stsva = false;
                  errorlog.push(`Marital Status ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('religion')) {
                if (isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (
                  arrReligion.indexOf(parseInt(+hea, 10)) === -1 &&
                  hea !== ''
                ) {
                  stsva = false;
                  errorlog.push(`Religion ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase() === 'relation') {
                if (isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (
                  arrFamilyRelation.indexOf(parseInt(+hea, 10)) === -1 &&
                  hea !== ''
                ) {
                  stsva = false;
                  errorlog.push(`Family Relation ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('citizenship')) {
                if (isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (!['1', '2'].includes(hea) && hea !== '') {
                  stsva = false;
                  errorlog.push(`Citizenship ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase() === 'identity') {
                if (isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (identity.indexOf(parseInt(+hea, 10)) === -1 && hea !== '') {
                  stsva = false;
                  errorlog.push(`Identity ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('employeement_status')) {
                if (isNaN(+hea) || hea == '') {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (
                  employeeStatus.indexOf(parseInt(+hea, 10)) === -1 &&
                  hea !== ''
                ) {
                  stsva = false;
                  errorlog.push(`Employee Status ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('tax_status')) {
                if (isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (
                  taxStatus.indexOf(parseInt(+hea, 10)) === -1 &&
                  hea !== ''
                ) {
                  stsva = false;
                  errorlog.push(`Tax Status ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('bank_name')) {
                if (isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (bank.indexOf(parseInt(+hea, 10)) === -1 && hea !== '') {
                  stsva = false;
                  errorlog.push(`Bank ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('default_tax_method')) {
                if (isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (
                  taxMethod.indexOf(parseInt(+hea, 10)) === -1 &&
                  hea !== ''
                ) {
                  stsva = false;
                  errorlog.push(`Default Tax Method ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('bpjstk_registered')) {
                if (isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (
                  BPJSTKRegis.indexOf(parseInt(+hea, 10)) === -1 &&
                  hea !== ''
                ) {
                  stsva = false;
                  errorlog.push(`BPJSTK ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('bpjsks_registered')) {
                if (isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (
                  BPJSKSRegis.indexOf(parseInt(+hea, 10)) === -1 &&
                  hea !== ''
                ) {
                  stsva = false;
                  errorlog.push(`BPJSKS ${hea} not found`);
                }
              }
              if (headers[key].toLowerCase().includes('last_education')) {
                if (isNaN(+hea)) {
                  stsva = false;
                  errorlog.push(`${header[key]} Invalid, `);
                }
                if (
                  lastEducation.indexOf(parseInt(+hea, 10)) === -1 &&
                  hea !== ''
                ) {
                  stsva = false;
                  errorlog.push(`Last Education ${hea} not found`);
                }
              }
            }
          });
        }

        if (errorlog.length === 0) {
          stsva = true;
        }

        if (stsva) {
          validdata.push(item);
          this.setState({ arrdatacsv: validdata, head: headers, file: e });
        } else {
          errormsg.push({ row: index + 1, log: errorlog });
          errordata.push({ item });
        }
      });
    }

    if (errormsg.length > 0) {
      this.setState({ error: true });
      this.setState({ message: errormsg, ...this.state.message });
    }
  };

  handleFirstChunk = (chunkc) => {
    const chunk = chunkc.split('\n');
    if (chunk[0].split(',').length <= 1 && chunk[0].split(';').length <= 1) {
      setTimeout(() => {
        this.handleOnRemoveFile();
      }, 2000);
      showNotifications('Fail', 'Format Not Supported', 'danger');
    }
    // return true;
  };

  handleOnError(err, file, inputElem, reason) {
    console.log(err);
    console.log(file);
    console.log(inputElem);
    console.log(reason);
  }

  handleSubmit(values) {
    this.setState({ displayLoad: '' });
    this.setState({ displayButton: 'none' });
    this.setState({
      displaySave: 'none',
      displaySpinner: '',
    });
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws, arrdatacsv, head, file } = this.state;
    const error = true;
    const csvfile = file;

    const arr = [];
    const updatelist = [];
    let url;

    arrdatacsv.map((elemet, key) => {
      if (elemet.data.length > 1) {
        const obj = {};
        elemet.data.map((value, index) => {
          obj[head[index]] = value;
        });
        arr.push(obj);
      }
    });

    if (values.type.value === '1') {
      url = `${apiws}/employee/saveItemArray`;
    } else if (values.type.value === '2') {
      url = `${apiws}/employee/updateItemArray`;
    }
    const array = JSON.stringify(arr);

    const convertdata = { data: array, csvfile };
    const body = { ...convertdata, cuser_id: userid };
    const getFormData = Object.keys(body).reduce((formData, key) => {
      formData.append(key, body[key]);
      return formData;
    }, new FormData());

    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    setTimeout(() => {
      axios
        .post(url, getFormData, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            this.setState({ displayLoad: 'none' });
            this.setState({ displayButton: '' });
            showNotifications('Success', 'Save Success', 'success');
            this.props.destroy();
            this.handleOnRemoveFile();
            setTimeout(() => {
              this.getEmployee();
            }, 200);
          } else {
            this.setState({ displayLoad: 'none' });
            this.setState({ displayButton: '' });
            showNotifications('Fail', 'Save Failed', 'danger');
            this.setState({ displaySpinner: 'none', displaySave: '' });
            this.props.destroy();
            this.getEmployee();
            this.handleOnRemoveFile();
          }
        })
        .catch(() => {
          this.setState({ displayLoad: 'none' });
          this.setState({ displayButton: '' });
          showNotifications('Fail', 'Save Failed', 'danger');
          this.props.destroy();
          this.getEmployee();
          this.handleOnRemoveFile();
        });
    });
  }

  handleOnRemoveFile = (e) => {
    this.myFormRef.reset();
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
    this.setState({ error: false });
    this.setState({ message: [] });
  };

  downloadTemplate() {
    const headrequire = [
      'No',
      'Employee code',
      'First name',
      'Last name',
      'Email',
      'Mobile',
      'Phone number',
      'Address',
      'Postal code',
      'Date of birth',
      'Place of birth',
      'Sex',
      'Blood type',
      'Last Education',
      'Marital status',
      'Religion',
      'Citizenship',
      'Country',
      'Identity',
      'Identity number',
      'Expired date',
      'Emergency contact',
      'Relation',
      'Relation name',
      'Attendance type code',
      'Tax status',
      'Default tax method',
      'Pay Tax Class',
      'Overtime',
      'Bpjstk registered',
      'Bpjstk number',
      'Bpjsks registered',
      'Bpjsks number',
      'NPWP',
      'NPWP date',
      'ID TKU',
      'Bank name',
      'Bank account',
      'Account holder',
      'Office',
      'Organizational unit',
      'Job name',
      'Position',
      'Job band',
      'Grade',
      'Superordinat',
      'Approval line',
      'Employeement status',
      'Join date',
      'Attendance Setting',
      'Geofence Area',
      'Generate user',
    ];
    const value = [
      {
        No: '1',
        'Employee code': 'S1090900',
        'First name': 'Javier',
        'Last name': 'february',
        Email: 'javierpena@gmail.com',
        Mobile: '0898987789',
        'Phone number': '',
        Address: 'Sudirman',
        'Postal code': '44151',
        'Date of birth': '17-08-1987',
        'Place of birth': 'Columbia',
        Sex: '1',
        'Blood type': '1',
        'Last Education': '8',
        'Marital status': '1',
        Religion: '1',
        Citizenship: '1',
        Country: '1',
        Identity: '1',
        'Identity number': '205066502970004',
        'Expired date': '09/09/2020',
        'Emergency contact': '081323373702',
        Relation: '1',
        'Relation name': 'Steve',
        'Attendance type code': '189000',
        'Tax status': '2',
        'Pay Tax Class': '1',
        'Default tax method': '1',
        Overtime: '',
        'Bpjstk registered': '1',
        'Bpjstk number': '222',
        'Bpjsks registered': '1',
        'Bpjsks number': '222',
        NPWP: '099900999',
        'NPWP date': '31/12/2020',
        'ID TKU': '3323232323232323232232',
        'Bank name': '2',
        'Bank account': '1234567',
        'Account holder': 'Javier',
        Office: '1',
        'Organizational unit': '1',
        'Job name': '1',
        Position: '1',
        'Job band': '1',
        Grade: '1',
        Superordinat: 'S1010109001',
        'Approval line': 'S1010109001',
        'Employeement status': '1',
        'Join date': '09/09/2020',
        Attendance: '1',
        ogf_id: '1',
        'Generate user': '1',
      },
    ];
    setTimeout(() => {
      this.setState({ headerexcel: headrequire, templatevalue: value });
    }, 200);
  }

  downloadjobgrade() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const ws_ip = ls.get('ws_ip');
    const url = `${ws_ip}/orgjobgrade/getAll/`;
    axios
      .post(url, '', config)
      .then((response) => {
        const res = response.data.data;
        const arr = [];
        const head = ['jgd_id', 'job grade'];
        res.map((data) => {
          arr.push({ jgd_id: data.jgd_id, 'job grade': data.job_grade });
        });
        setTimeout(() => {
          this.setState({ headergrade: head, gradevalue: arr });
        }, 200);
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  render() {
    const { t, handleSubmit } = this.props;
    const ulstyle = { width: '300px', height: '200px', overflow: 'auto' };
    const notestyle = { 'column-count': '4', 'column-gap': '20px' };
    let button_action;
    if (this.state.error) {
      button_action = (
        <Button color="primary" type="submit" style={{ display: 'none' }}>
          {t('FRM.SUBMIT')}{' '}
        </Button>
      );
    } else {
      button_action = (
        <Button
          color="primary"
          type="submit"
          style={{ display: this.state.displayButton }}
        >
          {t('FRM.SUBMIT')}
        </Button>
      );
    }
    return (
      <Card>
        <CardBody>
          <Row className="m5">
            <Col sm="12" md={12} lg={12}>
              <form
                className="form form--horizontal"
                onSubmit={handleSubmit(this.handleSubmit)}
                // eslint-disable-next-line no-return-assign
                ref={(el) => (this.myFormRef = el)}
                name="loaderemp"
                id="loaderemp"
              >
                <Container>
                  <Row>
                    <Col sm="12" md={6} lg={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.FILE')}
                        </span>
                        <div className="form__form-group-field">
                          <CSVReader
                            onDrop={this.handleOnFileLoad}
                            onError={this.handleOnError}
                            noDrag
                            addRemoveButton
                            config={{
                              beforeFirstChunk: this.handleFirstChunk,
                              delimitersToGuess: [';', ','],
                            }}
                            onRemoveFile={this.handleOnRemoveFile}
                          >
                            <span> {t('LBL.CLICK_TO_UPLOAD')}</span>
                          </CSVReader>
                        </div>
                      </div>
                    </Col>
                    {this.state.error === true && (
                      <Col sm="12" md={6} lg={6}>
                        <span
                          className="form__form-group-label"
                          style={{ color: 'red' }}
                        >
                          {t('LBL.ERROR')}
                        </span>
                        <ul style={ulstyle}>
                          {this.state.message.map((obj) => (
                            <React.Fragment>
                              <li>
                                {' '}
                                {t('LBL.ROW')} {obj.row}
                              </li>
                              <ul>
                                {' '}
                                {obj.log.map((ob) => (
                                  <li>{ob}</li>
                                ))}{' '}
                              </ul>
                            </React.Fragment>
                          ))}
                        </ul>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.TYPE_OPERATION')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="type"
                            component={renderSelectField}
                            options={[
                              {
                                value: '1',
                                label: 'insert new data, skip existing record',
                              },
                              {
                                value: '2',
                                label:
                                  'insert new data, update existing record',
                              },
                            ]}
                            onChange={(val) => {
                              val.value == '2'
                                ? this.setState({ isEdit: 'true' })
                                : this.setState({ isEdit: 'false' });
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: this.state.displayLoad,
                      textAlign: 'center',
                    }}
                    className="m5"
                  >
                    <Col xs={12} md={6} lg={6} xl={6}>
                      <Card>
                        <CardBody>
                          <div className="load__icon-wrap">
                            <svg className="load__icon">
                              <path
                                fill="#4ce1b6"
                                d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                              />
                            </svg>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
                <ButtonToolbar className="form__button-toolbar">
                  {button_action}
                  <Button
                    type="button"
                    onClick={() => this.handleOnRemoveFile()}
                    style={{ display: this.state.displayButton }}
                  >
                    {t('FRM.CANCEL')}
                  </Button>
                </ButtonToolbar>
              </form>
            </Col>
            <Col sm="12" md="12" lg="12" xl="12">
              <div className="note-file-upload">
                <ExcelFile
                  filename="Template Employee Loader"
                  element={
                    <Link
                      to="#"
                      // type="button"
                      onClick={this.downloadTemplate}
                    >
                      {' '}
                      <FontAwesomeIcon icon={faDownload} />{' '}
                      <h5>
                        <b>
                          {t('LBL.DOWNLOAD')} {t('LBL.TEMPLATE')}
                        </b>
                      </h5>
                    </Link>
                  }
                >
                  <ExcelSheet data={this.state.templatevalue} name="Employees">
                    {this.state.headerexcel.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                </ExcelFile>
                <ExcelFile
                  filename="Employee References"
                  element={
                    this.state.loadCount >= 24 ? (
                      <Link
                        to="#"
                        // type="button"
                        onClick={this.loadExcelSheet}
                      >
                        {' '}
                        <FontAwesomeIcon icon={faDownload} />{' '}
                        <h5>
                          <b>
                            {t('LBL.DOWNLOAD')} {t('LBL.EMPLOYEE')}{' '}
                            {t('LBL.REFERENCES')}
                          </b>
                        </h5>
                      </Link>
                    ) : (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{
                          color: '#1974EF',
                          marginTop: '5px',
                          marginBottom: '5px',
                        }}
                        disabled
                      />
                    )
                  }
                >
                  <ExcelSheet
                    data={this.state.countryvalue}
                    name="Country Code"
                  >
                    {this.state.headercountry.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.taxstatusval} name="Tax Status">
                    {this.state.headertaxstat.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.methodvalue} name="Tax Method">
                    {this.state.headermethod.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.banknamevalue} name="Bank">
                    {this.state.headerbank.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.officevalue} name="Office">
                    {this.state.headeroffice.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet
                    data={this.state.organizationvalue}
                    name="Unit Organization"
                  >
                    {this.state.headerorganization.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.jobnamevalue} name="Job Name">
                    {this.state.headerjobname.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.positionvalue} name="Position">
                    {this.state.headerposition.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.jobbandvalue} name="Job Band">
                    {this.state.headerjobband.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.gradevalue} name="Grade">
                    {this.state.headergrade.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet
                    data={this.state.attsettingvalue}
                    name="Attendance setting"
                  >
                    {this.state.headerattsetting.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.emsvalue} name="Employee status">
                    {this.state.headerems.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet
                    data={this.state.familyrelation}
                    name="Family relation"
                  >
                    {this.state.headerfamily.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.religion} name="Religion">
                    {this.state.headerreligion.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.tcsvalue} name="Pay Tax Class">
                    {this.state.headertcs.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.ogfvalue} name="Geofence Area">
                    {this.state.headerogf.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.sexname} name="Sex">
                    {this.state.headersex.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.bloodname} name="Blood">
                    {this.state.headerblood.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.eduname} name="Last Education">
                    {this.state.headereducationlevel.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet
                    data={this.state.maritalname}
                    name="Marital Status"
                  >
                    {this.state.headermarital.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.citizenname} name="Citizenship">
                    {this.state.headercitizen.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet
                    data={this.state.bpjstkname}
                    name="BPJSTK Registered"
                  >
                    {this.state.headerbpjstk.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet
                    data={this.state.bpjsksname}
                    name="BPJSKS Registered"
                  >
                    {this.state.headerbpjsks.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet data={this.state.idenname} name="Identity">
                    {this.state.headeriden.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                  <ExcelSheet
                    data={this.state.genusername}
                    name="Generate User"
                  >
                    {this.state.headergenuser.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                </ExcelFile>
                <br />
                <h5>
                  <b>NOTE:</b>
                </h5>
                <h5>Required file type (*.CSV)</h5>
                <h5>Field Order :</h5>
                <ol style={notestyle}>
                  <li>No</li>
                  <li>Employee Code</li>
                  <li>First Name</li>
                  <li>Last Name</li>
                  <li>Email</li>
                  <li>Mobile</li>
                  <li>Phone Number</li>
                  <li>Address</li>
                  <li>Postal Code</li>
                  <li>Date of Birth(Format: (dd/mm/yyyy))</li>
                  <li>Place of Birth</li>
                  <li>Sex(1: Male, 2: Female)</li>
                  <li>Blood Type(1: O, 2: A, 3: B, 4: AB)</li>
                  <li>Last Education</li>
                  <li>Marital Status(1: Kawin, 2: Belum Kawin, 3: Cerai)</li>
                  <li>Religion</li>
                  <li>Citizenship(1: WNI, 2: WNA)</li>
                  <li>Country</li>
                  <li>identity(1: KTP, 2: SIM, 3: PASSPOR)</li>
                  <li>identity number</li>
                  <li>expired date</li>
                  <li>Emergency Contact</li>
                  <li>Relation</li>
                  <li>Relation Name</li>
                  <li>Attendance Type Code</li>
                  <li>Tax Status</li>
                  <li>Default Tax Method</li>
                  <li>Pay Tax Class</li>
                  <li>Overtime</li>
                  <li>Bpjstk Registered(1: Yes, 2: No)</li>
                  <li>Bpjstk Number</li>
                  <li>Bpjsks Registered(1: Yes, 2: No)</li>
                  <li>Bpjsks Number</li>
                  <li>NPWP</li>
                  <li>NPWP Date(Format:(dd/mm/yyyy))</li>
                  <li>ID TKU</li>
                  <li>Bank Name</li>
                  <li>Bank Account</li>
                  <li>Account Holder</li>
                  <li>Office</li>
                  <li>Organizational Unit</li>
                  <li>Job Name</li>
                  <li>Position</li>
                  <li>Job Band</li>
                  <li>Grade</li>
                  <li>Super Ordinat</li>
                  <li>Approval Line</li>
                  <li>Employment Status</li>
                  <li>Join Date(Format:(dd/mm/yyyy))</li>
                  <li>Attendance setting</li>
                  <li>
                    Geofence Area (Format:(ogf_id_1, ogf_id_2)) Max. 5 ogf_id
                  </li>
                  <li>Generate user(1: Yes, 0: No)</li>
                </ol>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.type) {
    errors.type = 'Type field shouldn’t be empty';
  }

  return errors;
};

export default reduxForm({
  validate,
  form: 'loaderemp', // a unique identifier for this form
})(translate('global')(HorizontalForm));
