/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/extensions */
/* eslint-disable no-tabs */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-unused-state,object-shorthand,arrow-parens,comma-dangle,jsx-a11y/anchor-is-valid,no-script-url,react/style-prop-object,no-console,no-trailing-spaces,space-in-parens,space-infix-ops,react/no-unescaped-entities,camelcase,no-plusplus,max-len,quote-props,no-unused-vars */
/* eslint-disable-next-line no-underscore-dangle */
/* eslint-disable-next-line react/sort-comp */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Card,
  CardBody,
  Col,
  ButtonToolbar,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
  UncontrolledDropdown,
} from 'reactstrap';
import axios from 'axios';
import { translate } from 'react-i18next';
import { Link, NavLink as RRNavLink } from 'react-router-dom';
import ws from '../../../../shared/components/config/config';
import ls from 'local-storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faSearch,
  faUserPlus,
  faArrowRight,
  faFilter,
  faDownload,
} from '@fortawesome/fontawesome-free-solid';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import { Field, reduxForm } from 'redux-form';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import Swal from 'sweetalert2';
import '../../../Tables/DataTable/assets/css/style.css';
import utils from '../../../../utils';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderSelectField from '../../../../shared/components/form/Select';
import moment from 'moment';
import MinusIcon from 'mdi-react/MinusIcon';
import ReactExport from 'react-export-excel';
import showNotifications from '../../../Preferences/notificationMessages';
import { store } from '../../../App/store';
import { connect } from 'react-redux';
import './employeeList.css';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const user1 = `${process.env.PUBLIC_URL}/public/uploads/emp/user_736c6e31-6daa-437b-9d31-8625f89fdbfd_file.png`;
const user2 = `${process.env.PUBLIC_URL}/user-images.png`;
const user3 = `${process.env.PUBLIC_URL}/img/user_photo/user-3.jpg`;
const user4 = `${process.env.PUBLIC_URL}/img/user_photo/user-4.jpg`;
let abortController = new AbortController();
let requestCompleted = true;
const WAIT_INTERVAL = 1000;

class DataTable extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    men_id: PropTypes.string.isRequired,
    fga_id: PropTypes.string.isRequired,
    location: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE_NAME'),
      t('LBL.EMPLOYEE_CODE'),
      t('LBL.POSITION_NAME'),
      t('LBL.JOB_BAND'),
      t('LBL.JOIN_DATE'),
      t('LBL.STATUS'),
      '#',
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;

    this.state = {
      rows: [],
      limit: 10, // default is 10 rows
      offset: 0,
      page: 1,
      disable_preview: '',
      disable_next: '',
      modal_show: false,
      search: '',
      total: 0,
      token: '',
      apiws: '',
      idUser: '',
      total_pagination: 0,
      total_active: 0,
      current_pagination: 0,
      label_idx_start: 0,
      label_idx_end: 0,
      path_root: '',
      parm_id_usr: 0,
      menu: [],
      addcond: '',
      editcond: '',
      menu2: [],
      maxuser: 0,
      btnAdjsustment: false,
      menIdAdjustment: '',
      btnPromotion: false,
      menIdPromotion: '',
      men_id: '',
      fga_id: '',
      date_start: null,
      date_end: null,
      status: '0',
      sex: '',
      job_title: '',
      job_band: '',
      adv_filter: false,
      org_unit: '',
      rtborgjobtitle: [],
      rtborgjobband: [],
      rtborgjobgrade: [],
      rtborgorganization: [],
      rtbemployeementstatus: [],
      perm_start_date: null,
      perm_end_date: null,
      term_start_date: null,
      term_end_date: null,
      job_grade: '',
      emp_status: '',
      isOpen: false,
      isClear: false,
      filename: 'Report Employee',
      dataexcel: [],
      headerexcel: [],
      trigger: false,
      arrCompany: this?.props.loadBoxEmployee?.company?.length > 0,
      arrOffice: this?.props.loadBoxEmployee?.office?.length > 0,
      arrGeofence: this?.props.loadBoxEmployee?.geofence?.length > 0,
      arrOrganization: this?.props.loadBoxEmployee?.organization?.length > 0,
      arrJobBand: this?.props.loadBoxEmployee?.jobBand?.length > 0,
      arrAttShift: this?.props.loadBoxEmployee?.attShift?.length > 0,
      blood: this?.props.loadBoxEmployee?.blood?.length > 0,
      religion: this?.props.loadBoxEmployee?.religion?.length > 0,
      maritalStatus: this?.props.loadBoxEmployee?.maritalStatus?.length > 0,
      country: this?.props.loadBoxEmployee?.country?.length > 0,
      payComponentGroup:
        this?.props.loadBoxEmployee?.payComponentGroup?.length > 0,
      familyRelation: this?.props.loadBoxEmployee?.familyRelation?.length > 0,
      taxStatus: this?.props.loadBoxEmployee?.taxStatus?.length > 0,
      bank: this?.props.loadBoxEmployee?.bank?.length > 0,
      payProrate: this?.props.loadBoxEmployee?.payProrate?.length > 0,
      identityType: this?.props.loadBoxEmployee?.identityType?.length > 0,
      payComponentGroupRel:
        this?.props.loadBoxEmployee?.payComponentGroupRel?.length > 0,
      payTaxMethod: this?.props.loadBoxEmployee?.payTaxMethod?.length > 0,
      payTaxClass: this?.props.loadBoxEmployee?.payTaxClass?.length > 0,
      itemRecurring: this?.props.loadBoxEmployee?.itemRecurring?.length > 0,
      jobLevel: this?.props.loadBoxEmployee?.jobLevel?.length > 0,
      empApproval: this?.props.loadBoxEmployee?.empApproval?.length > 0,
    };

    this.reload = this.reload.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.changePage = this.changePage.bind(this);
    this.search = this.search.bind(this);
    this.getReference = this.getReference.bind(this);
    this.getJobBand = this.getJobBand.bind(this);
    this.getJobGrade = this.getJobGrade.bind(this);
    this.getJobTitle = this.getJobTitle.bind(this);
    this.getOrgUnit = this.getOrgUnit.bind(this);
    this.getEmpStatus = this.getEmpStatus.bind(this);
    this.getActiveEmp = this.getActiveEmp.bind(this);
    this.createButtonPagination = this.createButtonPagination.bind(this);
    this.goto = this.goto.bind(this);
    this.handleReport = this.handleReport.bind(this);
  }

  componentDidUpdate() {
    if (
      this.state.sex ||
      this.state.org_unit ||
      this.state.job_title ||
      this.state.job_band ||
      this.state.job_grade ||
      this.state.emp_status ||
      this.state.perm_start_date ||
      this.state.perm_end_date ||
      this.state.term_start_date ||
      this.state.term_end_date
    ) {
      this.setState({
        isClear: true,
      });
    }
  }

  componentWillMount() {
    this.timer = null;
    this.reload();
    this.setState({
      path_root: `${ls.get('ws_ip')}`,
      maxuser: ls.get('membership').max_user,
    });
    this.getActiveEmp();
    this.getJobBand();
    this.getJobTitle();
    this.getOrgUnit();
    this.getEmpStatus();
    this.getJobGrade();
    if (this.props.men_id === null && this.props.fga_id === null) {
      const { men_id } = this.props.location.state;
      const { fga_id } = this.props.location.state;
      this.setState({ men_id: men_id, fga_id: fga_id }, () => {
        this.getReference();
        this.getButton();
      });
    } else {
      const { men_id } = this.props;
      const { fga_id } = this.props;
      this.setState({ men_id: men_id, fga_id: fga_id }, () => {
        this.getReference();
        this.getButton();
      });
    }

    if (this?.props.loadBoxEmployee?.company?.length == 0) {
      this.getCompany();
    }
    if (this?.props.loadBoxEmployee?.office?.length == 0) {
      this.getOffice();
    }
    if (this?.props.loadBoxEmployee?.geofence?.length == 0) {
      this.getGeofence();
    }
    if (this?.props.loadBoxEmployee?.organization?.length == 0) {
      this.getOrganization();
    }
    if (this?.props.loadBoxEmployee?.jobBand?.length == 0) {
      this.getJobBandData();
    }
    if (this?.props.loadBoxEmployee?.attShift?.length == 0) {
      this.getAttSetting();
    }
    if (this?.props.loadBoxEmployee?.blood?.length == 0) {
      this.getBloodType();
    }
    if (this?.props.loadBoxEmployee?.religion?.length == 0) {
      this.getReligion();
    }
    if (this?.props.loadBoxEmployee?.maritalStatus?.length == 0) {
      this.getMaritalStatus();
    }
    if (this?.props.loadBoxEmployee?.country?.length == 0) {
      this.getCountry();
    }
    if (this?.props.loadBoxEmployee?.payComponentGroup?.length == 0) {
      this.getPayComponentGroup();
    }
    if (this?.props.loadBoxEmployee?.familyRelation?.length == 0) {
      this.getFamilyRelation();
    }
    if (this?.props.loadBoxEmployee?.taxStatus?.length == 0) {
      this.getTaxStatus();
    }
    if (this?.props.loadBoxEmployee?.bank?.length == 0) {
      this.getBank();
    }
    if (this?.props.loadBoxEmployee?.payProrate?.length == 0) {
      this.getPayProrate();
    }
    if (this?.props.loadBoxEmployee?.payComponentGroupRel?.length == 0) {
      this.getPayComponentGroupRel();
    }
    if (this?.props.loadBoxEmployee?.payTaxMethod?.length == 0) {
      this.getPayTaxMethod();
    }
    if (this?.props.loadBoxEmployee?.payTaxClass?.length == 0) {
      this.getPayTaxClass();
    }
    if (this?.props.loadBoxEmployee?.itemRecurring?.length == 0) {
      this.getItemCurring();
    }
    if (this?.props.loadBoxEmployee?.jobLevel?.length == 0) {
      this.getJobLevel();
    }
    if (this?.props.loadBoxEmployee?.empApproval?.length == 0) {
      this.getApprovalEmp();
    }

    console.log('redux jalan');
    // else {
    setTimeout(() => {
      this.getGeofence();
      this.getOffice();
      this.getCompany();
      this.getOrganization();
      this.getJobBandData();
      this.getAttSetting();
      this.getBloodType();
      this.getReligion();
      this.getMaritalStatus();
      this.getCountry();
      this.getPayComponentGroup();
      this.getFamilyRelation();
      this.getTaxStatus();
      this.getBank();
      this.getPayProrate();
      this.getPayComponentGroupRel();
      this.getPayTaxMethod();
      this.getPayTaxClass();
      this.getItemCurring();
      this.getJobLevel();
      this.getApprovalEmp();
    }, 1000);
  }

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.EMPLOYEE_NAME'),
      t('LBL.EMPLOYEE_CODE'),
      t('LBL.POSITION_NAME'),
      t('LBL.JOB_BAND'),
      t('LBL.JOIN_DATE'),
      t('LBL.STATUS'),
      '#',
    ];
  }

  getCompany() {
    const usrd = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(
        `${ls.get('ws_ip')}/Orgcompany/getAllItemByUser/${usrd}`,
        '',
        config,
      )
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          if (dataResponse.length !== 0) {
            const Arr = [];
            dataResponse.map((obj) =>
              Arr.push({ value: obj.cmp_id, label: obj.company }),
            );
            store.dispatch({ type: 'LOAD_GET_COMPANY', data: Arr });
            if (!this.state.arrCompany) {
              this.setState({
                arrCompany: true,
              });
            }
          }
        } else {
          console.log('no data');
        }
      })
      .catch((error) => {
        // console.log(error);
        console.log('no data');
      });
  }

  getOffice() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/orgoffice/getAll`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.off_id, label: obj.office }),
          );
          store.dispatch({ type: 'LOAD_GET_OFFICE', data: Arr });
          if (!this.state.arrOffice) {
            this.setState({
              arrOffice: true,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getGeofence() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/geofence/getAll`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.ogf_id, label: obj.title }),
          );
          store.dispatch({ type: 'LOAD_GET_GEOFENCE', data: Arr });
          if (!this.state.arrGeofence) {
            this.setState({
              arrGeofence: true,
            });
          }
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getOrganization() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/orgorganization/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.org_id, label: obj.unit_organization }),
          );
          store.dispatch({ type: 'LOAD_GET_ORGANIZATION', data: Arr });
          if (!this.state.arrOrganization) {
            this.setState({
              arrOrganization: true,
            });
          }
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getJobBandData() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/orgjobband/getAll/`, '', config)
      .then((res) => {
        console.log(res);
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.jbd_id, label: obj.job_band }),
          );
          store.dispatch({ type: 'LOAD_GET_JOB_BAND', data: Arr });
          if (!this.state.arrJobBand) {
            this.setState({
              arrJobBand: true,
            });
          }
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getApprovalEmp() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const usrd = ls.get('user_cred').usr_id;
    axios
      .post(`${ls.get('ws_ip')}/employee/getAll`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.emp_id, label: obj.emp_name }),
          );
          store.dispatch({ type: 'LOAD_GET_ALL_EMPLOYEE', data: Arr });
          if (!this.state.empApproval) {
            this.setState({
              empApproval: true,
            });
          }
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  getAttSetting() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/attsetting/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.ats_id, label: obj.ats_name }),
          );
          store.dispatch({ type: 'LOAD_GET_ATT_SHIFT', data: Arr });
          if (!this.state.arrAttShift) {
            this.setState({
              arrAttShift: true,
            });
          }
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getBloodType() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/rtbbloodtype/getall/`, '', config)
      .then((response) => {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map((obj) =>
          Arr.push({ value: obj.rtb_id, label: obj.blood_type }),
        );
        store.dispatch({ type: 'LOAD_GET_BLOOD', data: Arr });
        if (!this.state.blood) {
          this.setState({
            blood: true,
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getReligion() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/rtbreligion/getAll/`, '', config)
      .then((response) => {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map((obj) =>
          Arr.push({ value: obj.rtg_id, label: obj.religion }),
        );
        store.dispatch({ type: 'LOAD_GET_RELIGION', data: Arr });
        if (!this.state.religion) {
          this.setState({
            religion: true,
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getMaritalStatus() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/rtbmaritalstatus/getAll/`, '', config)
      .then((response) => {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map((obj) =>
          Arr.push({ value: obj.rtm_id, label: obj.marital_status }),
        );
        store.dispatch({ type: 'LOAD_GET_MARITAL_STATUS', data: Arr });
        if (!this.state.maritalStatus) {
          this.setState({
            maritalStatus: true,
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getCountry() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/appcountry/getAll/`, '', config)
      .then((response) => {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map((obj) =>
          Arr.push({ value: obj.ctr_id, label: obj.country }),
        );
        store.dispatch({ type: 'LOAD_GET_COUNTRY', data: Arr });
        if (!this.state.country) {
          this.setState({
            country: true,
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getPayComponentGroup() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/paycomponentgroup/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          if (dataResponse.length !== 0) {
            const Arr = [];
            dataResponse.map((obj) =>
              Arr.push({ value: obj.pyg_id, label: obj.component_group }),
            );
            store.dispatch({ type: 'LOAD_GET_PAY_COMPONENT_GROUP', data: Arr });
            if (!this.state.payComponentGroup) {
              this.setState({
                payComponentGroup: true,
              });
            }
          }
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getFamilyRelation() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/rtbfamilyrelation/getAll/`, '', config)
      .then((response) => {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map((obj) =>
          Arr.push({ value: obj.rtf_id, label: obj.family_relation }),
        );
        store.dispatch({ type: 'LOAD_GET_FAMILY_RELATION', data: Arr });
        if (!this.state.familyRelation) {
          this.setState({
            familyRelation: true,
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getTaxStatus() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/rtbtaxstatus/getAll/`, '', config)
      .then((response) => {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map((obj) =>
          Arr.push({ value: obj.rtx_id, label: obj.tax_status }),
        );
        store.dispatch({ type: 'LOAD_GET_TAX_STATUS', data: Arr });
        if (!this.state.taxStatus) {
          this.setState({
            taxStatus: true,
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getBank() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/rtbbank/getAll/`, '', config)
      .then((response) => {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map((obj) =>
          Arr.push({ value: obj.rtk_id, label: obj.bank }),
        );
        store.dispatch({ type: 'LOAD_GET_BANK', data: Arr });
        if (!this.state.bank) {
          this.setState({
            bank: true,
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getPayProrate() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/payproratesetting/getAll/`, '', config)
      .then((response) => {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map((obj) =>
          Arr.push({ value: obj.pys_id, label: obj.prorate_setting }),
        );
        store.dispatch({ type: 'LOAD_GET_PAY_PRORATE', data: Arr });
        if (!this.state.payProrate) {
          this.setState({
            payProrate: true,
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getIdentityType() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/rtbidentitytype/getAll/`, '', config)
      .then((response) => {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map((obj) =>
          Arr.push({ value: obj.rti_id, label: obj.identity_type }),
        );
        store.dispatch({ type: 'LOAD_GET_INDENTITY_TYPE', data: Arr });
        if (!this.state.identityType) {
          this.setState({
            identityType: true,
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getPayComponentGroupRel() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/paycomponentgrouprel/getAllItem/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const dataComponent = [];
          getResponseData.forEach((element) => {
            if (dataComponent[element.pyg_id] === undefined) {
              dataComponent[element.pyg_id] = [];
            }
            dataComponent[element.pyg_id].push({
              pyc_id: element.pyc_id,
              label: element.component,
              pec_value: 0,
            });
          });
          store.dispatch({
            type: 'LOAD_GET_PAY_COMPONENT_GROUP_REL',
            data: Arr,
          });
          if (!this.state.payComponentGroupRel) {
            this.setState({
              payComponentGroupRel: true,
            });
          }
        } else {
          console.log('Error fetch component payroll');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getPayTaxMethod() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/paytaxmethod/getAll/`, '', config)
      .then((response) => {
        const dataResponse = response.data.data;
        const Arr = [];
        dataResponse.map((obj) =>
          Arr.push({ value: obj.ptm_id, label: obj.tax_method }),
        );
        store.dispatch({ type: 'LOAD_GET_PAY_TAX_METHOD', data: Arr });
        if (!this.state.payTaxMethod) {
          this.setState({
            payTaxMethod: true,
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getPayTaxClass() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/paytaxclass/getAllItem/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const array = [];
          getResponseData.forEach((element) => {
            array.push({
              value: element.tcs_id,
              label: element.class_name,
            });
          });
          store.dispatch({ type: 'LOAD_GET_PAY_TAX_CLASS', data: array });
          if (!this.state.payTaxClass) {
            this.setState({
              payTaxClass: true,
            });
          }
        } else {
          console.log('Error fetch component payroll');
        }
        resolve2();
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getItemCurring() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/paycomponent/getAllItemrecurring/0`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data;
          const dataComponent = [];
          getResponseData.forEach((element) => {
            dataComponent.push({
              pyc_id: element.pyc_id,
              label: element.component,
              pec_value: 0,
            });
          });
          store.dispatch({
            type: 'LOAD_GET_ITEM_RECURRING',
            data: dataComponent,
          });
          if (!this.state.itemRecurring) {
            this.setState({
              itemRecurring: true,
            });
          }
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getJobLevel() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/orgjoblevel/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.job_id, label: obj.job_level }),
          );
          store.dispatch({ type: 'LOAD_GET_JOB_LEVEL', data: Arr });
          if (!this.state.jobLevel) {
            this.setState({
              jobLevel: true,
            });
          }
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getActiveEmp() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${ls.get('ws_ip')}/employee/getActiveEmployee`, '', config)
      .then((res) => {
        if (res.data.status === 'ok') {
          const nactive = res.data.total;
          this.setState({ total_active: nactive });
        }
      });
  }

  getReference() {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga: this.state.fga_id,
      reference: this.state.men_id,
    };

    // console.log(data).;
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(`${ls.get('ws_ip')}/menu/getreference/`, formBody, config)
      .then((res) => {
        const resmen = res.data.data;
        const array = [];
        if (resmen?.length > 0) {
          for (let i = 0; i < resmen?.length; i += 1) {
            if (resmen[i].lbl_code === 'LBL.SALARY_ADJUSTMENT') {
              this.setState({
                btnAdjsustment: true,
                menIdAdjustment: resmen[i].men_id,
              });
            }
            if (resmen[i].lbl_code === 'LBL.PROMOTION_MUTATION') {
              this.setState({
                btnPromotion: true,
                menIdPromotion: resmen[i].men_id,
              });
            }
          }
          resmen.map((obj) =>
            array.push({ men_id: obj.men_id, label: obj.lbl_code }),
          );
          const arr2 = resmen.filter(
            (obj) =>
              obj.lbl_code !== 'LBL.SALARY_ADJUSTMENT' &&
              obj.lbl_code !== 'LBL.PROMOTION_MUTATION',
          );
          setTimeout(() => {
            this.setState({
              menu: array,
              menu2: arr2,
            });
          }, 200);
        }
      });
  }

  getJobBand() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${ls.get('ws_ip')}/orgjobband/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.jbd_id, label: obj.job_band }),
          );
          this.setState({ rtborgjobband: Arr });
        } else {
          // this.setState({ button_disabled: false });
          // showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getJobTitle() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${ls.get('ws_ip')}/orgjobname/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.jbn_id, label: obj.job_name }),
          );
          this.setState({ rtborgjobtitle: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getJobGrade() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${ls.get('ws_ip')}/orgjobgrade/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const grade = response.data.data;
          const arr = [];
          grade.map((obj) =>
            arr.push({ value: obj.jgd_id, label: obj.job_grade }),
          );
          setTimeout(() => {
            this.setState({
              rtborgjobgrade: arr,
            });
          }, 500);
        }
      })
      .catch((error) => {
        // this.setState({ button_disabled: false });
      });
  }

  getEmpStatus() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${ls.get('ws_ip')}/rtbemployeementstatus/getAll`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.ems_id, label: obj.ems_employeement_status }),
          );
          this.setState({ rtbemployeementstatus: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getOrgUnit() {
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${ls.get('ws_ip')}/orgorganization/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.org_id, label: obj.unit_organization }),
          );
          this.setState({ rtborgorganization: Arr });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getButton() {
    const men = this.state.men_id;
    const fga = this.state.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
            }
          });
        }
      });
  }

  handleReport() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const bodyParameters = {
      query: this.state.search,
      conds: ls.get('user_cred').usr_id,
      status: this.state.status,
      date_start: this.state.date_start,
      date_end: this.state.date_end,
      sex: this.state.sex,
      job_title: this.state.job_title,
      job_band: this.state.job_band,
      job_grade: this.state.job_grade,
      emp_status: this.state.emp_status,
      org_unit: this.state.org_unit,
      perm_start_date: this.state.perm_start_date,
      perm_end_date: this.state.perm_end_date,
      term_start_date: this.state.term_start_date,
      term_end_date: this.state.term_end_date,
    };

    axios
      .post(`${ls.get('ws_ip')}/Employee/getReportItem`, bodyParameters, config)
      .then((res) => {
        if (res.data.status == 'ok') {
          const { data, header } = res.data;
          const array = [];
          data.map((obj, index) => array.push({ No: index + 1, ...obj }));
          this.setState({
            dataexcel: array,
            headerexcel: header,
            trigger: !this.state.trigger,
          });
          setTimeout(() => {
            showNotifications('Success', 'Load Success', 'success');
            this.inputElement.click();
          }, 500);
        }
      });
  }

  paginationNumber() {
    const total_pagination = this.state.total / this.state.limit;
    this.setState({
      total_pagination: Math.ceil(total_pagination),
    });

    const page_start = Number.parseInt(this.state.offset, 10) + 1;
    const page_end =
      Number.parseInt(this.state.limit, 10) +
      Number.parseInt(this.state.offset, 10);
    this.setState({
      label_idx_start: page_start,
      label_idx_end: page_end > this.state.total ? this.state.total : page_end,
    });
  }

  changePage(event) {
    this.setState(
      {
        limit: event.target.value,
      },
      () => {
        setTimeout(() => {
          this.reload();
        }, 50);
      },
    );
  }

  filterDateStart(event) {
    this.setState({ date_start: moment(event).format('YYYY-MM-DD') });
    if (moment(event).format('YYYY-MM-DD') > this.state.date_end) return false;

    this.timeout = setTimeout(() => {
      this.reload();
    }, 300);
  }

  filterDateEnd(event) {
    this.setState({ date_end: moment(event).format('YYYY-MM-DD') });
    if (this.state.date_start > moment(event).format('YYYY-MM-DD'))
      return false;

    this.timeout = setTimeout(() => {
      this.reload();
    }, 300);
  }

  filterPermDateStart(event) {
    this.setState({ perm_start_date: moment(event).format('YYYY-MM-DD') });
    // this.timeout = setTimeout(() => {
    //   this.reload();
    // }, 300);
  }

  filterPermDateEnd(event) {
    this.setState({ perm_end_date: moment(event).format('YYYY-MM-DD') });
    // this.timeout = setTimeout(() => {
    //   this.reload();
    // }, 300);
  }

  filterTermDateStart(event) {
    this.setState({ term_start_date: moment(event).format('YYYY-MM-DD') });

    // this.timeout = setTimeout(() => {
    //   this.reload();
    // }, 300);
  }

  filterTermDateEnd(event) {
    this.setState({ term_end_date: moment(event).format('YYYY-MM-DD') });

    // this.timeout = setTimeout(() => {
    //   this.reload();
    // }, 300);
  }

  filterStatus(val) {
    if (val == null) {
      this.setState({ status: '' });
    } else {
      this.setState({ status: val.toString() });
    }
    this.timeout = setTimeout(() => {
      this.reload();
    }, 300);
  }

  filterSex(val) {
    if (val == null) {
      this.setState({ sex: '' });
    } else {
      this.setState({ sex: val.toString() });
    }
  }

  filterOrgUnit(val) {
    if (val == null) {
      this.setState({ org_unit: '' });
    } else {
      this.setState({ org_unit: val.toString() });
    }
  }

  filterJobTitle(val) {
    if (val == null) {
      this.setState({ job_title: '' });
    } else {
      this.setState({ job_title: val.toString() });
    }
  }

  filterJobBand(val) {
    if (val == null) {
      this.setState({ job_band: '' });
    } else {
      this.setState({ job_band: val.toString() });
    }
  }

  filterGrade(val) {
    if (val == null) {
      this.setState({ job_grade: '' });
    } else {
      this.setState({ job_grade: val.toString() });
    }
  }

  filterEmpStatus(val) {
    if (val == null) {
      this.setState({ emp_status: '' });
    } else {
      this.setState({ emp_status: val.toString() });
    }
  }

  search(event) {
    this.setState({ search: event.target.value });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.reload();
    }, 300);
  }

  previous() {
    let state_offset = this.state.offset - this.state.limit;
    const { t } = this.props;
    this.setState({ modal_show: true });
    const nextState = this;
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
      conds: ls.get('user_cred').usr_id,
      status: this.state.status,
      date_start: this.state.date_start,
      date_end: this.state.date_end,
      sex: this.state.sex,
      job_title: this.state.job_title,
      job_band: this.state.job_band,
      job_grade: this.state.job_grade,
      emp_status: this.state.emp_status,
      org_unit: this.state.org_unit,
      perm_start_date: this.state.perm_start_date,
      perm_end_date: this.state.perm_end_date,
      term_start_date: this.state.term_start_date,
      term_end_date: this.state.term_end_date,
    };
    state_offset = state_offset < 1 ? 0 : state_offset;
    axios
      .post(
        `${ws.ws.ip}/Employee/getAllItem/${this.state.limit}/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          // eslint-disable-next-line no-underscore-dangle
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            let path_photo;
            if (
              response.data.data[i].path_photo === null ||
              response.data.data[i].path_photo === '' ||
              response.data.data[i].path_photo === 'undefined'
            ) {
              path_photo = `${`${ls.get(
                'ws_ip',
              )}/public/uploads/user-images.png`}`;
            } else {
              path_photo = `${`${ls.get('ws_ip')}/public/uploads${
                response.data.data[i].path_photo
              }`}`;
            }
            rows.push({
              no: no_index,
              emp_id: response.data.data[i].emp_id,
              emp_name: response.data.data[i].emp_name,
              emp_code: response.data.data[i].emp_code,
              position_name: response.data.data[i].position_name,
              job_band: response.data.data[i].job_band,
              join_date: response.data.data[i].join_date,
              path_photo: path_photo,
              status:
                response.data.data[i].is_active === '0'
                  ? t('LBL.ACTIVE')
                  : t('LBL.NOT_ACTIVE'),
            });
          }

          // simpan state limit offset
          nextState.setState({
            rows: rows,
            total: response.data.total,
            page: this.state.page - 1,
            offset: state_offset,
            modal_show: false,
            disable_preview: state_offset < 1 ? 'disabled' : '',
            disable_next: response.data.total > no_index ? '' : 'disabled',
          });

          this.paginationNumber();
        } else {
          // sembunyikan modal loading data jika data kosong
          this.setState({ modal_show: false });
        }
      })
      .catch((error) => {
        // sembunyikan modal loading data
        this.setState({ modal_show: false });
      });
  }

  reload() {
    const { t } = this.props;
    const reloadState = this;
    let state_offset = this.state.offset;
    if (!requestCompleted) {
      abortController.abort();
      abortController = new AbortController();
    }
    requestCompleted = false;
    this.setState({ modal_show: true });
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
      signal: abortController.signal,
    };
    const bodyParameters = {
      query: this.state.search,
      conds: ls.get('user_cred').usr_id,
      conds1: '1',
      status: this.state.status,
      date_start: this.state.date_start,
      date_end: this.state.date_end,
      sex: this.state.sex,
      job_title: this.state.job_title,
      job_band: this.state.job_band,
      job_grade: this.state.job_grade,
      emp_status: this.state.emp_status,
      org_unit: this.state.org_unit,
      perm_start_date: this.state.perm_start_date,
      perm_end_date: this.state.perm_end_date,
      term_start_date: this.state.term_start_date,
      term_end_date: this.state.term_end_date,
    };
    state_offset = state_offset < 1 ? 0 : state_offset;
    axios
      .post(
        `${ws.ws.ip}/Employee/getAllItem/${this.state.limit}/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        const numPage = typeof item !== 'undefined' ? this.state.page : 2;
        const nulPage = (response.data.total / state_offset) % 1;
        const numPages = nulPage === 0 ? numPage - 1 : numPage;
        this.setState({ modal_show: false });
        requestCompleted = true;

        if (response?.data?.data?.length === 0) {
          if (nulPage === 0) {
            axios
              .post(
                `${ws.ws.ip}/Employee/getAllItem/${this.state.limit}/${state_offset}`,
                bodyParameters,
                config,
              )
              .then((res) => {
                if (res.data.data.length > 0) {
                  this.setState({ rowHead: res.data.data.length + 1 });
                  let no_index = state_offset - this.state.limit;
                  let path_photo;

                  for (let i = 0; i < res.data.data.length; i += 1) {
                    if (
                      response.data.data[i].path_photo === null ||
                      response.data.data[i].path_photo === '' ||
                      response.data.data[i].path_photo === 'undefined'
                    ) {
                      path_photo = `${`${ls.get(
                        'ws_ip',
                      )}/public/uploads/user-images.png`}`;
                    } else {
                      path_photo = `${`${ls.get('ws_ip')}/public/uploads${
                        response.data.data[i].path_photo
                      }`}`;
                    }
                    no_index += 1;
                    rows.push({
                      no: no_index,
                      emp_id: response.data.data[i].emp_id,
                      emp_name: response.data.data[i].emp_name,
                      emp_code: response.data.data[i].emp_code,
                      position_name: response.data.data[i].position_name,
                      job_band: response.data.data[i].job_band,
                      join_date: response.data.data[i].join_date,
                      path_photo: path_photo,
                      status:
                        response.data.data[i].is_active === '0'
                          ? t('LBL.ACTIVE')
                          : t('LBL.NOT_ACTIVE'),
                    });
                  }
                  reloadState.setState({
                    rows: rows,
                    total: res.data.total,
                    page: numPages,
                    offset: state_offset - this.state.limit,
                    modal_show: false,
                    disable_next: no_index >= res.data.total ? 'disabled' : '',
                    disable_preview:
                      state_offset - this.state.limit < 1 ? 'disabled' : '',
                  });
                  this.paginationNumber();
                }
              });
          } else {
            reloadState.setState({
              rows: [],
              total: 0,
              disable_next: 'disabled',
              disable_preview: 'disabled',
              page: 1,
              total_pagination: 0,
              current_pagination: 0,
            });
            this.paginationNumber();
          }
        }
        if (response?.data?.data?.length > 0) {
          this.setState({ rowHead: response.data.data.length + 1 });
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            let path_photo;
            if (
              response.data.data[i].path_photo === null ||
              response.data.data[i].path_photo === '' ||
              response.data.data[i].path_photo === 'undefined'
            ) {
              path_photo = `${`${ls.get(
                'ws_ip',
              )}/public/uploads/user-default-photo.png`}`;
            } else {
              path_photo = `${`${ls.get('ws_ip')}/public/uploads${
                response.data.data[i].path_photo
              }`}`;
            }
            rows.push({
              no: no_index,
              emp_id: response.data.data[i].emp_id,
              emp_name: response.data.data[i].emp_name,
              emp_code: response.data.data[i].emp_code,
              position_name: response.data.data[i].position_name,
              job_band: response.data.data[i].job_band,
              join_date: response.data.data[i].join_date,
              path_photo: path_photo,
              status:
                response.data.data[i].is_active === '0'
                  ? t('LBL.ACTIVE')
                  : t('LBL.NOT_ACTIVE'),
            });
          }
          reloadState.setState({
            rows: rows,
            total: response.data.total,
            page: numPage,
            offset: state_offset,
            modal_show: false,
            disable_next: no_index >= response.data.total ? 'disabled' : '',
            disable_preview: state_offset < 1 ? 'disabled' : '',
          });
          this.paginationNumber();
        }
      });
  }

  next() {
    const { t } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    const state_offset = (this.state.page - 1) * this.state.limit;
    this.setState({ modal_show: true });
    // eslint-disable-next-line no-underscore-dangle
    const nextState = this;
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
      conds: ls.get('user_cred').usr_id,
      status: this.state.status,
      date_start: this.state.date_start,
      date_end: this.state.date_end,
      sex: this.state.sex,
      job_title: this.state.job_title,
      job_band: this.state.job_band,
      job_grade: this.state.job_grade,
      emp_status: this.state.emp_status,
      org_unit: this.state.org_unit,
      perm_start_date: this.state.perm_start_date,
      perm_end_date: this.state.perm_end_date,
      term_start_date: this.state.term_start_date,
      term_end_date: this.state.term_end_date,
    };
    axios
      .post(
        `${ws.ws.ip}/Employee/getAllItem/${this.state.limit}/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        // prompt('data', JSON.stringify(response));
        if (response.data.data.length > 0) {
          // eslint-disable-next-line no-underscore-dangle
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            let path_photo;
            if (
              response.data.data[i].path_photo === null ||
              response.data.data[i].path_photo === '' ||
              response.data.data[i].path_photo === 'undefined'
            ) {
              path_photo = `${`${ls.get(
                'ws_ip',
              )}/public/uploads/user-images.png`}`;
            } else {
              path_photo = `${`${ls.get('ws_ip')}/public/uploads${
                response.data.data[i].path_photo
              }`}`;
            }
            rows.push({
              no: no_index,
              emp_id: response.data.data[i].emp_id,
              emp_name: response.data.data[i].emp_name,
              emp_code: response.data.data[i].emp_code,
              position_name: response.data.data[i].position_name,
              job_band: response.data.data[i].job_band,
              join_date: response.data.data[i].join_date,
              path_photo: path_photo,
              status:
                response.data.data[i].is_active === '0'
                  ? t('LBL.ACTIVE')
                  : t('LBL.NOT_ACTIVE'),
            });
          }
          nextState.setState({
            rows: rows,
            total: response.data.total,
            page: this.state.page + 1,
            offset: state_offset,
            modal_show: false,
            disable_preview: state_offset < 1 ? 'disabled' : '',
            disable_next: no_index >= response.data.total ? 'disabled' : '',
          });

          this.paginationNumber();
        }
      })
      .catch((error) => {
        this.setState({ modal_show: false });
      });
  }

  goto(e) {
    const { t } = this.props;
    e.preventDefault();
    const page = parseInt(e.target.innerHTML, 10);
    // eslint-disable-next-line no-underscore-dangle
    const state_offset = (page - 1) * this.state.limit;
    this.setState({ modal_show: true });
    // eslint-disable-next-line no-underscore-dangle
    const nextState = this;
    const rows = [];
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const bodyParameters = {
      query: this.state.search,
      conds: ls.get('user_cred').usr_id,
      conds1: '1',
      status: this.state.status,
      date_start: this.state.date_start,
      date_end: this.state.date_end,
      sex: this.state.sex,
      job_title: this.state.job_title,
      job_band: this.state.job_band,
      job_grade: this.state.job_grade,
      emp_status: this.state.emp_status,
      org_unit: this.state.org_unit,
      perm_start_date: this.state.perm_start_date,
      perm_end_date: this.state.perm_end_date,
      term_start_date: this.state.term_start_date,
      term_end_date: this.state.term_end_date,
    };
    axios
      .post(
        `${ws.ws.ip}/Employee/getAllItem/${this.state.limit}/${state_offset}`,
        bodyParameters,
        config,
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          // eslint-disable-next-line no-underscore-dangle
          let no_index = state_offset;
          for (let i = 0; i < response.data.data.length; i += 1) {
            no_index += 1;
            let path_photo;
            if (
              response.data.data[i].path_photo === null ||
              response.data.data[i].path_photo === '' ||
              response.data.data[i].path_photo === 'undefined'
            ) {
              path_photo = `${`${ls.get(
                'ws_ip',
              )}/public/uploads/user-images.png`}`;
            } else {
              path_photo = `${`${ls.get('ws_ip')}/public/uploads${
                response.data.data[i].path_photo
              }`}`;
            }
            rows.push({
              no: no_index,
              emp_id: response.data.data[i].emp_id,
              emp_name: response.data.data[i].emp_name,
              emp_code: response.data.data[i].emp_code,
              position_name: response.data.data[i].position_name,
              job_band: response.data.data[i].job_band,
              join_date: response.data.data[i].join_date,
              path_photo: path_photo,
              status:
                response.data.data[i].is_active === '0'
                  ? t('LBL.ACTIVE')
                  : t('LBL.NOT_ACTIVE'),
            });
          }
          nextState.setState({
            rows,
            total: response.data.total,
            page: page + 1,
            offset: state_offset,
            modal_show: false,
            disable_preview: state_offset < 1 ? 'disabled' : '',
            disable_next: no_index >= response.data.total ? 'disabled' : '',
          });

          this.paginationNumber();
        }
      })
      .catch((error) => {
        this.setState({ modal_show: false });
      });
  }

  FieldSidebar() {
    const {
      sex,
      org_unit,
      job_title,
      job_band,
      job_grade,
      emp_status,
      perm_start_date,
      perm_end_date,
      term_start_date,
      term_end_date,
    } = this.state;

    const toggleOffcanvas = () => {
      // const { employeeFilter } = store.getState();
      // console.log(employeeFilter);
      this.setState((prev) => ({ isOpen: !prev.isOpen }));
    };

    const searchFilter = () => {
      if (this.state.perm_start_date > this.state.perm_end_date) return false;

      if (
        (!this.state.perm_start_date && this.state.perm_end_date) ||
        (this.state.perm_start_date && !this.state.perm_end_date)
      )
        return false;

      this.setState({ adv_filter: true });
      toggleOffcanvas();
      this.reload();
    };

    const clearAdvFilter = () => {
      this.setState({
        sex: '',
        org_unit: '',
        job_title: '',
        job_band: '',
        job_grade: '',
        emp_status: '',
        perm_start_date: null,
        perm_end_date: null,
        term_start_date: null,
        term_end_date: null,
        dateValue: '',
        isClear: false,
      });
    };

    return (
      <>
        <div
          className="offcanvas fade show"
          style={{
            // width: '348px',
            overflow: 'hidden',
          }}
        >
          <div className="offcanvas-dialog" style={{ width: '348px' }}>
            <div className="offcanvas-header">
              <span className="off-title">Advance Filter</span>
              <button className="close" onClick={toggleOffcanvas}>
                &times;
              </button>
            </div>
            <div
              className="offcanvas-body-adv-filter"
              style={{
                overflowY: 'auto',
              }}
            >
              <form method="post">
                <div className="form__form-group">
                  <span className="form__form-group-label">Gender: </span>
                  <div className="form__form-group-field">
                    <Field
                      name="sex"
                      component={renderSelectField}
                      options={[
                        { value: 1, label: 'Male' },
                        { value: 2, label: 'Female' },
                      ]}
                      props={{ disableTouched: true, value: sex }}
                      onChange={(status) => this.filterSex(status.value)}
                      placeholder="Gender"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Organization Unit:{' '}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="org_unit"
                      component={renderSelectField}
                      options={this.state.rtborgorganization}
                      props={{ disableTouched: true, value: org_unit }}
                      onChange={(status) => this.filterOrgUnit(status.value)}
                      placeholder="Organization Unit"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Job Title: </span>
                  <div className="form__form-group-field">
                    <Field
                      className="custom-select-fiel"
                      name="statu"
                      component={renderSelectField}
                      options={this.state.rtborgjobtitle}
                      props={{ disableTouched: true, value: job_title }}
                      onChange={(status) => this.filterJobTitle(status.value)}
                      placeholder="Status Employee"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Job Band: </span>
                  <div className="form__form-group-field">
                    <Field
                      className="custom-select-fiel"
                      name="statu"
                      component={renderSelectField}
                      options={this.state.rtborgjobband}
                      props={{ disableTouched: true, value: job_band }}
                      onChange={(status) => this.filterJobBand(status.value)}
                      placeholder="Status Employee"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Grade: </span>
                  <div className="form__form-group-field">
                    <Field
                      className="custom-select-fiel"
                      name="statu"
                      component={renderSelectField}
                      options={this.state.rtborgjobgrade}
                      props={{ disableTouched: true, value: job_grade }}
                      onChange={(status) => this.filterGrade(status.value)}
                      placeholder="Status Employee"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Employement Status:{' '}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      className="custom-select-fiel"
                      name="statu"
                      component={renderSelectField}
                      options={this.state.rtbemployeementstatus}
                      props={{ disableTouched: true, value: emp_status }}
                      onChange={(status) => this.filterEmpStatus(status.value)}
                      placeholder="Status Employee"
                    />
                  </div>
                </div>
                <div className="form">
                  <div
                    className="form__form-group mb-3"
                    style={{ marginRight: '10px' }}
                  >
                    <span className="form__form-group-label">
                      Permanent Date:{' '}
                    </span>
                    <div className="form__form-group-field">
                      <div className="date-picker date-picker--interval">
                        {this.state.perm_start_date >
                          this.state.perm_end_date && (
                          <span
                            class="form__form-group-error"
                            style={{
                              background: '#ffbcbc',
                              position: 'absolute',
                              marginTop: '-28px',
                              padding: '5px 10px',
                              border: '3px',
                            }}
                          >
                            End date shouldn’t less than Start Date
                          </span>
                        )}
                        {((!this.state.perm_start_date &&
                          this.state.perm_end_date) ||
                          (this.state.perm_start_date &&
                            !this.state.perm_end_date)) && (
                          <span
                            class="form__form-group-error"
                            style={{
                              background: '#ffbcbc',
                              position: 'absolute',
                              marginTop: '-28px',
                              padding: '5px 10px',
                              border: '3px',
                            }}
                          >
                            Range permanent date shouldn't be empty
                          </span>
                        )}
                        <Col md={5} xs={5} style={{ padding: '0' }}>
                          <div>
                            <Field
                              name="perm_start_date"
                              component={renderDatePickerField}
                              placeholderText="Start Date"
                              props={{
                                disableTouched: false,
                                value: perm_start_date,
                              }}
                              onChange={(date) =>
                                this.filterPermDateStart(date)
                              }
                            />
                          </div>
                        </Col>
                        <div style={{ fontSize: '15px', paddingTop: '2px' }}>
                          <span>
                            {/* <FontAwesomeIcon icon={faArrowRight} /> */}
                            <MinusIcon className="date-picker__svg" />
                          </span>
                        </div>
                        <Col md={5} xs={5} style={{ padding: '0' }}>
                          <Field
                            className="right"
                            name="perm_end_date"
                            component={renderDatePickerField}
                            placeholderText="End Date"
                            props={{
                              disableTouched: false,
                              value: perm_end_date,
                            }}
                            onChange={(date) => this.filterPermDateEnd(date)}
                          />
                        </Col>
                        <div
                          style={{
                            padding: '6px',
                            height: '32px',
                            background: '#eaeaea',
                            border: '1px solid #eaeaea',
                          }}
                        >
                          <CalendarBlankIcon color="black" size="18" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="form__form-group mb-3"
                    style={{ marginRight: '10px' }}
                  >
                    <span className="form__form-group-label">
                      Terminate Date:{' '}
                    </span>
                    <div className="form__form-group-field">
                      <div className="date-picker date-picker--interval">
                        {this.state.term_start_date >
                          this.state.term_end_date && (
                          <span
                            class="form__form-group-error"
                            style={{
                              background: '#ffbcbc',
                              position: 'absolute',
                              marginTop: '-28px',
                              padding: '5px 10px',
                              border: '3px',
                            }}
                          >
                            End date shouldn’t less than Start Date
                          </span>
                        )}
                        {((!this.state.term_start_date &&
                          this.state.term_end_date) ||
                          (this.state.term_start_date &&
                            !this.state.term_end_date)) && (
                          <span
                            class="form__form-group-error"
                            style={{
                              background: '#ffbcbc',
                              position: 'absolute',
                              marginTop: '-28px',
                              padding: '5px 10px',
                              border: '3px',
                            }}
                          >
                            Range terminate date shouldn't be empty
                          </span>
                        )}
                        <Col md={5} xs={5} style={{ padding: '0' }}>
                          <Field
                            name="term_start_date"
                            component={renderDatePickerField}
                            placeholderText="Start Date"
                            props={{
                              disableTouched: false,
                              value: term_start_date,
                            }}
                            onChange={(date) =>
                              this.setState({
                                term_start_date: moment(date).format(
                                  'YYYY-MM-DD',
                                ),
                              })
                            }
                          />
                        </Col>
                        <div style={{ fontSize: '15px', paddingTop: '2px' }}>
                          <span>
                            {/* <FontAwesomeIcon icon={faArrowRight} /> */}
                            <MinusIcon className="date-picker__svg" />
                          </span>
                        </div>
                        <Col md={5} xs={5} style={{ padding: '0' }}>
                          <Field
                            className="right"
                            name="term_end_date"
                            component={renderDatePickerField}
                            placeholderText="End Date"
                            props={{
                              disableTouched: false,
                              value: term_end_date,
                            }}
                            onChange={(date) =>
                              this.setState({
                                term_end_date: moment(date).format(
                                  'YYYY-MM-DD',
                                ),
                              })
                            }
                          />
                        </Col>
                        <div
                          style={{
                            padding: '6px',
                            height: '32px',
                            background: '#eaeaea',
                            border: '1px solid #eaeaea',
                          }}
                        >
                          <CalendarBlankIcon color="black" size="18" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="offcanvas-footer">
              <div className="offcanvas-footer-btn-group d-flex w-100">
                <button
                  type="button"
                  onClick={clearAdvFilter}
                  className={`btn btn-secondary p-1 w-100 ${!this.state
                    .isClear && `disabled`}`}
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="btn btn-primary p-1 w-100"
                  onClick={searchFilter}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  createButtonPagination() {
    const total_button = 5;
    const child_pagination = [];
    const state_page = this.state.page - 1;
    // set minimum index
    if (
      this.state.current_pagination < 1 ||
      state_page >= this.state.current_pagination
    ) {
      this.setState({
        current_pagination: state_page + total_button,
      });
    }
    // set maximum index
    if (state_page < this.state.current_pagination - total_button) {
      this.setState({
        current_pagination: this.state.current_pagination - total_button,
      });
    }

    for (let i = 1; i <= this.state.total_pagination; i++) {
      let selected = '';
      if (state_page === i) {
        selected = 'active';
      }
      if (
        i >= this.state.current_pagination - total_button - 1 &&
        i <= this.state.current_pagination
      ) {
        child_pagination.push(
          <li className={`page-item ${selected}`}>
            <a onClick={this.goto} className="page-link" href="#">
              {i}
            </a>
          </li>,
        );
      }
    }
    return child_pagination;
  }

  // createButtonPagination() {
  //   const total_button = 5;
  //   const child_pagination = [];
  //   const state_page = this.state.page - 1;
  //   // set minimum index
  //   if ((this.state.current_pagination < 1) || (state_page >= this.state.current_pagination)) {
  //     this.setState({
  //       current_pagination: state_page + total_button,
  //     });
  //   }
  //   // set maximum index
  //   if (state_page < (this.state.current_pagination - total_button)) {
  //     this.setState({
  //       current_pagination: this.state.current_pagination - total_button,
  //     });
  //   }

  //   for (let i = 1; i <= this.state.total_pagination; i++) {
  //     let selected = '';
  //     if (state_page === i) {
  //       selected = 'active';
  //     }
  //     if ((i >= ((this.state.current_pagination - total_button) - 1)) && (i <= this.state.current_pagination)) {
  //       child_pagination.push(<li class={`page-item ${selected}`}><a onClick={this.goto} class="page-link" href="#">{i}</a></li>);
  //     }
  //   }
  //   return child_pagination;
  // }

  render() {
    // if (this.state.redirect) {
    //   return (<Redirect to={{
    //     pathname: '/administration/editemployee',
    //     state: { usr_id: this.state.parm_id_usr }
    //   }}
    //   />);
    // }
    const { t } = this.props;
    const {
      addcond,
      editcond,
      menu,
      menu2,
      status,
      sex,
      org_unit,
      job_title,
      job_band,
      job_grade,
      emp_status,
      perm_start_date,
      perm_end_date,
      term_start_date,
      term_end_date,
    } = this.state;
    const toggleOffcanvas = () => {
      this.setState({ isOpen: true });
    };
    let filter = false;
    if (
      sex ||
      org_unit ||
      job_title ||
      job_band ||
      job_grade ||
      emp_status ||
      perm_start_date !== null ||
      perm_end_date !== null ||
      term_start_date !== null ||
      term_end_date !== null
    ) {
      filter = true;
    }
    return (
      <Col md={12} xs={12} lg={12} xl={12}>
        <Card>
          <CardBody style={{ padding: '20px 10px 30px 10px' }}>
            <div class="container-fluid">
              <div class="wrapper">
                <div class="row mb-2">
                  <div class="col-md-6">
                    <h3 class="page-title">{t('MEN.EMPLOYEE_LIST')}</h3>
                    <h3 class="page-subhead subhead">
                      {t('MEN.EMPLOYEE_DATA_OVERVIEW')}
                    </h3>
                  </div>
                  <div class="col-md-6">
                    {/* <button class="btn btn-primary" style={{ 'float': 'right', 'margin-bottom': '20px' }}>Add Employee</button> */}
                  </div>
                </div>
                <div class="row">
                  {/* <div class="col-md-3">
                    <div className="input-group mb-3">
                      <input value={this.state.search} onChange={this.search} type="text" className="form-control" placeholder="Emp Name/Code/Position/Job Band" />
                      <div className="input-group-append">
                        <span className="input-group-text" ><FontAwesomeIcon icon={faSearch} /></span>
                      </div>
                    </div>
                    </div> */}
                  <div class="col-md-3 form">
                    <div
                      className="form__form-group mb-3"
                      style={{ borderRadius: '0.25rem' }}
                    >
                      <div className="form__form-group-field">
                        <input
                          value={this.state.search}
                          onChange={this.search}
                          type="text"
                          className="form__form-group-input-wrap form__form-group-input-wrap--error-above"
                          placeholder={t('LBL.SEARCH_EMP')}
                        />
                        <div className="form__form-group-icon">
                          <span class="pl-1 pr-1">
                            <FontAwesomeIcon color="black" icon={faSearch} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 form">
                    {/* <input type="hidden" name="" class="form-control" /> */}
                    <div
                      className="form__form-group mb-3"
                      style={{ marginRight: '24px' }}
                    >
                      {this.state.date_start > this.state.date_end && (
                        <span
                          class="form__form-group-error"
                          style={{
                            background: '#ffbcbc',
                            position: 'absolute',
                            marginTop: '-28px',
                            padding: '5px 10px',
                            border: '3px',
                          }}
                        >
                          End date shouldn’t less than Start Date
                        </span>
                      )}
                      <div className="form__form-group-field">
                        <div className="date-picker date-picker--interval">
                          <Col md={5} xs={5} style={{ padding: '0' }}>
                            <Field
                              name="date"
                              component={renderDatePickerField}
                              placeholderText={t('LBL.START_JOIN_DATE')}
                              onChange={(date) => this.filterDateStart(date)}
                            />
                          </Col>
                          <div style={{ fontSize: '15px', paddingTop: '2px' }}>
                            <span>
                              {/* <FontAwesomeIcon icon={faArrowRight} /> */}
                              <MinusIcon className="date-picker__svg" />
                            </span>
                          </div>
                          <Col md={5} xs={5} style={{ padding: '0' }}>
                            <Field
                              name="date"
                              component={renderDatePickerField}
                              placeholderText={t('LBL.END_JOIN_DATE')}
                              onChange={(date) => this.filterDateEnd(date)}
                            />
                          </Col>
                          <div
                            style={{
                              padding: '6px',
                              height: '32px',
                              background: '#eaeaea',
                              border: '1px solid #eaeaea',
                            }}
                          >
                            <CalendarBlankIcon color="black" size="18" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Col md={2} xs={12} class="mb-3">
                    {/* {status === '0' ? <button type="button" class="btn btn-primary btn-sm" onClick={() => this.filterStatus('0')} style={{ marginRight: '0' }}>{t('LBL.ACTIVE')}</button> : <button type="button" class="btn btn-outline-secondary btn-sm" onClick={() => this.filterStatus('0')} style={{ marginRight: '0' }}>{t('LBL.ACTIVE')}</button>}
                  {status === '1' ? <button type="button" class="btn btn-primary btn-sm" onClick={() => this.filterStatus('1')} style={{ marginRight: '0' }}>{t('LBL.NOT_ACTIVE')}</button> : <button type="button" class="btn btn-outline-secondary btn-sm" onClick={() => this.filterStatus('1')} style={{ marginRight: '0' }}>{t('LBL.NOT_ACTIVE')}</button>}
                  {status === '' ? <button type="button" class="btn btn-primary btn-sm" onClick={() => this.filterStatus('')} style={{ marginRight: '0' }}>{t('LBL.ALL')}</button> : <button type="button" class="btn btn-outline-secondary btn-sm" onClick={() => this.filterStatus('')} style={{ marginRight: '0' }}>{t('LBL.ALL')}</button>} */}
                    <div className="form__form-group form-select-emp form">
                      <div
                        className="form__form-group-field"
                        style={{
                          margin: '0',
                          maxHeight: '32px !important',
                        }}
                      >
                        <Field
                          className="custom-select-field"
                          name="status"
                          component={renderSelectField}
                          type="textemployee"
                          props={{ disableTouched: true, value: status }}
                          options={[
                            { value: null, label: 'All' },
                            { value: 0, label: 'Active' },
                            { value: 1, label: 'Non Active' },
                          ]}
                          onChange={(status) => this.filterStatus(status.value)}
                          placeholder="Status Employee"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={1} xs={12} class="mb-3">
                    <button
                      style={{
                        width: '90px',
                        maxHeight: '54%',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                      }}
                      type="button"
                      className="btn btn-secondary"
                      onClick={toggleOffcanvas}
                    >
                      <span class="pr-2">
                        <FontAwesomeIcon color="gray" icon={faFilter} />
                      </span>
                      Filter
                    </button>

                    {filter && (
                      <span
                        class="badge badge-primary"
                        style={{
                          width: '15px',
                          height: '15px',
                          borderRadius: '10px',
                          padding: '0px 5px',
                          position: 'absolute',
                          top: '-0.2rem',
                          left: '6.5rem',
                        }}
                      >
                        &nbsp;
                      </span>
                    )}
                  </Col>
                  <Col md={1} xs={12} class="mb-3">
                    <button
                      style={{
                        width: '65px',
                        maxHeight: '54%',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      type="button"
                      className="btn btn-secondary mg-export"
                      onClick={this.handleReport}
                      id="btn-export"
                    >
                      <span class="pr-2">
                        <FontAwesomeIcon color="gray" icon={faDownload} />
                      </span>
                    </button>
                    <UncontrolledTooltip placement="top" target={`btn-export`}>
                      {t('LBL.EXPORT')}
                    </UncontrolledTooltip>
                  </Col>
                  <div class="col-md-2">
                    <div className="btn_action_table_navbar">
                      <ButtonToolbar>
                        {this.state.maxuser >= this.state.total_active && (
                          <Link
                            className="btn btn-primary btn_table_navbar"
                            // to="/administration/addemployee"
                            to={{
                              pathname: '/administration/addemployee',
                              state: {
                                statusEdit: false,
                                fga_id: this.state.fga_id,
                                men_employee: this.state.men_id,
                              },
                            }}
                            style={{ display: addcond === '1' ? '' : 'none' }}
                          >
                            <FontAwesomeIcon icon={faUserPlus} /> {t('FRM.ADD')}{' '}
                            {t('LBL.EMPLOYEE')}
                          </Link>
                        )}
                        {this.state.maxuser < this.state.total_active && (
                          <Link
                            className="btn btn-primary btn_table_navbar"
                            // to="/administration/addemployee"
                            to={{
                              pathname: '/account/package',
                              state: {
                                statusEdit: false,
                                fga_id: this.state.fga_id,
                                men_employee: this.state.men_id,
                              },
                            }}
                          >
                            <FontAwesomeIcon icon={faUserPlus} /> {t('FRM.ADD')}{' '}
                            {t('LBL.EMPLOYEE')}
                          </Link>
                        )}
                      </ButtonToolbar>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table id="myTable" class="table data-grid-tbl responsive">
                    <thead>
                      {this.heads.map((data) => (
                        <th>{data}</th>
                      ))}
                    </thead>
                    <tbody>
                      {this.state.rows.map((data) => (
                        <tr>
                          <td width="3%">{data.no}</td>
                          <td width="20%">
                            <div
                              className="user-photo"
                              style={{ width: '40px' }}
                            >
                              <img
                                class="profile-pic"
                                src={data.path_photo}
                                alt=""
                              />
                            </div>{' '}
                            <span
                              className="lbl_emp_name"
                              style={{ paddingTop: '7%' }}
                            >
                              {data.emp_name}
                            </span>
                          </td>
                          <td width="10%">{data.emp_code}</td>
                          <td>{data.position_name}</td>
                          <td>{data.job_band}</td>
                          <td width="8%">{data.join_date}</td>
                          <td>{data.status}</td>
                          <td width="17%">
                            <Link
                              className="link_action_tbl"
                              id={`edit${data.no}`}
                              to={{
                                pathname: '/administration/editemployee',
                                state: {
                                  usr_id: data.emp_id,
                                  statusEdit: true,
                                  fga_id: this.state.fga_id,
                                  men_employee: this.state.men_id,
                                },
                              }}
                              style={{
                                'margin-bottom': '20px',
                                'margin-right': '10px',
                                display: editcond === '1' ? '' : 'none',
                              }}
                            >
                              <button
                                type="button"
                                class="btn btn-sm btn-outline-primary btn-data-table"
                              >
                                <i class="fas fa-pencil-alt" />
                              </button>
                            </Link>
                            {this.state.btnAdjsustment && (
                              <Link
                                className="link_action_tbl"
                                id={`adjustment${data.no}`}
                                to={{
                                  pathname: '/administration/adjustment',
                                  state: {
                                    usrid: data.emp_id,
                                    code: data.emp_code,
                                    name: data.emp_name,
                                    photo: data.path_photo,
                                    men: this.state.menIdAdjustment,
                                    fga_id: this.state.fga_id,
                                    men_employee: this.state.men_id,
                                  },
                                }}
                                style={{
                                  'margin-bottom': '20px',
                                  'margin-right': '10px',
                                }}
                              >
                                <button
                                  type="button"
                                  class="btn btn-sm btn-outline-primary btn-data-table"
                                >
                                  <i class="fas fa-money-check-alt" />
                                </button>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`adjustment${data.no}`}
                                >
                                  {t('LBL.SALARY_ADJUSTMENT')}
                                </UncontrolledTooltip>
                              </Link>
                            )}
                            {this.state.btnPromotion && (
                              <Link
                                className="link_action_tbl"
                                id={`promotion${data.no}`}
                                to={{
                                  pathname: '/administration/promotionmutation',
                                  state: {
                                    usrid: data.emp_id,
                                    code: data.emp_code,
                                    name: data.emp_name,
                                    photo: data.path_photo,
                                    men_id: this.state.menIdPromotion,
                                    fga_id: this.state.fga_id,
                                    men_employee: this.state.men_id,
                                  },
                                }}
                                style={{
                                  'margin-bottom': '20px',
                                  'margin-right': '10px',
                                }}
                              >
                                <button
                                  type="button"
                                  class="btn btn-sm btn-outline-primary btn-data-table"
                                >
                                  <i class="fas fa-user-cog" />
                                </button>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`promotion${data.no}`}
                                >
                                  {t('LBL.PROMOTION_MUTATION')}
                                </UncontrolledTooltip>
                              </Link>
                            )}
                            <UncontrolledDropdown
                              id={`more${data.no}`}
                              className="link_action_tbl"
                              style={{ display: 'inline-block' }}
                            >
                              <DropdownToggle
                                className="icon btn-outline-primary btn-data-table"
                                outline
                              >
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown__menu">
                                {menu2.map((dt) => (
                                  <DropdownItem
                                    tag={RRNavLink}
                                    to={{
                                      pathname: dt.url,
                                      state: {
                                        usrid: data.emp_id,
                                        code: data.emp_code,
                                        name: data.emp_name,
                                        photo: data.path_photo,
                                        men_id: dt.men_id,
                                        fga_id: this.state.fga_id,
                                        men_employee: this.state.men_id,
                                      },
                                    }}
                                  >
                                    <span className="link_action_tbl">
                                      {t(dt.lbl_code)}
                                    </span>
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledTooltip
                              placement="top"
                              target={`edit${data.no}`}
                            >
                              {t('FRM.EDIT')}
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target={`more${data.no}`}
                            >
                              {t('LBL.MORE')}
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <ExcelFile
                  element={
                    <div
                      onClick={this.handleClick}
                      onKeyPress={this.handleClick}
                      role="button"
                      tabIndex="0"
                    >
                      <input
                        type="hidden"
                        ref={(ref) => {
                          this.inputElement = ref;
                        }}
                      />
                    </div>
                  }
                  filename={this.state.filename}
                >
                  <ExcelSheet data={this.state.dataexcel} name="Employee">
                    {this.state.headerexcel.map((obj) => (
                      <ExcelColumn label={obj} value={obj} />
                    ))}
                  </ExcelSheet>
                </ExcelFile>
                <div class="row">
                  <div class="col-md-12">
                    <div class="paging-panel-bottom">
                      <div class="row">
                        <div class="col-md-6">
                          <nav aria-label="Page navigation example">
                            <ul class="pagination">
                              <li
                                className={`page-item ${this.state.disable_preview}`}
                              >
                                <a
                                  onClick={this.previous}
                                  className="disabled page-link"
                                  href="#"
                                  tabIndex="-1"
                                  aria-disabled="true"
                                >
                                  {' '}
                                  {t('FRM.PREVIOUS')}
                                </a>
                              </li>
                              {this.createButtonPagination()}
                              <li
                                className={`page-item ${this.state.disable_next}`}
                              >
                                <a
                                  onClick={this.next}
                                  className="page-link"
                                  href="#"
                                >
                                  {t('FRM.NEXT')}
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                        <div class="col-md-6 ">
                          <form class="form-inline float-right">
                            <div class="form-group mb-2">
                              <label class="mr-3">{t('FRM.PER_PAGE')}</label>
                              <select
                                onChange={this.changePage}
                                class="form-control"
                              >
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                              </select>
                            </div>
                            <div class="f orm-group mx-sm-3 mb-2">
                              <label>
                                {t('FRM.SHOWING')} {this.state.label_idx_start}{' '}
                                to {this.state.label_idx_end} of{' '}
                                {this.state.total}
                              </label>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.isOpen && this.FieldSidebar()}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  loadBoxEmployee: state.loadBoxEmployee,
});

const ConnectedDataTable = connect(mapStateToProps)(DataTable);
const FormattedDataTable = reduxForm({
  destroyOnUnmount: false,
})(ConnectedDataTable);

// Mengaplikasikan penerjemahan pada komponen
const TranslatedDataTable = translate('global')(FormattedDataTable);

export default TranslatedDataTable;
