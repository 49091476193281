/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-did-mount-set-state */
import React from 'react';
import { Button, ButtonToolbar, Row, Col, Container } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
// import renderToggleButtonField from '../../../../shared/components/form/ToggleButton';
import renderSelectField from '../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import { translate } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';

const WizardFormThree = ({
  parmid,
  // rtbcompany,
  rtboffice,
  // rtborgjoblevel,
  rtborgjobband,
  rtborgjobname,
  rtborgjobgrade,
  rtbgeofence,
  // rtborgjobbandgrade,
  rtborgposition,
  rtborgorganization,
  rtbemployeementstatus,
  handleSubmit,
  rtbattshift,
  previousPage,
  emphierarchy,
  getjobname,
  getSuperordinat,
  getjobtitle,
  getjobgrade,
  t,
  displaySave,
  displaySpinner,
  employeeStatus,
  empStatus,
  empApproval,
}) => (
  <form className="form wizard__form" onSubmit={handleSubmit}>
    <Container>
      {/* <Row className="h_line">
        <Col md={4} sm={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">Company</span>
            <Field
              name="cmp_id"
              component={renderSelectField}
              type="text"
              options={rtbcompany}
              placeholder="Choose Company"
            />
          </div>
        </Col>
      </Row> */}
      <Row className="h_line">
        <Col md={4} sm={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">{t('LBL.OFFICE')}</span>
            <Field
              name="off_id"
              component={renderSelectField}
              type="text"
              options={rtboffice}
              placeholder="Choose Office"
            />
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('LBL.GEOFENCE_AREA')}
            </span>
            <Field
              name="ogf_id"
              style="styleTop"
              className="custom-multi"
              component={renderSelectField}
              type="text"
              options={rtbgeofence}
              placeholder="Choose Geofence Area (max. 5)"
              multi="true"
              disableTouched
            />
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('LBL.UNIT_ORGANIZATION')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="org_id"
                component={renderSelectField}
                type="text"
                options={rtborgorganization}
                onChange={getjobname}
                placeholder="Unit Organization"
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="h_line">
        <Col md={4} sm={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">{t('LBL.JOB_NAME')}</span>
            <Field
              name="jbn_id"
              component={renderSelectField}
              type="text"
              options={rtborgjobname}
              onChange={getjobtitle}
              placeholder="Choose Job Name"
            />
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">{t('LBL.JOB_TITLE')}</span>
            <Field
              name="pos_id"
              component={renderSelectField}
              type="text"
              options={rtborgposition}
              onChange={getSuperordinat}
              placeholder="Choose Position"
            />
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">{t('LBL.JOB_BAND')}</span>
            <Field
              name="jbd_id"
              component={renderSelectField}
              type="text"
              options={rtborgjobband}
              onChange={getjobgrade}
              placeholder="Choose Job Band"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">{t('LBL.GRADE')}</span>
            <Field
              name="jgd_id"
              component={renderSelectField}
              type="text"
              options={rtborgjobgrade}
              placeholder="Choose Grade"
            />
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('LBL.SUPERORDINAT')}
            </span>
            <Field
              name="emp_parent"
              component={renderSelectField}
              type="text"
              options={emphierarchy}
              placeholder="Choose Super Ordinat"
            />
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">Approval Line</span>
            <Field
              name="emp_approval"
              component={renderSelectField}
              type="text"
              options={empApproval}
              placeholder="Choose Approval Line"
            />
          </div>
        </Col>
      </Row>
      <Row className="h_line">
        <Col md={4} sm={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('LBL.EMPLOYMENT_STATUS')}
            </span>
            <Field
              name="ems_id"
              component={renderSelectField}
              type="text"
              options={rtbemployeementstatus}
              // eslint-disable-next-line no-return-assign
              onChange={employeeStatus}
              placeholder="Employee Status"
            />
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">{t('LBL.JOIN_DATE')}</span>
            <div className="form__form-group-field">
              <Field
                name="join_date"
                component={renderDatePickerField}
                type="text"
              />
              <div className="form__form-group-icon">
                <CalendarBlankIcon />
              </div>
            </div>
          </div>
        </Col>
        {empStatus !== 1 && (
          <Col md={4} sm={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t('LBL.END_DATE')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="end_date"
                  component={renderDatePickerField}
                  type="text"
                />
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Row className="h_line">
        <Col md={4} sm={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('LBL.ATTENDANCE')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="ats_id"
                component={renderSelectField}
                type="text"
                options={rtbattshift}
                placeholder="Attendance"
              />
            </div>
          </div>
        </Col>
      </Row>
      {/* show only on add */}
      {parmid.pathname.match(/add/) && (
        <Row className="h_line">
          <Col md={4} sm={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t('LBL.GENERATE_USER')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="gnr_usr"
                  component={renderCheckBoxField}
                  label={t('LBL.GENERATE_USER_LOGIN')}
                />
              </div>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          {/* <Button color="secondary" type="button">Cancel</Button> */}
          <Button
            color="primary"
            type="button"
            className="previous"
            onClick={previousPage}
          >
            {t('FRM.BACK')}
          </Button>
          <Button
            color="primary"
            type="submit"
            style={{ display: displaySave }}
          >
            {t('FRM.SAVE')}{' '}
          </Button>
          <Button color="primary" style={{ display: displaySpinner }}>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: '6px' }}
            />
            {t('FRM.LOADING')}
          </Button>
        </ButtonToolbar>
      </Row>
    </Container>
  </form>
);

WizardFormThree.propTypes = {
  parmid: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  // rtbcompany: PropTypes.array.isRequired,
  rtboffice: PropTypes.array.isRequired,
  rtborgjobband: PropTypes.array.isRequired,
  rtbgeofence: PropTypes.array.isRequired,
  // rtborgjoblevel: PropTypes.array.isRequired,
  rtborgjobname: PropTypes.array.isRequired,
  rtborgposition: PropTypes.array.isRequired,
  emphierarchy: PropTypes.array.isRequired,
  rtborgjobgrade: PropTypes.array.isRequired,
  // rtborgjobbandgrade: PropTypes.array.isRequired,
  rtborgorganization: PropTypes.array.isRequired,
  rtbemployeementstatus: PropTypes.array.isRequired,
  rtbattshift: PropTypes.array.isRequired,
  getjobname: PropTypes.array.isRequired,
  getSuperordinat: PropTypes.array.isRequired,
  getjobtitle: PropTypes.array.isRequired,
  getjobgrade: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  displaySpinner: PropTypes.string.isRequired,
  displaySave: PropTypes.string.isRequired,
  employeeStatus: PropTypes.func.isRequired,
  empStatus: PropTypes.string.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(translate('global')(WizardFormThree));
