/* eslint-disable array-callback-return */
/* eslint-disable camelcase, max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import ls from 'local-storage';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import axios from 'axios';
import showNotifications from '../../../Preferences/notificationMessages';
import Swal from 'sweetalert2';
import renderSelectField from '../../../../shared/components/form/Select';
import { translate } from 'react-i18next';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import utils from '../../../../utils';

let currentPtkp;
class Ptkphistory extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    parmid: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.NAME') },
      { 2: t('LBL.EFFECTIVE_DATE') },
      { 3: t('LBL.END_DATE') },
      { 4: t('LBL.TYPE') },
      { 5: t('FRM.FUNCTION') },
    ];

    this.state = {
      token: '',
      apiws: '',
      urlData: '',
      dataWidth: ['5%', '25%', '15%', '15%', '10%', '10%'],
      emp_id: '',
      redirect: false,
      addcond: '',
      editcond: '',
      deletecond: '',
    };

    this.getTableData = this.getTableData.bind(this);
    this.deleteptkphistory = this.deleteptkphistory.bind(this);
    // this.editptkphistory = this.editptkphistory.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getTaxStatus = this.getTaxStatus.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.linkBack = this.linkBack.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getTaxStatus();
      this.getEmployee();
      this.getButton();
      this.getLastRecord();
    });
    this.setState({
      urlData: `${ls.get('ws_ip')}/empptkphistory/getAllItemByEmpId/${
        this.props.parmid.state.usrid
      }`,
    });
    this.setState({ emp_id: this.props.parmid.state.usrid });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.NAME') },
      { 2: t('LBL.EFFECTIVE_DATE') },
      { 3: t('LBL.END_DATE') },
      { 4: t('LBL.TYPE') },
      { 5: t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  getButton() {
    const men = this.props.parmid.state.men_id;
    const fga = this.props.parmid.state.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getEmploymentStatus() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/rtbemployeementstatus/getAll/`, '', config)
      .then((resEmpStatus) => {
        const emp = resEmpStatus.data.data;
        const array = [];
        if (emp.length === 0) {
          this.setState({ arrEmpStatus: array });
        } else {
          emp.map((obj) =>
            array.push({
              value: obj.ems_id,
              label: obj.ems_employeement_status,
            }),
          );
          this.setState({ arrEmpStatus: array });
        }
      });
  }
  getEmployee() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const empid = this.state.emp_id;
    axios
      .post(
        `${ls.get('ws_ip')}/Employee/getAllItemExceptedEmp/${empid}`,
        '',
        config,
      )
      .then((resemp) => {
        const emp = resemp.data.data;
        const array = [];
        if (emp.length === 0) {
          this.setState({ arrEmp: array });
        } else {
          emp.map((obj) =>
            array.push({ value: obj.emp_id, label: obj.emp_name }),
          );
          this.setState({ arrEmp: array });
        }
      });
  }

  getTaxStatus() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/rtbtaxstatus/getAll/`, '', config)
      .then((response) => {
        const wf = response.data.data;
        const array = [];
        if (wf.length === 0) {
          this.setState({ rtbtaxstatus: array });
        } else {
          wf.map((obj) =>
            array.push({ value: obj.rtx_id, label: obj.tax_status }),
          );
          this.setState({ rtbtaxstatus: array });
        }
      });
  }

  getLastRecord(x = '') {
    currentPtkp = '';
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(
        `${ls.get('ws_ip')}/empptkphistory/getLastItem/${
          this.props.parmid.state.usrid
        }/${x}`,
        '',
        config,
      )
      .then((response) => {
        const datas = response.data.data;
        console.log(datas);
        currentPtkp =
          datas.end_date === null ? datas.start_date : datas.end_date;
      });
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  handleSubmit(values) {
    const { apiws, token, emp_id } = this.state;
    const userid = ls.get('user_cred').usr_id;
    let error = false;
    const id = values.epk_id;
    const rtx_id =
      typeof values.status === 'undefined' || values.status === null
        ? ''
        : values.status.value || values.status;
    const start = moment(values.start_date).format('YYYY-MM-DD');
    let end;

    if (typeof values.end_date === 'undefined') {
      end = '';
    } else if (values.end_date === null || values.end_date === '') {
      end = '';
    } else {
      end = moment(values.end_date).format('YYYY-MM-DD');
    }
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/empptkphistory/saveItem`;
      data = {
        rtx_id,
        emp_id,
        start,
        end,
        ...values,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/empptkphistory/updateItem/${id}`;
      data = {
        rtx_id,
        start,
        end,
        emp_id,
        ...values,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    if (!values.status || !typeof values.status === 'object') {
      error = true;
    }
    if (!values.start_date || !typeof values.start_date === 'object') {
      error = true;
    }
    // check back date
    if (
      typeof values.start_date === 'object' &&
      values.start_date.isAfter(values.end_date)
    ) {
      error = true;
    }

    if (!error) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            setTimeout(() => {
              this.props.destroy();
              this.setState({ modal: false });
              this.child.reload();
              this.getLastRecord();
            }, 200);
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }
  // async editptkphistory(id) {
  //   this.getLastRecord(id);
  //   const { token, apiws } = this.state;
  //   const config = {
  //     headers: {
  //       Authorization: `bearer ${token}`,
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //   };
  //   await axios
  //     .post(`${apiws}/empptkphistory/getAllItemById/${id}`, '', config)
  //     .then(async (response) => {
  //       if (response.data.status === 'ok') {
  //         const getResponseData = response.data.data;
  //         const datamaping = {
  //           epk_id: getResponseData[0].epk_id,
  //           status: getResponseData[0].rtx_id,
  //           start_date: moment(getResponseData[0].start_date),
  //           end_date:
  //             getResponseData[0].end_date === null
  //               ? ''
  //               : moment(getResponseData[0].end_date),
  //         };
  //         this.setState({ statusEdit: true });
  //         this.setState({ modal: true }, () => {
  //           this.props.destroy();
  //           this.props.initialize(datamaping);
  //         });
  //       } else {
  //         showNotifications('Fail', 'Save Failed', 'danger');
  //       }
  //     })
  //     .catch(() => {
  //       // console.log(error);
  //     });
  // }

  toggle() {
    this.props.destroy();
    this.setState({ modal: !this.state.modal });
  }

  deleteptkphistory(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/empptkphistory/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
                this.getLastRecord();
              }, 1000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  render() {
    const { handleSubmit, t } = this.props;
    const { rtbtaxstatus } = this.state;
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    // const addBtn = (
    //   <Button
    //     color="primary"
    //     className="btn_table_navbar"
    //     onClick={() => this.toggle()}
    //     style={{
    //       float: 'right',
    //       'margin-bottom': '20px',
    //       display: this.state.addcond === '1' ? '' : 'none',
    //     }}
    //   >
    //     {t('FRM.ADD')} {t('LBL.PTKP_HISTORY')}
    //   </Button>
    // );
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn-sm btn_table_navbar"
        onClick={this.linkBack}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          'margin-right': '10px',
        }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: '/administration/employee',
            state: {
              men_id: this.props.parmid.state.men_employee,
              fga_id: this.props.parmid.state.fga_id,
            },
          }}
        />
      );
    }
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="p0">
            <Row className="m5">
              <Col md={8}>
                <h3 className="page-title">{t('LBL.PTKP_HISTORY')}</h3>
                <h3 className="page-subhead subhead">
                  {t('LBL.VIEW')} {t('LBL.PTKP_HISTORY')}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={12} lg={12} xl={12}>
                <Card>
                  <div style={{ padding: '20px 5px 30px 10px' }}>
                    <ViewListTable
                      url={this.state.urlData}
                      heads={this.heads}
                      primaryKey="epk_id"
                      widthTable={this.state.dataWidth}
                      // updateFunc={this.editptkphistory}
                      deleteFunc={this.deleteptkphistory}
                      displayStyleHead="none"
                      onRef={(ref) => (this.child = ref)}
                      // addBtn={addBtn}
                      backBtn={backBtn}
                      editCond="2"
                      // addCond={this.state.addcond}
                      deleteCond={this.state.deletecond}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className="modal-dialog
                        modal-input"
                  style={{ marginTop: '100px', maxWidth: '650px' }}
                >
                  <ModalHeader toggle={this.toggle}>
                    {t('FRM.ADD')} {t('LBL.PTKP_HISTORY')}
                  </ModalHeader>
                  <ModalBody>
                    <form
                      id="addptkphist"
                      className="form form--horizontal"
                      onSubmit={handleSubmit(this.handleSubmit)}
                    >
                      <Container>
                        <Row>
                          <Col md={8} lg={8} sm={12} xs={12}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.TAX_STATUS')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="status"
                                  component={renderSelectField}
                                  options={rtbtaxstatus}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={8} lg={8} sm={12} xs={12}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.START_DATE')}
                              </span>
                              <div className="form__form-group-field">
                                <input type="hidden" name="epk_id" />
                                <Field
                                  name="start_date"
                                  component={renderDatePickerField}
                                  placeholder="Start Date"
                                />
                                <div className="form__form-group-icon">
                                  <CalendarBlankIcon />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={8} lg={8} sm={12} xs={12}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.END_DATE')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="end_date"
                                  component={renderDatePickerField}
                                  placeholder="Start Date"
                                />
                                <div className="form__form-group-icon">
                                  <CalendarBlankIcon />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </form>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={this.toggle}>
                      {t('FRM.CANCEL')}
                    </Button>
                    <Button color="primary" type="submit" form="addptkphist">
                      {' '}
                      Save
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row> */}
          </CardBody>
        </Card>
      </Col>
    );
  }
}
const validate = (values) => {
  const validation = {
    status: {
      fieldLabel: 'Tax status',
      required: true,
    },
    start_date: {
      fieldLabel: 'Start date',
      required: true,
    },
    // end_date: {
    //   fieldLabel: 'End date',
    //   required: true,
    //   lockDateAfter: 'start_date',
    //   fieldLabelBefore: 'Start date',
    // },
  };
  const errors = utils.validate(validation, values);
  console.log(currentPtkp);
  if (currentPtkp !== '') {
    const cdate = moment(currentPtkp);
    if (cdate.isAfter(values.start_date)) {
      errors.start_date = 'Start date already exists';
    }
  }
  return errors;
};

export default reduxForm({
  validate,
  form: 'form_Ptkphistory',
})(translate('global')(Ptkphistory));
