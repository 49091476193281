/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar, Row, Col, Container, Table } from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderSelectField from '../../../../shared/components/form/Select';
import renderFileInputField from '../../../../shared/components/form/FileInput';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderTextInput from '../../../../shared/components/form/TextInput';
import renderMaskInput from '../../../../shared/components/form/InputMask';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/fontawesome-free-solid';
import { translate } from 'react-i18next';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import renderNumberFormat from '../../../../shared/components/form/NumberFormat';
// import { CodeStringIcon } from 'mdi-react';

class WizardFormTwo extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    rtbpayproratesetting: PropTypes.array.isRequired,
    paytaxmethod: PropTypes.array.isRequired,
    paycomponentgroup: PropTypes.array.isRequired,
    rtbtaxstatus: PropTypes.array.isRequired,
    paycomponentgrouprel: PropTypes.array.isRequired,
    paycomponentrecur: PropTypes.array.isRequired,
    tempform: PropTypes.object.isRequired,
    rtbbank: PropTypes.array.isRequired,
    previousPage: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    disabledTaxStatus: PropTypes.bool.isRequired,
    disabledTaxMethod: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    valuecomp: PropTypes.array.isRequired,
    oldvaluecomp: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    paytaxclass: PropTypes.array.isRequired,
    // getFormValues: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      disabledTKNO: true,
      disabledKSNO: true,
      comp_payroll: [],
      temp2: [],
      value: [],
      oldvalue: [],
    };
    this.changeKS = this.changeKS.bind(this);
    this.changeTK = this.changeTK.bind(this);
    this.changePayrollGroup = this.changePayrollGroup.bind(this);
    this.setDefaultValue = this.setDefaultValue.bind(this);
    this.next = this.next.bind(this);
  }

  componentDidMount = () => {
    this.setState({ comp_payroll: this.props.tempform.component_payroll });
    this.setState({ temp2: this.props.tempform });
    this.setState({ oldvalue: this.props.oldvaluecomp });
    this.setState({ value: this.props.valuecomp });
    setTimeout(() => {
      this.setDefaultValue();
    }, 200);
  };

  setDefaultValue() {
    const { tempform, paycomponentrecur } = this.props;
    const { temp2, comp_payroll } = this.state;
    // this.props.paycomponentgroup.map((comp) => {
    //   if (typeof temp2.pyg_id === 'undefined' || temp2.pyg_id === null) {
    //     console.log(comp.label);
    //     if (comp.label.toLowerCase() === 'reguler') {
    //       this.props.initialize({ pyg_id: comp.value });
    //       setTimeout(() => {
    //         this.state.temp2.pyg_id = comp.value;
    //         this.changePayrollGroup({ value: comp.value, ...tempform });
    //       }, 200);
    //     }
    //   }
    // });
    let emp_nama;
    console.log('last_name', tempform.last_name);
    if (tempform.last_name === 'undefined') {
      console.log('masuk sini');
      emp_nama = tempform.first_name;
    } else {
      console.log('masuk sini1');
      emp_nama = tempform.first_name;
    }
    console.log('emp_nama', emp_nama);
    if (typeof comp_payroll === 'undefined' || comp_payroll.length === 0) {
      this.props.initialize({
        ...tempform,
        component_payroll: paycomponentrecur,
        emp_name: emp_nama,
      });
    } else {
      this.props.initialize({ ...tempform, component_payroll: comp_payroll });
    }
    this.changeKS({ value: temp2.bpjs_ks_registered });
    this.changeTK({ value: temp2.bpjs_tk_registered });
  }

  next() {
    console.log(this.state.oldvalue);
    console.log(this.state.value);
    const { tempform, valuecomp } = this.props;
    this.props.initialize({
      ...tempform,
      valuecomp: this.state.value,
      oldvaluecomp: this.state.oldvalue,
    });
    setTimeout(() => {
      this.props.onSubmit();
    }, 200);
  }

  changeKS(event) {
    if (event.value === '1') {
      this.setState({ disabledKSNO: false });
    } else {
      this.setState({ disabledKSNO: true });
    }
  }

  changeTK(event) {
    if (event.value === '1') {
      this.setState({ disabledTKNO: false });
    } else {
      this.setState({ disabledTKNO: true });
    }
  }

  changePayrollGroup(event) {
    // console.log(this.state.value);
    const id = event.value;
    const { paycomponentgrouprel, tempform } = this.props;

    if (event.value === this.state.temp2.pyg_id) {
      this.props.initialize({
        ...tempform,
        component_payroll: this.state.comp_payroll,
      });
    } else {
      this.props.initialize({
        ...tempform,
        component_payroll: paycomponentgrouprel[id],
      });
    }
    // alert(this.state.comp_payroll);
    if (typeof this.state.comp_payroll === 'undefined') {
      // if (typeof this.state.comp_payroll === 'undefined') {
      this.props.initialize({
        ...tempform,
        component_payroll: paycomponentgrouprel[id],
      });
      // }
    }
  }

  renderComponentPayroll = ({
    fields,
    meta: { error },
    t,
    valuecomp,
    oldvaluecomp,
    tempform,
  }) => (
    <div>
      {fields.map((component, index) => (
        <Row className="h_line">
          <div className="identity_form_group">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">&nbsp;</span>
                <div className="form__form-group-field">
                  <input type="hidden" name={`${component}.pyc_id`} />
                  <Field
                    name={`${component}.label`}
                    component="input"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </Col>
            {/* <Row>
                <Col md={6} sm={12}>
                  <div className="form__form-group" >
                    <span className="form__form-group-label">{t('LBL.OLD_VALUE')}</span>
                    <div className="form__form-group-field number-form">
                      <NumberFormat
                        name={`${component}.old_value`}
                        component={NumberFormat}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="Rp "
                        type="text"
                        value={this.state.oldvalue[index]}
                        onValueChange={(val) => {
                          const a = this.state.oldvalue.slice();
                          a[index] = val.value;
                          this.setState({ oldvalue: a });
                        }}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <div className="form__form-group" >
                    <span className="form__form-group-label">{t('LBL.VALUE')}</span>
                    <div className="form__form-group-field number-form">
                      <NumberFormat
                        name={`${component}.pec_value`}
                        component={NumberFormat}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="Rp "
                        type="text"
                        value={this.state.value[index]}
                        onValueChange={(val) => {
                          const a = this.state.value.slice();
                          a[index] = val.value;
                          this.setState({ value: a });
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row> */}
            <Row>
              <Col md={6} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t('LBL.OLD_VALUE')}
                  </span>
                  <Field
                    name={`${component}.old_value`}
                    component={renderNumberFormat}
                    other={{
                      value: this.state.oldvalue[index],
                      thousandSeparator: '.',
                      decimalSeparator: ',',
                      prefix: 'Rp ',
                      disabled: true,
                    }}
                    onChange={(val) => {
                      const a = this.state.oldvalue.slice();
                      a[index] = val.value;
                      this.setState({ oldvalue: a });
                    }}
                  />
                  {/* </div> */}
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t('LBL.VALUE')}
                  </span>
                  <Field
                    name={`${component}.pec_value`}
                    component={renderNumberFormat}
                    other={{
                      // defaultValue: this.state.oldvalue,
                      value: this.state.value[index],
                      thousandSeparator: '.',
                      decimalSeparator: ',',
                      prefix: 'Rp ',
                    }}
                    onChange={(val) => {
                      const a = this.state.value.slice();
                      a[index] = val.value;
                      this.setState({ value: a });
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Col md={2} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.BACKPAY')}
                </span>
                <div className="form__form-group-field checkbox_custom">
                  <Field
                    name={`${component}.is_backpay`}
                    component={renderTextInput}
                    type="checkbox"
                  />
                </div>
              </div>
            </Col>
            <Col md={2} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.EFFECTIVE')}
                </span>
                <div className="form__form-group-field checkbox_custom">
                  <Field
                    name={`${component}.is_effective`}
                    component={renderTextInput}
                    type="checkbox"
                  />
                </div>
              </div>
            </Col>
          </div>
        </Row>
      ))}
      {error && <li className="error">{error}</li>}
    </div>
  );

  render() {
    const {
      paycomponentgroup,
      rtbpayproratesetting,
      paytaxmethod,
      rtbtaxstatus,
      handleSubmit,
      previousPage,
      rtbbank,
      disabledTaxStatus,
      disabledTaxMethod,
      t,
      valuecomp,
      oldvaluecomp,
      tempform,
      paytaxclass,
    } = this.props;
    const { disabledTKNO, disabledKSNO } = this.state;

    return (
      <form
        id="myForm"
        className="form wizard__form"
        onSubmit={handleSubmit(this.next)}
      >
        <Container>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.EMPLOYEE_NAME')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="emp_name"
                    component={renderTextInput}
                    type="text"
                    placeholder={t('LBL.EMPLOYEE_NAME')}
                    disabled
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.TAX_METHOD')}
                </span>
                <Field
                  name="ptm_id"
                  component={renderSelectField}
                  type="text"
                  // disabled={disabledTaxMethod}
                  options={paytaxmethod}
                  placeholder="Choose Tax Method"
                />
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.TAX_STATUS')}
                </span>
                <Field
                  name="rtx_id"
                  component={renderSelectField}
                  type="text"
                  disabled={disabledTaxStatus}
                  options={rtbtaxstatus}
                  placeholder="Choose Tax Status"
                />
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.TAX_RECIPIENT')}
                </span>
                <Field
                  name="tcs_id"
                  component={renderSelectField}
                  type="text"
                  options={paytaxclass}
                  placeholder="Choose Tax Status"
                />
              </div>
            </Col>
            {/* <Col md={2} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('LBL.DEPENDANT')}</span>
                <Field
                  name="dependant"
                  component={renderTextInput}
                  type="number"
                  placeholder="Dependant"
                />
              </div>
            </Col> */}
          </Row>
          {/* <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">Prorate Setting</span>
                <div className="form__form-group-field">
                  <Field
                    name="pys_id"
                    component={renderSelectField}
                    type="text"
                    options={rtbpayproratesetting}
                    placeholder="Prorate Setting"
                  />
                </div>
              </div>
            </Col>
          </Row> */}
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.OVERTIME')}{' '}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="overtime"
                    component={renderSelectField}
                    type="text"
                    options={[
                      { value: '1', label: 'Eligible' },
                      { value: '2', label: 'Not' },
                    ]}
                    placeholder="Overtime"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.BPJS_KETENAGAKERJAAN')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="bpjs_tk_registered"
                    component={renderSelectField}
                    type="text"
                    onChange={this.changeTK}
                    options={[
                      { value: '1', label: t('LBL.REGISTERED') },
                      { value: '2', label: t('LBL.NOT_REGISTERED') },
                    ]}
                    placeholder="Choose BPJS TK"
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.NUMBER_BPJS_KETENAGAKERJAAN')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="bpjs_tk_no"
                    component={renderTextInput}
                    disabled={disabledTKNO}
                    type="text"
                    maxLength="32"
                    placeholder="Your Number BPJS Ketenaga Kerjaan"
                  />
                </div>
              </div>
            </Col>
            {/* <Col md={4} xs={12}>
              <div className="checkbox_form_group">
                <Field
                  name="bpjs_tk_paid_by_company"
                  component={renderCheckBoxField}
                  label={t('LBL.PAID_BY_COMPANY')}
                />
              </div>
            </Col> */}
          </Row>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.BPJS_KESEHATAN')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="bpjs_ks_registered"
                    component={renderSelectField}
                    type="text"
                    onChange={this.changeKS}
                    options={[
                      { value: '1', label: t('LBL.REGISTERED') },
                      { value: '2', label: t('LBL.NOT_REGISTERED') },
                    ]}
                    placeholder="Choose BPJS Kesehatan"
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.NUMBER_BPJS_KESEHATAN')} {t('LBL.NUMBER')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="bpjs_ks_no"
                    disabled={disabledKSNO}
                    component={renderTextInput}
                    type="text"
                    maxLength="32"
                    placeholder="Your Number BPJS Kesehatan"
                  />
                </div>
              </div>
            </Col>
            {/* <Col md={4} xs={12}>
              <div className="checkbox_form_group">
                <Field
                  name="bpjs_ks_paid_by_company"
                  component={renderCheckBoxField}
                  label={t('LBL.PAID_BY_COMPANY')}
                />
              </div>
            </Col> */}
          </Row>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">NPWP</span>
                <div className="form__form-group-field">
                  <Field
                    name="npwp"
                    component={renderMaskInput}
                    mask={[
                      /[0-9]/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    type="text"
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.NPWP_DATE')}
                </span>
                <div className="form__form-group-field">
                  <input type="hidden" name="pad_id" />
                  <Field name="npwp_date" component={renderDatePickerField} />
                  <div className="form__form-group-icon">
                    <CalendarBlankIcon />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.UPLOAD')} NPWP
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="file_npwp"
                    allowedType={['png', 'jpg', 'jpeg', 'pdf']}
                    maxSize={1000}
                    component={renderFileInputField}
                    type="textemployee"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.ID_TKU')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="id_tku"
                    component={renderTextInput}
                    type="number"
                    textAlign="left"
                    maxLength="22"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.BANK_NAME')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="rtk_id"
                    component={renderSelectField}
                    type="text"
                    options={rtbbank}
                    placeholder="Select Bank"
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.BANK_ACCOUNT')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="bank_account"
                    component={renderTextInput}
                    type="number"
                    maxLength="32"
                    placeholder="Your Bank Account"
                    disableTouched
                  />
                </div>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.ACCOUNT_HOLDER')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="bank_account_holder"
                    component={renderTextInput}
                    type="text"
                    placeholder="Your Bank Account Holder"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h_line">
            <Col md={4} sm={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t('LBL.BIOLOGICAL_MOTHER')}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="biological_mother"
                    component={renderTextInput}
                    type="text"
                    placeholder="Your Biological Mother"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="h_line">
            <Col md={12} sm={12}>
              <div className="adj-panel-profile">
                <div className="title-adj">{t('LBL.PAYROLL')}</div>
                <div className="body-adj">
                  <Row>
                    <Col md={4}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.PAYROLL')} {t('LBL.START_DATE')}
                        </span>
                        <div className="form__form-group-field">
                          <input type="hidden" name="pad_id" />
                          <Field
                            name="adjustment_start_date"
                            component={renderDatePickerField}
                          />
                          <div className="form__form-group-icon">
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.PAYROLL')} {t('LBL.END_DATE')}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="adjustment_end_date"
                            component={renderDatePickerField}
                          />
                          <div className="form__form-group-icon">
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.BACKPAY')} {t('LBL.DATE')}{' '}
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="backpay_date"
                            component={renderDatePickerField}
                          />
                          <div className="form__form-group-icon">
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col md={4} sm={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('LBL.PAYROLL')} {t('LBL.GROUP_COMPONENT')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="pyg_id"
                            component={renderSelectField}
                            type="text"
                            options={paycomponentgroup}
                            onChange={this.changePayrollGroup}
                            placeholder="Select Payroll Group Component"
                          />
                        </div>
                      </div>
                    </Col> */}
                    <Col md={12} sm={12}>
                      <FieldArray
                        name="component_payroll"
                        component={this.renderComponentPayroll}
                        props={{
                          t,
                          valuecomp,
                          oldvaluecomp,
                          tempform,
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <ButtonToolbar className="form__button-toolbar wizard__toolbar">
              <Button
                color="primary"
                type="button"
                className="previous"
                onClick={previousPage}
              >
                {t('FRM.BACK')}
              </Button>
              <Button color="primary" type="submit" className="next">
                {t('FRM.NEXT')}
              </Button>
            </ButtonToolbar>
          </Row>
        </Container>
      </form>
    );
  }
}
// WizardFormTwo.propTypes = {

// };

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(translate('global')(WizardFormTwo));
