import React, { useEffect, useState } from 'react';
import renderCheckBoxField from '../form/CheckBox';
import { Field } from 'redux-form';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';

import './MultiSelect.scss';

const CustomMultiSelectDuoNew = ({
  t,
  options = [],
  optionsFirst = [],
  suffix = 'data',
  dataSelected = [],
  handleSelected = () => {},
  handleSearch = () => {},
}) => {
  const [selected, setSelected] = useState(dataSelected);

  const [unResultUnselected, setUnResultUnselected] = useState([]); // State untuk unselected

  const handleDeleteSelected = (item) => {
    // Filter item yang akan tetap di "selected"
    const unSelected = selected.filter((selectedItem) => selectedItem != item);

    // Perbarui state "selected"
    setSelected(unSelected);

    // Tambahkan item ke "unResultUnselected"
    setUnResultUnselected((prevUnselected) => [...prevUnselected, item]);
  };

  useEffect(() => {
    handleSelected(selected, unResultUnselected);
  }, [selected]);

  const [search, setSearch] = useState('');

  return (
    <>
      <div className="multi multi-new rounded w-100 d-flex flex-column">
        <div className="d-flex h-100 position-relative">
          <div className="w-50 h-100 multi-select d-flex flex-column">
            <div className="multi-header px-2 pt-2 pb-0 d-flex justify-content-between align-items-center">
              <span className="fw-bold">
                {t('LBL.SELECT')}{' '}
                {options.filter((item) => selected.includes(item.value))?.length
                  ? selected.length
                  : 0}{' '}
                {t('LBL.OF')} {options.length} {suffix}
              </span>
              <div
                className="text-primary"
                onClick={() => {
                  setSelected(options.map((option) => option.value));
                }}
                style={{
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
              >
                {t('LBL.SELECT_ALL')}
              </div>
            </div>
            <div className="multi-search p-2">
              <input
                type="text"
                className="form-control"
                placeholder={`${t('LBL.SEARCH')} ${suffix}...`}
                onChange={(e) => {
                  handleSearch([], e.target.value);
                }}
              />
              <MagnifyIcon className="search-icon" size={16} color="#999999" />
            </div>
            <div className="multi-data border px-3 pt-2 rounded-left flex-grow-1 border-right ">
              {options.map((option, index) => (
                <div
                  key={`multi-item-${index}`}
                  className="multi-item"
                  style={{
                    minHeight: '20px',
                    marginBottom: '0.5rem',
                    opacity: selected.includes(option.value) ? 0.25 : 1,
                    cursor: selected.includes(option.value)
                      ? 'not-allowed'
                      : 'pointer',
                    userSelect: 'none',
                  }}
                  onClick={() => {
                    if (!selected.includes(option.value)) {
                      setSelected([...selected, option.value]);
                    }
                  }}
                >
                  {option.label}
                </div>
              ))}
            </div>
          </div>
          <div className="multi-arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="13"
              viewBox="0 0 20 18"
              fill="none"
            >
              <path
                d="M6.20663 8.7577C6.29961 8.85058 6.37337 8.96087 6.42369 9.08226C6.47401 9.20366 6.49992 9.33379 6.49992 9.46521C6.49992 9.59662 6.47401 9.72675 6.42369 9.84815C6.37337 9.96954 6.29961 10.0798 6.20663 10.1727L4.37963 12.0007H13.9996C14.2648 12.0007 14.5192 12.1061 14.7067 12.2936C14.8943 12.4811 14.9996 12.7355 14.9996 13.0007C14.9996 13.2659 14.8943 13.5203 14.7067 13.7078C14.5192 13.8953 14.2648 14.0007 13.9996 14.0007H4.37963L6.20763 15.8287C6.30054 15.9215 6.37425 16.0318 6.42456 16.1531C6.47487 16.2744 6.50079 16.4045 6.50083 16.5359C6.50088 16.6672 6.47505 16.7973 6.42483 16.9186C6.37461 17.04 6.30097 17.1503 6.20813 17.2432C6.11529 17.3361 6.00505 17.4098 5.88372 17.4601C5.76239 17.5104 5.63233 17.5364 5.50098 17.5364C5.36964 17.5365 5.23956 17.5106 5.1182 17.4604C4.99683 17.4102 4.88654 17.3365 4.79363 17.2437L1.25763 13.7077C1.07016 13.5202 0.964844 13.2659 0.964844 13.0007C0.964844 12.7355 1.07016 12.4812 1.25763 12.2937L4.79363 8.7577C4.98116 8.57023 5.23547 8.46492 5.50063 8.46492C5.76579 8.46492 6.0201 8.57023 6.20763 8.7577H6.20663ZM13.7926 0.757705C13.9648 0.585528 14.1939 0.482097 14.4369 0.466816C14.68 0.451535 14.9202 0.525454 15.1126 0.674705L15.2066 0.757705L18.7426 4.29371C18.9148 4.4659 19.0182 4.695 19.0335 4.93802C19.0488 5.18105 18.9749 5.4213 18.8256 5.61371L18.7426 5.70771L15.2066 9.2427C15.0267 9.42205 14.7852 9.52618 14.5312 9.53393C14.2773 9.54169 14.0299 9.45249 13.8394 9.28446C13.6488 9.11642 13.5293 8.88215 13.5052 8.62923C13.4811 8.37631 13.5542 8.1237 13.7096 7.92271L13.7926 7.82871L15.6196 6.00071H5.99963C5.74475 6.00042 5.4996 5.90283 5.31426 5.72786C5.12893 5.55289 5.0174 5.31375 5.00246 5.05931C4.98752 4.80487 5.0703 4.55433 5.2339 4.35887C5.39749 4.16342 5.62953 4.03781 5.88263 4.0077L5.99963 4.00071H15.6206L13.7926 2.17071C13.6052 1.98318 13.4998 1.72887 13.4998 1.4637C13.4998 1.19854 13.6052 0.945233 13.7926 0.757705Z"
                fill="#89929B"
              />
            </svg>
          </div>
          <div className="w-50 h-100 multi-selected d-flex flex-column">
            <div className="multi-header px-2 pt-2 pb-0 d-flex justify-content-between align-items-center">
              <span className="fw-bold">
                {optionsFirst.filter((item) => selected.includes(item.value))
                  ?.length
                  ? selected.length
                  : 0}{' '}
                {suffix} {t('LBL.SELECTED')}
              </span>
              <div
                className="text-primary"
                onClick={() => {
                  setSelected([]);
                }}
                style={{
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
              >
                {t('LBL.UN_SELECT_ALL')}
              </div>
            </div>
            <div className="multi-search p-2">
              <input
                type="text"
                className="form-control"
                placeholder={`${t('LBL.SEARCH')} ${t(
                  'LBL.SELECTED',
                )} ${suffix}...`}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <MagnifyIcon className="search-icon" size={16} color="#999999" />
            </div>
            <div className="multi-data border px-3 pt-2 rounded-right flex-grow-1">
              {selected
                .map((dt) => {
                  return optionsFirst
                    .filter((item) => {
                      if (search != '') {
                        return (
                          item.label
                            .toLowerCase()
                            .includes(search.toLowerCase()) && item.value === dt
                        );
                      } else {
                        return item.value === dt;
                      }
                    })
                    .map((item) => item.value);
                })
                .filter((final) => final.length > 0)
                .map((item, index) => (
                  <div
                    style={{
                      minHeight: '20px',
                      marginBottom: '0.5rem',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    key={`selected-item-${index}`}
                    className="multi-item"
                    onClick={() => {
                      handleDeleteSelected(item);
                    }}
                  >
                    {optionsFirst.find((opts) => opts.value == item)?.label}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomMultiSelectDuoNew;
