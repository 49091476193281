/* eslint-disable no-return-assign, class-methods-use-this */
/* eslint-disable camelcase, no-unused-vars */
/* eslint-disable array-callback-return, quote-props */
/*
 * Page Office
 * Notes: Master Data Office, Connect with company and join ( province, city, subdistrict and village )
 * @author Gerdi
 * @date 02/08/2019
 * Modification (please note below)
 */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Col,
  Modal,
  Button,
  Row,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import Spinner from 'react-bootstrap/Spinner';
import { reduxForm, Field } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/fontawesome-free-solid';
// import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ls from 'local-storage';
import axios from 'axios';

import showNotifications from '../../../notificationMessages';
import renderTextInput from '../../../../../shared/components/form/TextInput';
import renderMaskInput from '../../../../../shared/components/form/InputMask';
import renderSelectField from '../../../../../shared/components/form/Select';
import Swal from 'sweetalert2';
import '../../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import isNumber from '../../../isnumber';
import isAlphaNumeric from '../../../isalphanumeric';
import isSpecialChar from '../../../isspecialchar';
// import isValidation from '../../../isvalidation';

class OfficeSettingForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,
    men_id: PropTypes.string.isRequired,
    fga_id: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.NAME') },
      { '2': t('LBL.ADDRESS') },
      { '3': t('LBL.PROVINCE') },
      { '4': t('LBL.CITY') },
      { '5': t('LBL.SUBDISTRICT') },
      { '6': t('LBL.VILLAGE') },
      { '7': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      dataWidth: ['2%', '15%', '15%', '10%', '10%', '10%', '10%', '10%'],
      urlData: [],
      modal: false,
      statusEdit: false,
      titleModal: '',
      arrProvinces: [],
      arrCity: [],
      arrSubdistrict: [],
      arrVillage: [],
      provinces: '',
      cities: '',
      displaySave: '',
      displaySpinner: 'none',
      disabledCancel: '',
      addcond: '',
      deletecond: '',
      editcond: '',
      rtbgeofence: [],
      arrBank: [],
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeprov = this.changeprov.bind(this);
    this.changecity = this.changecity.bind(this);
    this.changedistrict = this.changedistrict.bind(this);
    this.getProvinces = this.getProvinces.bind(this);
    this.editRows = this.editRows.bind(this);
    this.deleteRows = this.deleteRows.bind(this);
    this.getButton = this.getButton.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.getBank = this.getBank.bind(this);
  }

  componentWillMount() {
    this.getProvinces();
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.getTableData();
      this.getBank();
      this.getButton();
      this.getListGeofence();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/orgoffice` });
    const arrNameField1 = ['office', 'tax_collector_name'];
    const arrNameField2 = ['postal_code', 'bank_account'];
    const arrNameField3 = ['address'];
    document.addEventListener('keydown', function(event) {
      const target = event.target.name;
      if (target !== undefined) {
        if (arrNameField1.includes(target)) {
          isAlphaNumeric(event, target, arrNameField1);
        }
        if (arrNameField2.includes(target)) {
          isNumber(event, target, arrNameField2);
        }
        if (arrNameField3.includes(target)) {
          isSpecialChar(event, target, arrNameField3);
        }
      }
    });
    // document.addEventListener('keydown', function (event) {
    //   const target = event.target.name;
    //   if (target !== undefined) {
    //     if (arrNameField3.includes(target)) {
    //       isAlphaNumeric(event, target, arrNameField3);
    //     }
    //   }
    // });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.NAME') },
      { '2': t('LBL.ADDRESS') },
      { '3': t('LBL.PROVINCE') },
      { '4': t('LBL.CITY') },
      { '5': t('LBL.SUBDISTRICT') },
      { '6': t('LBL.VILLAGE') },
      { '7': t('FRM.FUNCTION') },
    ];
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getBank() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios.post(`${ls.get('ws_ip')}/rtbbank/getAll`, '', config).then((res) => {
      const rpv = res.data.data;
      const array = [];
      if (rpv.length === 0) {
        this.setState({ arrBank: array });
      } else {
        rpv.map((obj) => array.push({ value: obj.rtk_id, label: obj.bank }));
        this.setState({ arrBank: array });
      }
    });
  }

  getListGeofence() {
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios.post(`${ls.get('ws_ip')}/geofence/getAll`, '', config).then((res) => {
      const dataResponse = res.data.data;
      if (res.data.status === 'ok') {
        const Arr = [];
        dataResponse.map((obj) =>
          Arr.push({ value: obj.ogf_id, label: obj.title }),
        );
        this.setState({ rtbgeofence: Arr });
      }
    });
  }

  getProvinces() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/rtbprovince/getAll/`, '', config)
      .then((resProvince) => {
        const rpv = resProvince.data.data;
        const array = [];
        if (rpv.length === 0) {
          this.setState({ arrProvinces: array });
        } else {
          rpv.map((obj) =>
            array.push({ value: obj.prv_code, label: obj.prv_name }),
          );
          this.setState({ arrProvinces: array });
        }
      });
  }

  getTableData() {
    this.child.reload();
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      });
  }

  toggle(id) {
    const { t } = this.props;
    if (id === 'add') {
      this.props.destroy();
      this.setState({ titleModal: `${t('FRM.ADD')} ${t('LBL.OFFICE')}` });
      this.setState({ statusEdit: false });
    } else {
      this.setState({ statusEdit: true });
      this.setState({ titleModal: `${t('FRM.EDIT')} ${t('LBL.OFFICE')}` });
    }
    this.setState({
      modal: !this.state.modal,
      disabledCancel: '',
      displaySave: '',
      displaySpinner: 'none',
    });
  }

  handleKeyPress(event) {
    const tgt = event.target.name;
    if (tgt !== undefined) {
      if (this.state.nameForm.includes(tgt)) {
        if (
          !(
            /[0-9.]/i.test(event.key) ||
            event.key === 'Backspace' ||
            event.key === 'ArrowRight' ||
            event.key === 'ArrowLeft' ||
            event.key === 'Delete'
          )
        ) {
          event.preventDefault();
        }
      }
    }
  }

  mapFormData(b) {
    let val_b;
    if (b !== '') {
      if (typeof b === 'object') {
        if (b === 'null' || b === null) {
          val_b = '';
        } else {
          val_b = b.value;
        }
      } else {
        val_b = b;
      }
    } else {
      val_b = '';
    }
    return val_b;
  }

  handleSubmit(values) {
    const { apiws, token, idUser } = this.state;
    this.setState({
      displaySpinner: '',
      displaySave: 'none',
      disabledCancel: 'disabled',
    });
    const formBody = Object.keys(values)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            this.mapFormData(values[key]),
          )}`,
      )
      .join('&');
    const config = {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    let action;
    let link;
    let body;
    if (typeof values.off_id === 'undefined') {
      action = 'POST';
      link = `${apiws}/orgoffice`;
      body = [formBody, `cuser_id=${idUser}`].join('&');
    } else {
      action = 'PUT';
      link = `${apiws}/orgoffice/${values.off_id}`;
      body = [formBody, `muser_id=${idUser}`].join('&');
    }
    const options = {
      method: action,
      url: link,
      headers: config,
      data: body,
    };
    axios(options).then((x) => {
      if (x.data.status === 'ok') {
        this.toggle();
        this.child.reload('addedit');
        showNotifications('Success', 'Save Success', 'success');
        this.props.destroy();
      } else {
        showNotifications('Failed', 'Save Failed', 'danger');
        this.setState({
          displaySpinner: 'none',
          displaySave: '',
          disabledCancel: '',
        });
      }
    });
  }

  editRows(id) {
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios.get(`${apiws}/orgoffice/${id}`, config).then((response) => {
      const dataResponse = response.data.data;
      this.toggle();
      this.setState({
        statusEdit: true,
        provinces: dataResponse[0].province,
        cities: dataResponse[0].city,
      });
      this.changeprov({ value: dataResponse[0].province });
      this.changecity({ value: dataResponse[0].city });
      this.changedistrict({ value: dataResponse[0].subdistrict });
      this.props.initialize({
        off_id: dataResponse[0].off_id,
        office: dataResponse[0].office,
        address: dataResponse[0].address,
        postal_code: dataResponse[0].postal_code,
        tax_collector_name: dataResponse[0].tax_collector_name,
        tax_collector_npwp: dataResponse[0].tax_collector_npwp,
        office_npwp: dataResponse[0].office_npwp,
        province: dataResponse[0].province,
        city: dataResponse[0].city,
        subdistrict: dataResponse[0].subdistrict,
        village: dataResponse[0].village,
        npp: dataResponse[0].npp,
        ogf_id: dataResponse[0].ogf_id,
        rtk_id: dataResponse[0].rtk_id,
        bank_account: dataResponse[0].bank_account,
        currency: dataResponse[0].currency,
        account_name: dataResponse[0].account_name,
        company_code: dataResponse[0].company_code,
        branch_code: dataResponse[0].branch_code,
        id_tku: dataResponse[0].business_activity_id,
        office_tin: dataResponse[0].office_tin,
      });
    });
  }

  changeprov(x) {
    if (!this.state.status_edit) {
      this.props.change('city', 0);
      this.props.change('subdistrict', 0);
      this.props.change('village', 0);
    }
    const id = x.value;
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/rtbcity/getallitembyid/${id}`, '', config)
      .then((resCity) => {
        const rct = resCity.data.data;
        const array = [];
        if (rct.length > 0) {
          rct.map((obj) =>
            array.push({ value: obj.cty_code, label: obj.cty_name }),
          );
          this.setState({
            arrCity: array,
            provinces: id,
          });
        }
      });
  }

  changecity(x) {
    if (!this.state.status_edit) {
      this.props.change('subdistrict', 0);
      this.props.change('village', 0);
    }
    const id = x.value;
    const { apiws, token, provinces } = this.state;
    const procity = `${provinces}${id}`;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/rtbsubdistrict/getallitembyid/${procity}`, '', config)
      .then((resSubdistrict) => {
        const rbd = resSubdistrict.data.data;
        const array = [];
        if (rbd.length > 0) {
          rbd.map((obj) =>
            array.push({ value: obj.sbd_code, label: obj.sbd_name }),
          );
          this.setState({
            arrSubdistrict: array,
            cities: id,
          });
        }
      });
  }

  changedistrict(x) {
    if (!this.state.status_edit) {
      this.props.change('village', 0);
    }
    const id = x.value;
    const { apiws, token, provinces, cities } = this.state;
    const procitydistrict = `${provinces}${cities}${id}`;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/rtbvillage/getallitembyid/${procitydistrict}`, '', config)
      .then((resVillage) => {
        const rvl = resVillage.data.data;
        const array = [];
        if (rvl.length > 0) {
          rvl.map((obj) =>
            array.push({ value: obj.vlg_code, label: obj.vlg_name }),
          );
          this.setState({ arrVillage: array });
        }
      });
  }

  deleteRows(id) {
    const { apiws, token, idUser } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${apiws}/orgoffice/${id}`, config, `duser_id=${idUser}`)
          .then((response) => {
            if (response.data.status === 'ok') {
              this.child.reload('delete');
              showNotifications('Success', 'Delete Success', 'success');
            } else {
              showNotifications('Fail', 'Delete Fail', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Fail', 'danger');
          });
      }
    });
  }

  render() {
    const {
      statusEdit,
      titleModal,
      arrProvinces,
      arrCity,
      arrSubdistrict,
      arrVillage,
      urlData,
      displaySpinner,
      displaySave,
      disabledCancel,
      rtbgeofence,
    } = this.state;
    const { handleSubmit, t, pristine } = this.props;
    const modalStyle = {
      maxWidth: '900px',
      marginTop: '20px',
      textAlign: 'center',
    };
    let button_action;
    let button_office;
    let field_offid;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle('add')}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.OFFICE')}
      </Button>
    );
    if (statusEdit) {
      field_offid = (
        <Field name="off_id" component={renderTextInput} type="hidden" />
      );
      button_office = (
        <Field
          name="office"
          id="office"
          component={renderTextInput}
          type="text"
          autocomplete="off"
        />
      );
      button_action = (
        <Button
          color="success"
          type="submit"
          form="officeformorg"
          disabled={pristine}
          style={{ display: displaySave }}
        >
          Edit
        </Button>
      );
    } else {
      button_office = (
        <Field
          name="office"
          id="office"
          component={renderTextInput}
          type="text"
          autocomplete="off"
        />
      );
      button_action = (
        <Button
          color="primary"
          form="officeformorg"
          type="submit"
          disabled={pristine}
          style={{ display: displaySave }}
        >
          Save
        </Button>
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  deleteCond={this.state.deletecond}
                  editCond={this.state.editcond}
                  primaryKey="off_id"
                  widthTable={this.state.dataWidth}
                  deleteFunc={this.deleteRows}
                  updateFunc={this.editRows}
                  onRef={(ref) => (this.child = ref)}
                  displayStyleHead="none"
                  buttonAction={['edit', 'delete']}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="modal-dialog modal-input"
              style={modalStyle}
            >
              <ModalHeader toggle={this.toggle}>
                {' '}
                {titleModal} {this.state.message}
              </ModalHeader>
              <ModalBody>
                <form
                  className="form form--horizontal"
                  onSubmit={handleSubmit(this.handleSubmit)}
                  name="officeformorg"
                  id="officeformorg"
                >
                  {field_offid}
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.NAME')}
                      </span>
                      <div className="form__form-group-field">
                        {button_office}
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.ADDRESS')}
                      </span>
                      <div
                        className="form__form-group-field"
                        style={{ marginBottom: '-10px', marginTop: '-5px' }}
                      >
                        <Field
                          name="address"
                          component={renderTextInput}
                          type="textarea"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.TAX_COLLECTOR_NAME')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="tax_collector_name"
                          component={renderTextInput}
                          type="text"
                          maxLength="50"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.TAX_COLLECTOR_NPWP')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="tax_collector_npwp"
                          component={renderMaskInput}
                          mask={[
                            /[0-9]/,
                            /\d/,
                            '.',
                            /\d/,
                            /\d/,
                            /\d/,
                            '.',
                            /\d/,
                            /\d/,
                            /\d/,
                            '.',
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                            /\d/,
                            '.',
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.ID_TKU')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="id_tku"
                          component={renderTextInput}
                          type="number"
                          textAlign="left"
                          maxLength="22"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.NPWP_OFFICE_16')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="office_tin"
                          component={renderTextInput}
                          type="number"
                          textAlign="left"
                          maxLength="16"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.OFFICE_NPWP')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="office_npwp"
                          component={renderMaskInput}
                          mask={[
                            /[0-9]/,
                            /\d/,
                            '.',
                            /\d/,
                            /\d/,
                            /\d/,
                            '.',
                            /\d/,
                            /\d/,
                            /\d/,
                            '.',
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                            /\d/,
                            '.',
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.NPP')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="npp"
                          component={renderTextInput}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.BANK')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="rtk_id"
                          id="rtk_id"
                          component={renderSelectField}
                          type="text"
                          options={this.state.arrBank}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.BANK')} {t('LBL.ACCOUNT_NAME')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="account_name"
                          component={renderTextInput}
                          type="text"
                          maxLength="20"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.BANK_ACCOUNT')}
                      </span>
                      <div className="form__form-group-field number-form">
                        <Field
                          name="bank_account"
                          component={renderTextInput}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.PROVINCE')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="province"
                          id="province"
                          component={renderSelectField}
                          type="text"
                          options={arrProvinces}
                          onChange={this.changeprov}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.CITY')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="city"
                          id="city"
                          component={renderSelectField}
                          type="text"
                          options={arrCity}
                          onChange={this.changecity}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.SUBDISTRICT')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="subdistrict"
                          id="subdistrict"
                          component={renderSelectField}
                          type="text"
                          options={arrSubdistrict}
                          onChange={this.changedistrict}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.VILLAGE')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="village"
                          id="village"
                          component={renderSelectField}
                          type="text"
                          options={arrVillage}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.POSTAL_CODE')}
                      </span>
                      <div className="form__form-group-field number-form">
                        <Field
                          name="postal_code"
                          component={renderTextInput}
                          type="text"
                          maxLength="5"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.GEOFENCE_AREA')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="ogf_id"
                          id="ogf_id"
                          component={renderSelectField}
                          type="text"
                          options={rtbgeofence}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.BANK')} {t('LBL.CURRENCY')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="currency"
                          id="currency"
                          component={renderTextInput}
                          type="text"
                          maxLength="3"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.BANK')} {t('LBL.COMPANY_CODE')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="company_code"
                          component={renderTextInput}
                          type="text"
                          maxLength="20"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.BANK')} {t('LBL.BRANCH_CODE')}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="branch_code"
                          component={renderTextInput}
                          type="text"
                          maxLength="20"
                        />
                      </div>
                    </div>
                  </Col>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={this.toggle}
                  disabled={disabledCancel}
                >
                  Cancel
                </Button>
                {button_action}
                <Button color="primary" style={{ display: displaySpinner }}>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: '6px' }}
                  />
                  Loading...
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.office) {
    errors.office = 'Company field shouldn’t be empty';
  }
  if (!values.address) {
    errors.address = 'Address field shouldn’t be empty';
  }
  if (values.tax_collector_npwp) {
    const otc = values.tax_collector_npwp;
    const patt1 = /[0-9]/g;
    const result = otc.match(patt1);
    if (result.length < 15) {
      errors.tax_collector_npwp = 'Must have 15 digit';
    }
  }
  if (values.office_npwp) {
    const ofp = values.office_npwp;
    const patt1 = /[0-9]/g;
    const result = ofp.match(patt1);
    if (result.length < 15) {
      errors.office_npwp = 'Must have 15 digit';
    }
  }
  if (!values.city) {
    errors.city = 'City field shouldn’t be empty';
  }
  if (!values.subdistrict) {
    errors.subdistrict = 'Subdistrict field shouldn’t be empty';
  }
  if (!values.village) {
    errors.village = 'Village field shouldn’t be empty';
  }
  if (!values.email) {
    errors.email = 'Email field shouldn’t be empty';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.province) {
    errors.province = 'Province field shouldn’t be empty';
  }
  if (!values.postal_code) {
    errors.postal_code = 'Postal Code shouldn’t be empty or value is not valid';
  } else {
    const pc = values.postal_code;
    if (pc.length < 5) {
      errors.postal_code = 'Must have 5 digit';
    }
  }

  if (values.id_tku?.length) {
    if (values.id_tku?.length < 22) {
      errors.id_tku = 'Must have 22 digit';
    }
  }
  if (values.office_tin?.length) {
    if (values.office_tin?.length < 16) {
      errors.office_tin = 'Must have 16 digit';
    }
  }

  return errors;
};

/* const validate = (values) => {
  const arrField = ['office', 'address', 'city', 'subdistrict', 'village', 'province', 'postal_code', 'office_npwp'];
  return isValidation(arrField, values);
}; */

export default reduxForm({
  form: 'orgofficeforms',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(OfficeSettingForm));
