/* eslint-disable no-dupe-keys */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable quote-props */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';
import axios from 'axios';
import ls from 'local-storage';
import showNotifications from '../../../Preferences/notificationMessages';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import ReactExport from 'react-export-excel';
import { jsonToCSV } from 'react-papaparse';
import { CSVLink, CSVDownload } from 'react-csv';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Spinner from 'react-bootstrap/Spinner';
import FileSaver from 'file-saver';
import { security } from '../../../../security';

class ReportTaxMonthly extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    // onProcess: PropTypes.func.isRequired,
    // destroy: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { url, t } = props;
    this.state = {
      apiws: '',
      token: '',
      idUser: '',
      arrPeriod: [],
      urlData: '',
      orgorganization: [],
      company: [],
      office: [],
      empcurrent: [],
      search: '',
      org: '',
      dataexcel: '',
      type: '',
      csvData: '',
      arrYear: [],
      download: false,
      displayTable: 'none',
      displayLoad: 'none',
      displaySave: '',
      condYear: '',
      condPypId: '',
      condEmpId: '',
      condTaxValue: '',
      // npwp_comp: [],
      condOffice: '',
      type: '',
      dataexcel: [],
      headerexcel: [],
      month: [],
      component: [],
      pyplist: [],
      datarekap: [],
    };
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.TAX_PERIOD'),
      t('LBL.EMPLOYEE'),
      t('LBL.TAX_YEAR'),
      t('LBL.RECTIFICATION'),
      t('LBL.NPWP'),
      t('LBL.NAME'),
      t('LBL.TAX_CODE'),
      t('LBL.BRUTO_TOTAL'),
      t('LBL.PPH_TOTAL'),
      t('LBL.COUNTRY_CODE'),
    ];
    this.loadSelectBox = this.loadSelectBox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getPeriodProcess = this.getPeriodProcess.bind(this);
    this.getYear = this.getYear.bind(this);
    this.getReport = this.getReport.bind(this);
    this.downloadTemplate = this.downloadTemplate.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') });
    this.setState({ idUser: ls.get('user_cred').usr_id }, () => {
      this.loadSelectBox();
      this.getPeriodProcess();
      this.getYear();
      this.getTableData();
      this.getMonth();
    });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      t('LBL.NUMBER'),
      t('LBL.TAX_PERIOD'),
      t('LBL.EMPLOYEE'),
      t('LBL.TAX_YEAR'),
      t('LBL.RECTIFICATION'),
      t('LBL.NPWP'),
      t('LBL.NAME'),
      t('LBL.TAX_CODE'),
      t('LBL.BRUTO_TOTAL'),
      t('LBL.PPH_TOTAL'),
      t('LBL.COUNTRY_CODE'),
    ];
  }

  getTableData() {
    this.child.reload();
    this.setState({ displayTable: '', displayLoad: 'none', displaySave: '' });
  }

  getYear() {
    const arr = [];
    for (let i = 0; i < 5; i += 1) {
      const currentYear = new Date().getFullYear() - i;
      arr.push({ value: currentYear, label: currentYear });
    }
    this.setState({ arrYear: arr });
  }

  getMonth() {
    const { t } = this.props;
    const monthNames = [
      `${t('LBL.JANUARY')}`,
      `${t('LBL.FEBRUARY')}`,
      `${t('LBL.MARCH')}`,
      `${t('LBL.APRIL')}`,
      `${t('LBL.MAY')}`,
      `${t('LBL.JUNE')}`,
      `${t('LBL.JULI')}`,
      `${t('LBL.AUGUST')}`,
      `${t('LBL.SEPTEMBER')}`,
      `${t('LBL.OCTOBER')}`,
      `${t('LBL.NOVEMBER')}`,
      `${t('LBL.DESEMBER')}`,
    ];
    const comp = [
      'Ph Bruto',
      'Pajak Bulan ini',
      'Ph Bruto Bulan ini',
      'Pajak S/d Bulan ini',
      'PPh DTP',
    ];
    this.setState({ month: monthNames, component: comp });
  }

  // getNpwpCompany(id) {
  //   console.log(id);
  //   const { token, apiws } = this.state;
  //   const config = {
  //     headers: {
  //       'Authorization': `bearer ${token}`,
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //   };

  //   axios.get(
  //     `${apiws}/orgoffice/getAllItemById/${id}`,
  //     config,
  //   ).then((res) => {
  //     const dataResponse = res.data.data;
  //     if (res.data.status === 'ok') {
  //       const arr = [];
  //       dataResponse.map(obj => (
  //         arr.push({ value: obj.off_id, label: obj.office_npwp })
  //       ));
  //       this.setState({ npwp_comp: arr });
  //     }
  //   }).catch((error) => {
  //     console.log(error);
  //   });
  // }

  getReport() {
    const { apiws, token } = this.state;
    const url = `${apiws}/reportpayroll/reportTaxMonthly/`;

    this.setState({
      displayTable: 'none',
      displayLoad: 'none',
      displaySave: '',
    });

    const config = {
      // responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'blob',
      },
    };

    const conds = {
      pyp_id: this.state.condPypId,
      year: this.state.condYear,
      emp_id: this.state.condEmpId,
      tax_value: this.state.condTaxValue,
      office: this.state.condOffice,
      type: this.state.type,
    };

    axios
      .get(url, {
        ...config,
        params: conds,
      })
      .then((response) => {
        // console.log(security.decrypt('EXCEL_LINK', res.data.url));
        // return;
        showNotifications('Success', 'Load Success', 'success');
        axios({
          method: 'get',
          url: security.decrypt('EXCEL_LINK', response.data.url),
          responseType: 'blob',
        })
          .then((res) => {
            console.log(res);
            FileSaver.saveAs(res.data, response?.data?.name);
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }

  getPeriodProcess() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/payperiod/getAllItemTax`, '', config)
      .then((response) => {
        const pcs = response.data.data;
        const array = [];
        if (pcs.length === 0) {
          this.setState({ arrPcs: array });
        } else {
          pcs.map((obj) =>
            array.push({
              value: obj.pyp_id,
              label: obj.period_code,
              start: obj.period_start,
              end: obj.period_end,
              status: obj.is_process_tax,
            }),
          );
          pcs.map((obj) => this.state.pyplist.push(obj.pyp_id));
          this.setState({ arrPcs: array });
        }
      });
  }

  downloadTemplate() {
    const headrequire = [
      'No',
      'Employee Code',
      'Employee Name',
      'Country Code',
      'No NPWP',
      'NPWP Date',
      'Join Date',
      'Resign Date ',
      'January',
      'February',
    ];
    const value = [
      {
        No: '1',
        'Employee Code': 'S1090190',
        'Employee Name': 'Ajeng Ff',
        'Country Code': 'INA',
        'No NPWP': '28931238',
        'NPWP Date': '29-10-2020',
        'Join Date': '29-10-2020',
        'Resign Date': '10-10-2020',
        January: 'Ph Bruto',
      },
    ];
    setTimeout(() => {
      this.setState({ headerexcel: headrequire, templatevalue: value });
    }, 200);
  }

  loadSelectBox() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .get(`${apiws}/orgoffice/getAll/`, config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.off_id, label: obj.office }),
          );
          this.setState({ office: Arr });
        }
      })
      .catch(() => {
        // console.log(error);
      });

    axios
      .post(`${apiws}/employee/getAllItemOrg/0`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({
              value: obj.emp_id,
              label: obj.employee,
              label2: obj.emp_code,
            }),
          );
          this.setState({ empcurrent: Arr });
        }
      })
      .catch((error) => {
        // console.log(error);
      });

    axios
      .post(`${apiws}/orgoffice/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.off_id, label: obj.office }),
          );
          this.setState({ office: Arr });
        }
      })
      .catch(() => {
        // console.log(error);
      });

    axios
      .post(`${apiws}/orgorganization/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.org_id, label: obj.unit_organization }),
          );
          this.setState({ orgorganization: Arr });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  handleClick = (e) => {
    e.preventDefault();
  };

  handleSubmit(values) {
    this.setState({ displayLoad: '', displaySave: 'none' });
    // console.log(values);
    const { apiws, token } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const url = `${ls.get('ws_ip')}/reportpayroll/getReporttaxmonthly`;
    const period =
      typeof values.period === 'undefined' || values.period === null
        ? ''
        : values.period.value;
    const year =
      typeof values.period_year === 'undefined' || values.period_year === null
        ? ''
        : values.period_year.value;
    const empId =
      typeof values.employee === 'undefined' || values.employee === null
        ? ''
        : values.employee.value;
    const type = values.type_report.value;
    const taxval =
      typeof values.tax_value === 'undefined' || values.tax_value === null
        ? ''
        : values.tax_value.value;
    const office =
      typeof values.office === 'undefined' || values.office === null
        ? ''
        : values.office.value;
    const valperiod = year === '' && period === '' ? false : true;
    if (valperiod) {
      const pypId = period;
      setTimeout(() => {
        this.setState({ condYear: year });
        this.setState({ condPypId: pypId });
        this.setState({ condEmpId: empId });
        this.setState({ condTaxValue: taxval });
        this.setState({ condOffice: office });
        this.setState({ type: type });
        this.setState({ urlData: url });
        setTimeout(() => {
          if (values.type_report.value === '1') {
            this.setState({ displayTable: '' });
            setTimeout(() => {
              showNotifications('Success', 'Load Success', 'success');
            }, 1000);
            setTimeout(() => {
              this.getTableData();
            }, 200);
          } else if (
            values.type_report.value === '2' ||
            values.type_report.value == '3'
          ) {
            this.setState({ displayTable: 'none' });
            this.getReport();
          }
        }, 1000);
      }, 100);
    }
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      csvData,
      arrYear,
      office,
      arrPcs,
      empcurrent,
      urlData,
      condYear,
      condPypId,
      displayTable,
      displayLoad,
      displaySave,
      condEmpId,
      condTaxValue,
      condOffice,
    } = this.state;
    return (
      <Container>
        <Row style={{ paddingLeft: '20px' }}>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody className="p0">
                <Row className="m5">
                  <Col md={12}>
                    <h3 className="page-title">
                      {t('LBL.TAX')} - {t('MEN.REPORT')}
                    </h3>
                    <h3 className="page-subhead subhead">
                      {t('LBL.EXPORT_TAX_TO_EXCEL')}
                    </h3>
                  </Col>
                </Row>
                <Row className="m5">
                  <Col md={12} xs={12} lg={12} xl={12}>
                    <form
                      className="form form--horizontal mt15"
                      name="taxmonthly"
                      id="taxmonthly"
                      onSubmit={handleSubmit(this.handleSubmit)}
                    >
                      <Container>
                        {/* <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">{t('LBL.YEAR')}</span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="period_year"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrYear}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row> */}
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.PERIOD')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="period"
                                  component={renderSelectField}
                                  type="text"
                                  options={arrPcs}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.FORMAT_REPORT')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="format_report"
                                  component={renderSelectField}
                                  type="text"
                                  options={[
                                    { value: '1', label: 'Monthly Tax Report' },
                                    { value: '2', label: 'Bukti Potong A1' },
                                  ]}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row> */}
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.EMPLOYEE_NAME')}
                              </span>
                              <div className="form__form-group-field products-list__search">
                                <Field
                                  name="employee"
                                  component={renderSelectField}
                                  type="text"
                                  options={empcurrent}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} xs={6} lg={6} xl={6}>
                            <div className="form__form-group form__form-group--address">
                              <span className="form__form-group-label">
                                {t('LBL.OFFICE')}
                              </span>
                              <div className="form__form-group-field form-flex">
                                <Field
                                  name="company"
                                  component={renderSelectField}
                                  type="text"
                                  options={office}
                                  // onChange={val => (this.getNpwpCompany(val.value))}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} lg={6} xl={6}>
                            {/* <div className="form__form-group">
                              <span className="form__form-group-label">{t('LBL.NPWP')} {t('LBL.COMPANY')}</span>
                              <div className="form__form-group-field">
                                <Field
                                  name="bank"
                                  component={renderSelectField}
                                  type="text"
                                  options={npwp_comp}
                                />
                              </div>
                            </div> */}
                            <Row>
                              <Col md="12" lg="12" xl="12" sm="12">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    {t('LBL.TAX_VALUE')}
                                  </span>
                                  <div className="form__form-group-field">
                                    <Field
                                      name="tax_value"
                                      component={renderSelectField}
                                      type="text"
                                      options={[
                                        { value: '1', label: 'All Employee' },
                                        {
                                          value: '2',
                                          label: 'Employee with tax value > 0',
                                        },
                                        {
                                          value: '3',
                                          label: 'Employee with tax value = 0',
                                        },
                                      ]}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="12" md="12" xl="12" lg="12">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    {t('LBL.RECTIFICATION')}
                                  </span>
                                  <div className="form__form-group-field">
                                    <Container className="pl-pr-0">
                                      <Row>
                                        <Col sm="12" md="12" lg="12" xl="12">
                                          <Container className="pl-pr-0">
                                            <Row>
                                              <Col sm="12" md="6" lg="6" xl="6">
                                                <div className="form__form-group">
                                                  <div className="form__form-group-field form_custom_w100">
                                                    <Field
                                                      name="radioLeaveAllowance"
                                                      component={
                                                        renderRadioButtonField
                                                      }
                                                      label={t('LBL.YES')}
                                                      radioValue="1"
                                                    />
                                                  </div>
                                                </div>
                                              </Col>
                                              <Col sm="12" md="6" lg="6" xl="6">
                                                <div className="form__form-group">
                                                  <div className="form__form-group-field form_custom_w100">
                                                    <Field
                                                      name="radioLeaveAllowance"
                                                      component={
                                                        renderRadioButtonField
                                                      }
                                                      label={t('LBL.NO')}
                                                      radioValue="0"
                                                    />
                                                  </div>
                                                </div>
                                              </Col>
                                            </Row>
                                          </Container>
                                        </Col>
                                      </Row>
                                    </Container>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} xs={12} lg={12} xl={12}>
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    {t('LBL.TYPE_REPORT')}
                                  </span>
                                  <div className="form__form-group-field">
                                    <Field
                                      name="type_report"
                                      component={renderSelectField}
                                      type="text"
                                      options={[
                                        { value: '1', label: 'View' },
                                        { value: '2', label: 'Excel' },
                                        {
                                          value: '3',
                                          label: 'Excel (New Format)',
                                        },
                                      ]}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                          <Button
                            color="primary"
                            type="submit"
                            style={{ display: displaySave }}
                          >
                            {t('FRM.SUBMIT')}{' '}
                          </Button>
                          <Button
                            color="primary"
                            style={{ display: displayLoad }}
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{' '}
                            {t('FRM.LOADING')}
                          </Button>
                        </ButtonToolbar>
                      </Container>
                      {this.state.download === true && (
                        <CSVLink
                          filename="1721-l-bulanan.csv"
                          target="_blank"
                          data={csvData}
                        >
                          <input
                            type="hidden"
                            ref={(input) => (this.inputElement = input)}
                          />
                        </CSVLink>
                      )}
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{ display: displayTable }}>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ paddingLeft: '20px' }}>
                <Row className="m5">
                  <div className="card__title" style={{ paddingLeft: '15px' }}>
                    <h5 className="bold-text">{t('LBL.MONTHLY_REPORT')}</h5>
                  </div>
                  <ViewListTable
                    url={urlData}
                    heads={this.heads}
                    // primaryKey="pyp_id"
                    // widthTable={this.state.dataWidth}
                    buttonAction="noButton"
                    conds={{
                      pyp_id: condPypId,
                      year: condYear,
                      emp_id: condEmpId,
                      tax_value: condTaxValue,
                      office: condOffice,
                      type: '1',
                    }}
                    onRef={(ref) => (this.child = ref)}
                    displayStyleHead="none"
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.type_report) {
    errors.type_report = 'Type report field shouldn’t be empty';
  }
  if (!values.period) {
    errors.period = 'Period report field shouldn’t be empty';
  }
  // if (!values.format_report) {
  //   errors.format_report = 'Format report field shouldn’t be empty';
  // }
  return errors;
};

export default reduxForm({
  form: 'taxmonthly', // a unique identifier for this form
  validate,
})(translate('global')(ReportTaxMonthly));
