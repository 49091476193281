/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCoffee,
  faSync,
  faTimes,
  faClock,
  faFileImage,
  faFilePdf,
} from '@fortawesome/fontawesome-free-solid';
import ls from 'local-storage';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import axios from 'axios';
import showNotifications from '../../../../containers/Preferences/notificationMessages';
import Swal from 'sweetalert2';
import renderSelectField from '../../../../shared/components/form/Select';
import { translate } from 'react-i18next';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import renderTextInput from '../../../../shared/components/form/TextInput';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import utils from '../../../../utils';
import renderNumberFormat from '../../../../shared/components/form/NumberFormat';

const usedDate = [];
let translator = () => {};

class viewClaimList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    parmid: PropTypes.string.isRequired,
    pristine: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    translator = t;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.CLAIM_NAME') },
      { 2: t('LBL.CLAIM_DATE') },
      { 3: t('LBL.CLAIM_AMOUNT') },
      { 6: t('FRM.FUNCTION') },
    ];

    this.state = {
      // rows: this.createRows(3),
      // pageOfItems: [],
      token: '',
      apiws: '',
      urlData: '',
      dataWidth: ['5%', '15%', '20%', '20%', '15%', '15%', '10%'],
      emp_id: '',
      redirect: false,
      addcond: '',
      editcond: '',
      deletecond: '',
      isEditing: false,
      isEdited: false,
      attachment: [],
      arrClaimType: [],
      claim_value: '',
    };

    this.getTableData = this.getTableData.bind(this);
    this.getUsedLeave = this.getUsedLeave.bind(this);
    this.getClaimType = this.getClaimType.bind(this);
    this.deleteClaimHitory = this.deleteClaimHitory.bind(this);
    // this.editClaimHistory = this.editClaimHistory.bind(this);
    this.toggle = this.toggle.bind(this);
    this.checkAttachment = this.checkAttachment.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.getLeavecategory = this.getLeavecategory.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    // this.getEmpid = this.getEmpid.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.linkBack = this.linkBack.bind(this);
    this.getButton = this.getButton.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ emp_id: this.props.parmid.state.usrid });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getLeavecategory();
      this.getEmployee();
      this.getUsedLeave();
      this.getClaimType();
      this.getButton();
    });
    this.setState({
      urlData: `${ls.get('ws_ip')}/empclaimhistory/getAllItem/`,
    });
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.CLAIM_NAME') },
      { 2: t('LBL.CLAIM_DATE') },
      { 3: t('LBL.CLAIM_AMOUNT') },
      { 4: t('FRM.FUNCTION') },
    ];
  }

  getTableData() {
    this.child.reload();
  }

  getUsedLeave() {
    const { token, apiws, emp_id } = this.state;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .post(`${apiws}/empleavehistory/getAllItemByEmpId/${emp_id}`, '', config)
      .then((res) => {
        const list = res.data.data;
        list.map((val, i) => {
          const splittedStart = val.start_date.split('-');
          const splittedEnd = val.end_date.split('-');
          usedDate.push({
            start: new Date(
              splittedStart[0],
              parseInt(splittedStart[1], 10) - 1,
              splittedStart[2],
            ),
            end: new Date(
              splittedEnd[0],
              parseInt(splittedEnd[1], 10) - 1,
              splittedEnd[2],
            ),
          });
        });
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getClaimType() {
    const { apiws, emp_id } = this.state;
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(
        `${apiws}/appreimbursement/getAllowedClaim/`,
        `emp_id=${emp_id}`,
        config,
      )
      .then((res) => {
        const claim = res.data.data;
        const array = [];
        if (claim.length === 0) {
          this.setState({ arrClaimType: array });
        } else {
          claim.map((obj) =>
            array.push({ value: obj.ect_id, label: obj.claim_name }),
          );
          this.setState({ arrClaimType: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getButton() {
    const men = this.props.parmid.state.men_id;
    const fga = this.props.parmid.state.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });

          console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getEmploymentStatus() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/rtbemployeementstatus/getAll/`, '', config)
      .then((resEmpStatus) => {
        const emp = resEmpStatus.data.data;
        const array = [];
        if (emp.length === 0) {
          this.setState({ arrEmpStatus: array });
        } else {
          emp.map((obj) =>
            array.push({
              value: obj.ems_id,
              label: obj.ems_employeement_status,
            }),
          );
          this.setState({ arrEmpStatus: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getEmployee() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const empid = this.state.emp_id;
    axios
      .post(
        `${ls.get('ws_ip')}/Employee/getAllItemExceptedEmp/${empid}`,
        '',
        config,
      )
      .then((resemp) => {
        const emp = resemp.data.data;
        const array = [];
        if (emp.length === 0) {
          this.setState({ arrEmp: array });
        } else {
          emp.map((obj) =>
            array.push({ value: obj.emp_id, label: obj.emp_name }),
          );
          this.setState({ arrEmp: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getLeavecategory() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/Attleave/getAll/`, '', config)
      .then((response) => {
        const wf = response.data.data;
        const array = [];
        if (wf.length === 0) {
          this.setState({ leavecategory: array });
        } else {
          wf.map((obj) =>
            array.push({ value: obj.atl_id, label: obj.leave_name }),
          );
          this.setState({ leavecategory: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  handleSubmit(values) {
    /* eslint-disable no-use-before-define */
    const { apiws, token, emp_id } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const error = false;
    const id = values.ech_id;
    // eslint-disable-next-line max-len
    const ect_id =
      typeof values.ect_id === 'undefined' || values.ect_id === null
        ? ''
        : values.ect_id.value || values.ect_id;
    const claim_date = moment(values.claim_date).format('YYYY-MM-DD');
    const { claim_value } = this.state;
    let url;
    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/empclaimhistory/saveItem`;
      data = {
        emp_id,
        ...values,
        ect_id,
        claim_value,
        claim_date,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/empclaimhistory/updateItem/${id}`;
      data = {
        emp_id,
        ...values,
        ect_id,
        claim_date,
        claim_value,
        muser_id: userid,
      };
    }

    const formBody = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
    }, new FormData());

    for (let ln = 0; ln < this.state.attachment.length; ln++) {
      if (typeof this.state.attachment[ln].erf_id === 'undefined') {
        formBody.append(`attachment_${ln}`, this.state.attachment[ln]);
      } else {
        formBody.append(`attachment_${ln}`, this.state.attachment[ln].erf_id);
      }
    }

    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    const isValid = !utils.isObjectExist(validate(values));
    if (!error && isValid) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            setTimeout(() => {
              this.toggle();
              this.child.reload();
              this.setState({ claim_value: 0 });
            }, 200);
          } else if (response.data.status === 'limit') {
            const limitMsg = `${this.props.t('LBL.CLAIM_LIMIT')} ${this.props.t(
              'LBL.EXCEED',
            )}`;

            this.setState({ displayLoad: 'none', displaySave: '' });
            showNotifications('Fail', limitMsg, 'danger');
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  // async editClaimHistory(id) {
  //   const { token, apiws } = this.state;
  //   const config = {
  //     headers: {
  //       Authorization: `bearer ${token}`,
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //   };
  //   await axios
  //     .post(`${apiws}/empclaimhistory/getAllItemById/${id}`, '', config)
  //     .then(async (response) => {
  //       if (response.data.status === 'ok') {
  //         const dataResponse = response.data.data;
  //         const fileResponse = response.data.file;
  //         const datamapp = {
  //           ech_id: dataResponse[0].ech_id,
  //           ecr_id:
  //             dataResponse[0].ecr_id === null ? '' : dataResponse[0].ecr_id,
  //           ect_id: dataResponse[0].ect_id,
  //           claim_date: moment(dataResponse[0].claim_date),
  //         };
  //         this.setState({ claim_value: dataResponse[0].claim_value });
  //         const files = [];
  //         fileResponse.map((f) =>
  //           files.push({
  //             erf_id: f.erf_id,
  //             name: f.file_name,
  //             type: f.file_type,
  //             path: f.file_path,
  //           }),
  //         );
  //         this.setState({ attachment: files }, () => {
  //           this.toggle();
  //           this.props.initialize(datamapp);
  //         });
  //       } else {
  //         showNotifications('Fail', 'Save Failed', 'danger');
  //       }
  //     })
  //     .catch(() => {
  //       // console.log(error);
  //     });
  // }

  checkAttachment(e) {
    const file = e.target.files[0];
    const allowedType = [
      'application/pdf',
      'image/gif',
      'image/jpeg',
      'image/png',
    ];
    const allowedSize = 1000; // in kilobytes
    if (!allowedType.includes(file.type)) {
      Swal.fire({
        title: 'Warning',
        text: 'File type not allowed!',
        type: 'warning',
        showCloseButton: true,
        closeButtonColor: '#3085d6',
        reverseButtons: true,
      });
    } else if (Math.ceil(file.size / 1000) > allowedSize) {
      Swal.fire({
        title: 'Warning',
        text: 'Maximum file size exceeded!',
        type: 'warning',
        showCloseButton: true,
        closeButtonColor: '#3085d6',
        reverseButtons: true,
      });
    } else {
      this.setState({
        attachment: [...this.state.attachment, file],
        isEdited: true,
      });
    }
  }

  removeAttachment(pos) {
    const list = this.state.attachment;
    list.splice(pos, 1);
    this.setState({
      attachment: list,
      trigger: !this.state.trigger,
      isEdited: true,
    });
  }

  async downloadAttachment(pos) {
    const list = this.state.attachment;
    const objectURL = `${ls.get('ws_ip')}/public/uploads${list[pos].path}`;
    /* eslint-disable global-require */
    // eslint-disable-next-line import/no-extraneous-dependencies
    const fetch = require('node-fetch');
    /* eslint-enable global-require */
    const imageUrl = objectURL;
    const imageUrlData = await fetch(imageUrl);
    const buffer = await imageUrlData.buffer();
    const contentType = await imageUrlData.headers.get('content-type');
    const imageBas64 = `data:image/${contentType};base64,${buffer.toString(
      'base64',
    )}`;
    const a = document.createElement('a');
    a.href = imageBas64;
    a.download = list[pos].name;
    a.click();
    console.log('a', a);
  }

  toggle() {
    this.props.destroy();
    if (this.state.modal === true) {
      this.setState({ attachment: [] });
    }
    this.setState({
      modal: !this.state.modal,
      isEditing: !this.state.isEditing,
    });
  }

  deleteClaimHitory(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/empclaimhistory/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;
    const {
      arrEmp,
      leavecategory,
      emp_id,
      arrClaimType,
      attachment,
      disabled,
      isEditing,
      isEdited,
    } = this.state;
    // const addBtn = (
    //   <Button
    //     color="primary"
    //     className="btn_table_navbar"
    //     onClick={() => this.toggle()}
    //     style={{
    //       float: 'right',
    //       'margin-bottom': '20px',
    //       display: this.state.addcond === '1' ? '' : 'none',
    //     }}
    //   >
    //     {t('FRM.ADD')} {t('LBL.CLAIM_REQUEST')}
    //   </Button>
    // );
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn-sm btn_table_navbar"
        onClick={this.linkBack}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          'margin-right': '10px',
        }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: '/administration/employee',
            state: {
              men_id: this.props.parmid.state.men_employee,
              fga_id: this.props.parmid.state.fga_id,
            },
          }}
        />
      );
    }
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="p0">
            <Row className="m5">
              <Col md={8}>
                <h3 className="page-title">{t('LBL.CLAIM_HISTORY')}</h3>
                <h3 className="page-subhead subhead">
                  {t('LBL.VIEW_CLAIM_HIST')}
                </h3>
              </Col>
              {/* <Col md={4}>
                <div className="btn_action_table_navbar">
                  <ButtonToolbar> */}
              {/* <Link className="btn btn-primary btn_table_navbar" to="/administration/leaverequest">
                      <FontAwesomeIcon icon={faCoffee} /> Leave Request
                    </Link> */}
              {/* <Button color="primary" icon={faCoffee} onClick={this.toggle}>Leave Request</Button>
                  </ButtonToolbar>
                </div>
              </Col> */}
            </Row>
            {/* <Row>
              <Col md={12} xs={12}>
                <div className="table_topnav">
                  <UncontrolledDropdown>
                    <DropdownToggle className="icon icon--right" outline>
                      <p>Period <ChevronDownIcon /></p>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown__menu">
                      <DropdownItem>2017</DropdownItem>
                      <DropdownItem>2018</DropdownItem>
                      <DropdownItem>2019</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <Button color="secondary">
                    <FontAwesomeIcon icon={faSync} />
                  </Button>
                </div>
              </Col>
            </Row> */}
            <Row>
              <Col md={12} xs={12} lg={12} xl={12}>
                <Card>
                  <div style={{ padding: '20px 5px 30px 10px' }}>
                    <ViewListTable
                      url={this.state.urlData}
                      heads={this.heads}
                      primaryKey="ech_id"
                      widthTable={this.state.dataWidth}
                      // updateFunc={this.editClaimHistory}
                      deleteFunc={this.deleteClaimHitory}
                      displayStyleHead="none"
                      onRef={(ref) => (this.child = ref)}
                      backBtn={backBtn}
                      // addBtn={addBtn}
                      editCond="2"
                      // addCond={this.state.addcond}
                      deleteCond={this.state.deletecond}
                      conds={{
                        emp_id,
                      }}
                      yearFilter
                    />
                  </div>
                </Card>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <Modal
                  isOpen={this.state.modal}
                  style={modalStyle}
                  className="modal-dialog modal-input"
                >
                  <ModalHeader>
                    {t('FRM.ADD')} {t('LBL.CLAIM_HISTORY')}
                  </ModalHeader>
                  <ModalBody>
                    <form
                      className="form form--horizontal"
                      onSubmit={handleSubmit(this.handleSubmit)}
                      name="formclaimhistory"
                      id="formclaimhistory"
                    >
                      <Field
                        name="ech_id"
                        component={renderTextInput}
                        type="hidden"
                      />
                      <Field
                        name="ecr_id"
                        component={renderTextInput}
                        type="hidden"
                      />
                      <Container>
                        <Row>
                          <Col md="8" lg="8" xl="8" sm="8">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.CLAIM_NAME')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="ect_id"
                                  component={renderSelectField}
                                  options={arrClaimType}
                                  disabled={disabled}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="8" lg="8" xl="8" sm="8">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.CLAIM_DATE')}
                              </span>
                              <div className="form__form-group-field">
                                <Row className="w-100">
                                  <Col md={8} sm={12}>
                                    <div className="form__form-group-field form-group-ml-pl-0 w-100">
                                      <Field
                                        name="claim_date"
                                        component={renderDatePickerField}
                                        disabled={disabled}
                                      />
                                      <div className="form__form-group-icon">
                                        <CalendarBlankIcon />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" lg="6" xl="6" sm="6">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.CLAIM_AMOUNT')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="claim_value"
                                  component={renderTextInput}
                                  type="number"
                                  disabled={disabled}
                                />
                                <Field
                                  name="claim_value"
                                  component={renderNumberFormat}
                                  other={{
                                    defaultValue: this.state.claim_value,
                                    value: this.state.claim_value,
                                    thousandSeparator: '.',
                                    decimalSeparator: ',',
                                    prefix: 'Rp ',
                                  }}
                                  onChange={(e) =>
                                    this.setState({
                                      claim_value: e.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12" lg="12" xl="12" sm="12">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.UPLOAD_FILE')}
                              </span>
                              <div
                                className="form__form-group-file"
                                style={{ paddingLeft: '20px' }}
                              >
                                {isEditing && (
                                  <label htmlFor="attachment">
                                    Choose the file
                                  </label>
                                )}
                                {isEditing && (
                                  <div>
                                    <input
                                      type="file"
                                      name="attachment"
                                      id="attachment"
                                      onChange={(e) => this.checkAttachment(e)}
                                    />
                                    <small>File type: png,jpg,jpeg,pdf</small>
                                    <br />
                                    <small>File size: 1Mb</small>
                                  </div>
                                )}
                                <div
                                  style={{
                                    border: '1px solid #ddd',
                                    borderRadius: '2pt',
                                    padding: '5px 10px',
                                  }}
                                >
                                  {attachment.map((file, pos) => (
                                    <div>
                                      <FontAwesomeIcon
                                        icon={
                                          file.type === 'application/pdf'
                                            ? faFilePdf
                                            : faFileImage
                                        }
                                      />
                                      {console.log('path', file.path)};
                                      <span>{file.name}</span>
                                      {isEditing && (
                                        <button
                                          type="button"
                                          onClick={() =>
                                            this.removeAttachment(pos)
                                          }
                                          className="btn btn-sm btn-outline-danger btn-data-table mx-1"
                                        >
                                          <i className="fas fa-times" />
                                        </button>
                                      )}
                                      {
                                        <button
                                          type="button"
                                          // onClick={e => download(e)}
                                          onClick={() =>
                                            this.downloadAttachment(pos)
                                          }
                                          className="btn btn-sm btn-outline-success btn-data-table mx-1"
                                        >
                                          {t('FRM.DOWNLOAD')}
                                        </button>
                                      }
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </form>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={this.toggle}>
                      {t('FRM.CANCEL')}
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      form="formclaimhistory"
                      disabled={pristine && !isEdited}
                    >
                      {t('FRM.SAVE')}
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row> */}
          </CardBody>
        </Card>
      </Col>
    );
  }
}
const validate = (values) => {
  const validation = {
    ect_id: {
      fieldLabel: translator('LBL.CLAIM_NAME'),
      required: true,
    },
    claim_date: {
      fieldLabel: `${translator('LBL.CLAIM_DATE')}`,
      required: true,
    },
    claim_value: {
      fieldLabel: `${translator('LBL.CLAIM_AMOUNT')}`,
      required: true,
    },
  };
  const errors = utils.validate(validation, values);
  return errors;
};

export default reduxForm({
  form: 'formclaimhistory', // a unique identifier for this form
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(viewClaimList));
