import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import renderSelectField from '../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderSelectCheckboxField from '../../../../shared/components/form/SelectChecbox';
import Loading from '../../../../shared/components/Loading';
import showNotifications from '../../../Preferences/notificationMessages';
import ls from 'local-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import { CalendarBlankIcon } from 'mdi-react';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import moment from 'moment';

let statusInterval = null;
let isStatusFetching = false;
const Generate = (props) => {
  const { toggle, showSidebar, t } = props;
  const [isLoading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState({
    title: null,
    subtitle: null,
  });

  const [formData, setFormData] = useState({
    type: '',
    atlIds: [],
    isAllEmployee: false,
    valid_date: null,
    employees: [],
  });

  const [errorMsg, setErrorMsg] = useState({
    leave_type: null,
    generate_type: null,
    valid_date: null,
    employee: null,
  });

  const [opts, setOpts] = useState({
    leaves: [],
    employees: [],
  });

  const wrapperRef = useRef(null);
  const validDateRef = useRef(null);

  useEffect(() => {
    initLeaveData();
  }, []);

  useEffect(() => {
    if (validDateRef.current) {
      validDateRef.current.style.gap = '0px !important';
      validDateRef.current.style.gridGap = '0px !important';
    }

    if (formData.type != '' && formData.atlIds.length > 0) {
      setTimeout(() => {
        handleGetEmployee();
      }, 1000);
    }
  }, [formData]);

  // const useOutsideAlerter = (ref) => {
  //   useEffect(() => {
  //     function handleClickOutside(event) {
  //       if (ref.current && !ref.current.contains(event.target)) {
  //         // toggle();
  //       }
  //     }
  //     document.addEventListener('mousedown', handleClickOutside);
  //     return () => {
  //       document.removeEventListener('mousedown', handleClickOutside);
  //     };
  //   }, [ref]);
  // };

  // useOutsideAlerter(wrapperRef);

  const initLeaveData = () => {
    const token = ls.get('token');
    const ws = ls.get('ws_ip');

    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .post(`${ws}/attleave/getAll`, '', config)
      .then((response) => {
        if (response.data.status == 'ok') {
          setOpts((prev) => ({
            ...prev,
            leaves: response?.data?.data?.map((v) => ({
              label: v.leave_name,
              value: v.atl_id,
            })),
          }));
        }
      })
      .then((err) => {
        console.log(err);
      });
  };

  const handleGetEmployee = () => {
    const token = ls.get('token');
    const ws = ls.get('ws_ip');

    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const data = {
      type: formData.type,
      atl_id: formData.atlIds.map((val) => val.value),
    };

    axios
      .post(`${ws}/empleavebalance/eligibleEmployeeGenerate`, data, config)
      .then((response) => {
        if (response.data.status === 'ok') {
          setOpts((prev) => ({
            ...prev,
            employees: response?.data?.data?.map((val) => ({
              label: `${val.emp_name} (${val.emp_code})`,
              value: val.emp_id,
            })),
          }));
        }
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const errors = validate({
      generate_type: formData.type,
      leave_type: formData.atlIds,
      is_all_employee: formData.isAllEmployee,
      valid_date: formData.valid_date,
      employee: formData.employees,
    });

    setErrorMsg(errors);
    if (Object.keys(errors).length > 0) return false;

    const token = ls.get('token');
    const ws = ls.get('ws_ip');

    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const data = {
      type: formData.type,
      atl_id: formData.atlIds.map((val) => val.value),
      is_all_emp: formData.isAllEmployee,
      valid_date:
        formData?.valid_date && formData.type == '2'
          ? moment(formData.valid_date).format('YYYY-MM-DD')
          : null,
      emp_ids: formData.isAllEmployee
        ? opts.employees.map((val) => val.value)
        : formData.employees.map((val) => val.value),
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to generate this leave?',
      type: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#ff4861',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Yes, generate!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        setLoadingMessage({
          title: 'Processing...',
          subtitle: 'Please wait...',
        });
        setLoading(true);
        axios
          .post(`${ws}/empleavebalance/generate`, data, config)
          .then((response) => {
            if (response.data.status === 'ok') {
              statusInterval = setInterval(() => {
                handleWatchStatus(response.data.session, data.emp_ids?.length);
              }, 1000);
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {});
      }
    });
  };

  const handleWatchStatus = (sessionId, empSelected) => {
    if (isStatusFetching) return false;

    const token = ls.get('token');
    const ws = ls.get('ws_ip');

    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    isStatusFetching = true;
    const data = {
      sessionId: sessionId,
    };

    axios
      .post(`${ws}/empleavebalance/generateStatus`, data, config)
      .then((response) => {
        isStatusFetching = false;

        if (response.data.status === 'ok' && statusInterval) {
          if (!response.data.isFinish) {
            setLoadingMessage((prev) => ({
              ...prev,
              subtitle: response.data.message,
            }));
          } else {
            setLoading(false);
            clearInterval(statusInterval);
            console.log('handleWatchStatus clear interval');
            setLoadingMessage({
              title: null,
              subtitle: null,
            });
            showNotifications(
              'Success',
              `${response.data.counting}/${empSelected} Leave successfully generated`,
              'success',
            );
            closeDrawer();
          }
        }
      })
      .then((err) => {
        isStatusFetching = false;
      });
  };

  const closeDrawer = useCallback(() => {
    toggle('generate');

    setFormData((prev) => ({
      ...prev,
      atlIds: [],
      isAllEmployee: false,
      valid_date: null,
      employees: [],
    }));

    setErrorMsg({
      leave_type: null,
      generate_type: null,
      valid_date: null,
      employee: null,
    });
  }, []);

  return (
    <>
      {showSidebar && (
        <div
          id="offcanvas-form-task"
          className="offcanvas fade show"
          style={{
            transition: 'opacity 0.9s ease-in-out',
          }}
        >
          <div className="offcanvas-dialog" ref={wrapperRef}>
            <div className="offcanvas-header p-3">
              <span className="off-title">{t('LBL.LEAVE_GENERATE')}</span>
              <button className="close" onClick={closeDrawer}>
                &times;
              </button>
            </div>
            <div className="offcanvas-body mt-0 p-3">
              {isLoading && (
                <Loading
                  isLoading={isLoading}
                  width="22vw"
                  title={loadingMessage.title}
                  subtitle={loadingMessage.subtitle}
                />
              )}
              <form
                id="formTask"
                method="post"
                className="form form--horizontal"
                onSubmit={handleSubmit}
              >
                <div className="form__form-group">
                  <span className="form__form-group-label mt-0">
                    {t('LBL.LEAVE_GENERATE_TYPE')}
                    <span class="text-danger">*</span>
                    {errorMsg.generate_type && (
                      <span className={`form__form-group-error text-danger`}>
                        {errorMsg.generate_type}
                      </span>
                    )}
                  </span>
                  <div className="form__form-group-field my-2">
                    <Field
                      name="generate_type"
                      id="generate_limit"
                      component={renderRadioButtonField}
                      defaultChecked={true}
                      label={t('LBL.LEAVE_GENERATE_FIRST')}
                      radioValue="1"
                      onChange={(e, val) => {
                        setFormData((prev) => ({ ...prev, type: val }));
                      }}
                    />
                    <Field
                      name="generate_type"
                      id="generate_limit2"
                      component={renderRadioButtonField}
                      label={t('LBL.LEAVE_GENERATE_REPEAT')}
                      radioValue="2"
                      onChange={(e, val) => {
                        setFormData((prev) => ({ ...prev, type: val }));
                      }}
                    />
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label mt-0">
                      {t('LBL.LEAVE_TYPE')}
                      <span class="text-danger">*</span>
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="leave_type"
                        component={renderSelectField}
                        className="custom-multi"
                        multi={true}
                        options={opts.leaves}
                        meta={{
                          touched: errorMsg.leave_type != null,
                          error: errorMsg.leave_type,
                        }}
                        input={{
                          value: formData.atlIds,
                          onChange: (val) => {
                            setFormData((prev) => ({
                              ...prev,
                              atlIds: val,
                              employees: [],
                            }));
                          },
                        }}
                      />
                    </div>
                  </div>

                  {formData.type == '2' && (
                    <div className="form__form-group">
                      <span className="form__form-group-label mt-0">
                        {t('LBL.LEAVE_START_DATE')}
                        <span class="text-danger">*</span>
                      </span>
                      <div
                        className="form__form-group-field"
                        ref={validDateRef}
                      >
                        <Field
                          className="right"
                          name="valid_date"
                          component={renderDatePickerField}
                          placeholderText={t('LBL.LEAVE_START_DATE')}
                          props={{ value: formData.valid_date ?? '' }}
                          meta={{
                            touched: errorMsg.valid_date != null,
                            error: errorMsg.valid_date,
                          }}
                          onChange={(date) => {
                            console.log('valid_date', date);
                            setFormData((prev) => ({
                              ...prev,
                              valid_date: date,
                            }));
                          }}
                        />
                        <div className="form__form-group-icon">
                          <CalendarBlankIcon />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="form__form-group">
                    <div className="form__form-group-label mt-0">
                      {t('LBL.EMPLOYEE')}
                      <span class="text-danger"> *</span>
                    </div>
                    <div className="align-items-center">
                      <Field
                        name="is_all_employee"
                        component={renderCheckBoxField}
                        label="All Select"
                        defaultChecked={formData.isAllEmployee}
                        onChange={(e, val) => {
                          setFormData((prev) => ({
                            ...prev,
                            isAllEmployee: val,
                            employees: [],
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label mt-0"></span>
                    <div className="form__form-group-field">
                      <Field
                        name="employee"
                        component={renderSelectCheckboxField}
                        className="custom-multi"
                        multi={true}
                        options={opts.employees}
                        input={{
                          value: formData.employees,
                          onChange: (val) => {
                            setFormData((prev) => ({
                              ...prev,
                              employees: val,
                            }));
                          },
                        }}
                        meta={{
                          touched: errorMsg.employee != null,
                          error: errorMsg.employee,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="offcanvas-footer">
                  <div className="offcanvas-footer-btn-group">
                    <button
                      type="button"
                      className="btn-cancel"
                      onClick={closeDrawer}
                    >
                      {t('FRM.CANCEL')}
                    </button>
                    <button type="submit" className="btn-save">
                      {t('FRM.SUBMIT')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.generate_type) {
    errors.generate_type = 'Please choose generate type';
  } else {
    if (values.generate_type == '2' && !values.valid_date) {
      errors.valid_date = 'Please enter start valid date';
    }
  }

  if (values.leave_type?.length == 0) {
    errors.leave_type = 'Please choose at least 1 leave type';
  }

  if (!values.is_all_employee && values.employee?.length == 0) {
    errors.employee = 'Please choose at least 1 employee';
  }

  console.log(errors);
  return errors;
};

export default reduxForm({
  form: 'leaveGenerate',
  enableReinitialize: true,
  destroyOnUnmount: true,
})(translate('global')(Generate));
