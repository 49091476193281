/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import ls from 'local-storage';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import axios from 'axios';
import showNotifications from '../../../../containers/Preferences/notificationMessages';
import Swal from 'sweetalert2';
import renderSelectField from '../../../../shared/components/form/Select';
import { translate } from 'react-i18next';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import MinusIcon from 'mdi-react/MinusIcon';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import utils from '../../../../utils';

let usedDate = [];
const leaveReq = [];
let translator = () => {};

class viewLeaveList extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    parmid: PropTypes.string.isRequired,
    pristine: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    translator = t;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.LEAVE_TYPE') },
      { 2: t('LBL.START_DATE') },
      { 3: t('LBL.END_DATE') },
      { 4: t('LBL.TOTAL') },
      { 5: t('FRM.FUNCTION') },
    ];

    this.state = {
      // rows: this.createRows(3),
      // pageOfItems: [],
      token: '',
      apiws: '',
      urlData: '',
      dataWidth: ['5%', '15%', '20%', '20%', '15%', '15%', '10%'],
      emp_id: '',
      redirect: false,
      addcond: '',
      editcond: '',
      deletecond: '',
    };

    this.getTableData = this.getTableData.bind(this);
    this.getUsedLeave = this.getUsedLeave.bind(this);
    this.deleteLeaveRequest = this.deleteLeaveRequest.bind(this);
    // this.editLeaveRequest = this.editLeaveRequest.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getLeavecategory = this.getLeavecategory.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    // this.getEmpid = this.getEmpid.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.linkBack = this.linkBack.bind(this);
    this.getButton = this.getButton.bind(this);
    this.getLeaveReqDate = this.getLeaveReqDate.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getLeavecategory();
      this.getEmployee();
      this.getUsedLeave();
      this.getButton();
      this.getLeaveReqDate();
    });

    this.setState({
      urlData: `${ls.get('ws_ip')}/empleavehistory/getAllItem/`,
    });
    if (typeof this.props.parmid.state.usrid !== 'undefined') {
      this.setState({ emp_id: this.props.parmid.state.usrid });
    }

    // console.log(this.props.parmid);
    // console.log(this.state.emp_id);
    // console.log(this.props.parmid.state.usrid);
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.LEAVE_TYPE') },
      { 2: t('LBL.START_DATE') },
      { 3: t('LBL.END_DATE') },
      { 4: t('LBL.TOTAL') },
      { 5: t('FRM.FUNCTION') },
    ];
    this.getUsedLeave();
  }

  getTableData() {
    this.child.reload();
  }

  getUsedLeave() {
    const { token, apiws, emp_id } = this.state;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    usedDate = [];
    axios
      .post(`${apiws}/empleavehistory/getAllItemByEmpId/${emp_id}`, '', config)
      .then((res) => {
        const list = res.data.data;
        list.map((val, i) => {
          const splittedStart = val.start_date.split('-');
          const splittedEnd = val.end_date.split('-');
          usedDate.push({
            start: new Date(
              splittedStart[0],
              parseInt(splittedStart[1], 10) - 1,
              splittedStart[2],
            ),
            end: new Date(
              splittedEnd[0],
              parseInt(splittedEnd[1], 10) - 1,
              splittedEnd[2],
            ),
          });
        });
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getButton() {
    const men = this.props.parmid.state.men_id;
    const fga = this.props.parmid.state.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });

          // console.log(array);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getEmploymentStatus() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/rtbemployeementstatus/getAll/`, '', config)
      .then((resEmpStatus) => {
        const emp = resEmpStatus.data.data;
        const array = [];
        if (emp.length === 0) {
          this.setState({ arrEmpStatus: array });
        } else {
          emp.map((obj) =>
            array.push({
              value: obj.ems_id,
              label: obj.ems_employeement_status,
            }),
          );
          this.setState({ arrEmpStatus: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getEmployee() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const empid = this.state.emp_id;
    axios
      .post(
        `${ls.get('ws_ip')}/Employee/getAllItemExceptedEmp/${empid}`,
        '',
        config,
      )
      .then((resemp) => {
        const emp = resemp.data.data;
        const array = [];
        if (emp.length === 0) {
          this.setState({ arrEmp: array });
        } else {
          emp.map((obj) =>
            array.push({ value: obj.emp_id, label: obj.emp_name }),
          );
          this.setState({ arrEmp: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getLeavecategory() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const empid = this.state.emp_id;

    axios
      .post(`${ls.get('ws_ip')}/Attleave/getByEmp/${empid}`, '', config)
      .then((response) => {
        const wf = response.data.data;
        const array = [];
        if (wf.length === 0) {
          this.setState({ leavecategory: array });
        } else {
          wf.map((obj) =>
            array.push({ value: obj.atl_id, label: obj.leave_name }),
          );
          this.setState({ leavecategory: array });
        }
        // console.log(wf);
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getLeaveReqDate() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const { emp_id } = ls.get('user_cred');
    const dataBody = {
      emp_id,
    };
    const formBody = Object.keys(dataBody)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(dataBody[key])}`,
      )
      .join('&');
    // console.log(formBody);
    axios
      .post(
        `${ls.get('ws_ip')}/Empleaverequest/getLeavereqExist`,
        formBody,
        config,
      )
      .then((response) => {
        const dataReq = response.data.data.datacase;
        const dataHist = response.data.data.datahist;
        const Arr = [];
        if (dataReq.length !== 0) {
          dataReq.map((obj) => {
            leaveReq.push({
              leave_start: obj.leave_start,
              leave_end: obj.leave_end,
            });
          });
        }
        console.log(leaveReq);
      })
      .catch(() => {
        // console.log(error);
      });
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  handleSubmit(values) {
    /* eslint-disable no-use-before-define */
    const { apiws, token, emp_id } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const error = true;
    const id = values.elv_id;
    const atl_id =
      typeof values.leave_category === 'undefined' ||
      values.leave_category === null
        ? ''
        : values.leave_category.value || values.leave_category;
    let url;
    let data;
    console.log(values);
    if (id === undefined || id === '') {
      url = `${apiws}/empleavehistory/saveItem`;
      data = {
        atl_id,
        emp_id,
        ...values,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/empleavehistory/updateItem/${id}`;
      data = {
        atl_id,
        emp_id,
        ...values,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');

    console.log(formBody);

    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // const isValid = !utils.isObjectExist(validate(values));

    if (error) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            setTimeout(() => {
              this.props.destroy();
              this.setState({ modal: false });
              this.child.reload();
            }, 200);
          } else if (response.data.status === 'failed') {
            showNotifications('Fail', 'Save Failed', 'danger');
          } else {
            showNotifications('Fail', response.data.status, 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }
  // async editLeaveRequest(id) {
  //   const { token, apiws } = this.state;
  //   const config = {
  //     headers: {
  //       Authorization: `bearer ${token}`,
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //   };
  //   await axios
  //     .post(`${apiws}/empleavehistory/getAllItemById/${id}`, '', config)
  //     .then(async (response) => {
  //       if (response.data.status === 'ok') {
  //         const getResponseData = response.data.data;
  //         const datamaping = {
  //           elv_id: getResponseData[0].elv_id,
  //           leave_category: getResponseData[0].atl_id,
  //           start_date: moment(getResponseData[0].start_date),
  //           end_date: moment(getResponseData[0].end_date),
  //           remarks: getResponseData[0].remarks,
  //         };
  //         this.setState({ statusEdit: true });
  //         this.setState({ modal: true }, () => {
  //           this.props.destroy();
  //           this.props.initialize(datamaping);
  //         });
  //       } else {
  //         showNotifications('Fail', 'Save Failed', 'danger');
  //       }
  //     })
  //     .catch(() => {
  //       // console.log(error);
  //     });
  // }

  toggle() {
    this.props.destroy();
    this.setState({ modal: !this.state.modal });
  }

  deleteLeaveRequest(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/empleavehistory/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  render() {
    const { handleSubmit, t, pristine } = this.props;
    const { arrEmp, leavecategory, emp_id } = this.state;
    // const addBtn = (
    //   <Button
    //     color="primary"
    //     className="btn_table_navbar"
    //     onClick={() => this.toggle()}
    //     style={{
    //       float: 'right',
    //       'margin-bottom': '20px',
    //       display: this.state.addcond === '1' ? '' : 'none',
    //     }}
    //   >
    //     {t('FRM.ADD')} {t('LBL.LEAVE_HISTORY')}
    //   </Button>
    // );
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn-sm btn_table_navbar"
        onClick={this.linkBack}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          'margin-right': '10px',
        }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: '/administration/employee',
            state: {
              men_id: this.props.parmid.state.men_employee,
              fga_id: this.props.parmid.state.fga_id,
            },
          }}
        />
      );
    }
    const modalStyle = {
      maxWidth: '800px',
      textAlign: 'center',
    };
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="p0">
            <Row className="m5">
              <Col md={8}>
                <h3 className="page-title">{t('LBL.LEAVE_LIST')}</h3>
                <h3 className="page-subhead subhead">
                  {t('LBL.VIEW_LEAVE_HIST')}
                </h3>
              </Col>
              {/* <Col md={4}>
                <div className="btn_action_table_navbar">
                  <ButtonToolbar> */}
              {/* <Link className="btn btn-primary btn_table_navbar" to="/administration/leaverequest">
                      <FontAwesomeIcon icon={faCoffee} /> Leave Request
                    </Link> */}
              {/* <Button color="primary" icon={faCoffee} onClick={this.toggle}>Leave Request</Button>
                  </ButtonToolbar>
                </div>
              </Col> */}
            </Row>
            {/* <Row>
              <Col md={12} xs={12}>
                <div className="table_topnav">
                  <UncontrolledDropdown>
                    <DropdownToggle className="icon icon--right" outline>
                      <p>Period <ChevronDownIcon /></p>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown__menu">
                      <DropdownItem>2017</DropdownItem>
                      <DropdownItem>2018</DropdownItem>
                      <DropdownItem>2019</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <Button color="secondary">
                    <FontAwesomeIcon icon={faSync} />
                  </Button>
                </div>
              </Col>
            </Row> */}
            <Row>
              <Col md={12} xs={12} lg={12} xl={12}>
                <Card>
                  <div style={{ padding: '20px 5px 30px 10px' }}>
                    <ViewListTable
                      url={this.state.urlData}
                      heads={this.heads}
                      primaryKey="elv_id"
                      widthTable={this.state.dataWidth}
                      // updateFunc={this.editLeaveRequest}
                      deleteFunc={this.deleteLeaveRequest}
                      displayStyleHead="none"
                      onRef={(ref) => (this.child = ref)}
                      backBtn={backBtn}
                      // addBtn={addBtn}
                      editCond="2"
                      // addCond={this.state.addcond}
                      deleteCond={this.state.deletecond}
                      conds={{
                        emp_id,
                      }}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <Modal
                  isOpen={this.state.modal}
                  style={modalStyle}
                  className="modal-dialog modal-input"
                >
                  <ModalHeader>
                    {t('FRM.ADD')} {t('LBL.LEAVE_HISTORY')}
                  </ModalHeader>
                  <ModalBody>
                    <form
                      className="form form--horizontal"
                      onSubmit={handleSubmit(this.handleSubmit)}
                      name="addleaverequest"
                      id="addleaverequest"
                    >
                      <Container className="p0">
                        <Row>
                          <Col md={8} xs={12} lg={8} xl={8}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.LEAVE_TYPE')}
                              </span>
                              <div className="form__form-group-field">
                                <input type="hidden" name="elv_id" />
                                <Col>
                                  <Field
                                    name="leave_category"
                                    component={renderSelectField}
                                    type="text"
                                    options={leavecategory}
                                    // options={[{ value: 1, label: 'Cuti Tahunan' }]}
                                    placeholder="Select leave Type"
                                  />
                                </Col>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} xs={12} lg={12} xl={12}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.DATE')}{' '}
                              </span>
                              <div className="form__form-group-field">
                                <div className="date-picker date-picker--interval">
                                  <Col md={6} xs={12} lg={6} xl={6}>
                                    <Field
                                      name="start_date"
                                      component={renderDatePickerField}
                                      placeholder="Start Date"
                                    />
                                  </Col>
                                  <MinusIcon className="date-picker__svg" />
                                  <Col md={6} xs={12} lg={6} xl={6}>
                                    <Field
                                      name="end_date"
                                      component={renderDatePickerField}
                                      type="text"
                                      placeholder="End Date"
                                    />
                                  </Col>
                                  <div className="form__form-group-icon">
                                  <CalendarBlankIcon />
                                </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={8} xs={12} lg={8} xl={8}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.COMMENT')}
                              </span>
                              <div className="form__form-group-field">
                                <Col>
                                  <Field
                                    name="remarks"
                                    component="textarea"
                                    type="text"
                                    maxLength="255"
                                  />
                                </Col>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </form>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={this.toggle}>
                      {t('FRM.CANCEL')}
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      form="addleaverequest"
                      disabled={pristine}
                    >
                      {t('FRM.SAVE')}
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row> */}
          </CardBody>
        </Card>
      </Col>
    );
  }
}
const validate = (values) => {
  const validation = {
    start_date: {
      fieldLabel: `Start ${translator('LBL.DATE')}`,
      required: true,
    },
    end_date: {
      fieldLabel: `End ${translator('LBL.DATE')}`,
      required: true,
      // lockDateAfter: 'start_date',
      fieldLabelBefore: `start ${translator('LBL.DATE')}`,
    },
    leave_category: {
      fieldLabel: translator('LBL.LEAVE_TYPE'),
      required: true,
    },
  };
  const errors = utils.validate(validation, values);
  if (values.start_date !== null && values.end_date !== null) {
    if (
      typeof values.start_date === 'object' &&
      typeof values.end_date === 'object'
    ) {
      usedDate.map((used) => {
        if (
          values.start_date.isSameOrBefore(used.end) &&
          (values.end_date.isSameOrAfter(used.start) ||
            values.end_date.isSameOrAfter(used.end))
        ) {
          errors.start_date = 'Date selected is already used';
        }
      });
    }
  }

  if (typeof values.start_date !== 'undefined' && values.start_date !== null) {
    leaveReq.forEach((element) => {
      if (
        moment(values.start_date) >= moment(element.leave_start) &&
        moment(values.end_date) <= moment(element.leave_end)
      ) {
        errors.start_date = `${element.leave_start} and ${element.leave_end} has been requested`;
      }
    });
  }
  if (typeof values.start_date !== 'undefined' && values.start_date !== null) {
    leaveReq.forEach((element) => {
      if (
        moment(values.start_date) <= moment(element.leave_start) &&
        moment(values.end_date) >= moment(element.leave_end)
      ) {
        errors.start_date = `${element.leave_start} and ${element.leave_end} has been requested`;
      }
    });
  }
  if (typeof values.end_date !== 'undefined' && values.end_date !== null) {
    leaveReq.forEach((element) => {
      if (
        moment(values.start_date) >= moment(element.leave_start) &&
        moment(values.start_date) <= moment(element.leave_end) &&
        moment(values.end_date) >= moment(element.leave_end)
      ) {
        errors.end_date = `${element.leave_start} and ${element.leave_end} has been requested`;
      }
    });
  }
  if (typeof values.start_date !== 'undefined' && values.start_date !== null) {
    leaveReq.forEach((element) => {
      if (
        moment(values.start_date) <= moment(element.leave_start) &&
        moment(values.end_date) >= moment(element.leave_start) &&
        moment(values.end_date) <= moment(element.leave_end)
      ) {
        errors.start_date = `${element.leave_start} and ${element.leave_end} has been requested`;
      }
    });
  }
  return errors;
};

export default reduxForm({
  form: 'leaveListForm', // a unique identifier for this form
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(translate('global')(viewLeaveList));
